<template>
  <div>
    <el-dialog
      :title="dataList.title"
      :visible="value"
      width="80%"
      @close="handleClose"
    >
      <template v-if="dataList.list.length > 0">
        <el-table
          :data="dataList.list"
          border
          style="width: 100%;color: #333; margin: 16px auto;"
        >
          <el-table-column
            v-for="(data, index) in column"
            :key="index"
            :prop="data.field"
            :label="data.label"
            :min-width="data.width || 'auto'"
          >
            <template slot-scope="scope">
              <template v-if="data.field === 'select'">
                <el-radio v-model="dataList.id" :label="scope.row.id">
                  {{ '' }}
                </el-radio>
              </template>
              <template v-else>
                {{ scope.row[data.field] }}
              </template>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @current-change="handlePage"
          :current-page="currentPage"
          layout="prev, pager, next"
          :page-size="20"
          :total="total"
        >
        </el-pagination>
      </template>
      <div class="no-data-info" v-else>
        <i class="el-icon-warning"></i>
        查無資料
      </div>
      <div slot="footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleUpdate">確 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    dataList: {
      type: Object,
      default: () => {}
    },
    total: {
      type: Number,
      default: 0
    }
  },
  emits: ['close', 'shipDataSave', 'delete', 'handlePage'],
  data() {
    return {
      currentPage: 0,
      column: [
        {
          field: 'select',
          label: '編輯',
          width: '30px'
        },
        {
          field: 'reg_ship',
          label: '海掛'
        },
        // {
        //   field: 'call_sign',
        //   label: '呼號'
        // },
        {
          field: 'vsl_name',
          label: '船名'
        },
        {
          field: 'voyage',
          label: '航次'
        },
        {
          field: 'carrier',
          label: '船公司'
        }
        // {
        //   field: 'port_discharge',
        //   label: '卸貨港'
        // },
        // {
        //   field: 'closing_date',
        //   label: '結關日'
        // },
        // {
        //   field: 'onboard_date',
        //   label: '裝船日'
        // },
        // {
        //   field: 'receipt_place',
        //   label: '櫃場'
        // },
        // {
        //   field: 'line_code',
        //   label: '航線'
        // }
      ]
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    handleUpdate() {
      console.log('selected', this.dataList.id)
      this.$emit('fetchListById')
      this.handleClose()
    },
    checkEmpty(val) {
      console.log(val)
    },
    handlePage(val) {
      this.$emit('handlePage', val)
    }
  },
  mounted() {},
  watch: {
    value(val) {}
  }
}
</script>

<style lang="scss" scoped>
.no-data-info {
  color: #87898c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  font-size: 0.875rem;

  i {
    font-size: 5rem;
    padding: 24px;
    color: #ededee;
  }
}

::v-deep .el-dialog {
  border-radius: 8px;
}
::v-deep .el-dialog__body {
  margin: 10px 20px;
  padding: 10px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
</style>
