<template>
  <el-table
    :data="tableData"
    tooltip-effect="dark"
    :class="$style.table"
    empty-text="無數據資料"
    :border="true"
  >
    <el-table-column type="selection" width="50" align="center" fixed="left" />
    <el-table-column
      label="使用者帳號"
      align="center"
      prop="account"
      min-width="100"
      fixed="left"
    />
    <el-table-column
      label="使用者名稱"
      prop="name"
      align="center"
      min-width="100"
      fixed="left"
    />
    <el-table-column label="角色" prop="userrole" align="center" />
    <el-table-column label="基本代碼" prop="basic_code" align="center" />
    <!-- <el-table-column
      label="公司名稱"
      prop="companyname"
      align="center"
      min-width="180"
    /> -->
    <el-table-column
      label="Email"
      prop="email"
      align="center"
      min-width="170"
    />
    <el-table-column
      label="CCEmail"
      prop="email_cc"
      align="center"
      min-width="180"
    />
    <el-table-column
      label="加入日期"
      prop="createon"
      align="center"
      min-width="100"
    />
    <!----<el-table-column
      label="狀態"
      prop="end"
      align="status"
      min-width="120"
    >
      <template slot-scope="scope">
        <el-switch v-model="scope.row.status"/>
      </template>
    </el-table-column>--->
    <el-table-column label="操作" align="center" width="60">
      <template slot-scope="scope">
        <i
          class="el-icon-edit"
          :class="$style.icon"
          @click="$emit('edit', '儲存', scope.row)"
        />
      </template>
    </el-table-column>
    <el-table-column label="刪除" align="center" width="60">
      <template slot-scope="scope">
        <i
          class="el-icon-delete"
          :class="$style.icon"
          @click="$emit('delete', scope.row)"
        />
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['tableData'])
  },
  data() {
    return {}
  },
  methods: {
    test(row) {
      // console.log('row', row)
      return true
    }
  }
}
</script>

<style lang="scss" module>
.table {
  :global(.el-table__body-wrapper) {
    min-height: 475px;
  }
}

.icon {
  font-size: 20px;
  font-weight: 800;
  cursor: pointer;
  color: #39a85d;
}
</style>
