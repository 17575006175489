<template>
  <form class="search-bar">
    <div class="sea">
      <label>海掛</label>
      <el-autocomplete
        class="inline-input"
        v-model="condition.reg_ship"
        :fetch-suggestions="querySearch"
        placeholder="輸入海掛"
        :trigger-on-focus="false"
        @select="handleSelect"
      ></el-autocomplete>
    </div>
    <div class="line-container">
      <div class="line"></div>
      <p>or</p>
      <div class="line"></div>
    </div>
    <div class="vessel">
      <!-- <label>船名</label> -->
      <el-autocomplete
        class="inline-input"
        v-model="condition.vsl_name"
        :fetch-suggestions="querySearch"
        placeholder="輸入船名"
        :trigger-on-focus="false"
        @select="handleSelect"
        @keydown.tab.native="handleSelect"
        @keydown.enter.native="handleSelect"
      ></el-autocomplete>
    </div>
    <div class="number">
      <!-- <label>航次</label> -->

      <el-select
        v-model="condition.voyage"
        filterable
        placeholder="航次"
        @change="handleCarrierOption"
      >
        <el-option
          v-for="item in voyageOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="carrier">
      <!-- <label>船公司</label> -->

      <el-select v-model="condition.carrier" filterable placeholder="船公司">
        <el-option
          v-for="item in carrierOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="buttons">
      <div class="button" @click="$emit('query')">
        <base-button type="button">
          <template #icon>
            <img src="@/assets/bb/search.svg" />
          </template>
          <template #default>
            <p>搜尋</p>
          </template>
        </base-button>
      </div>
      <div class="clear-button" @click="clear">
        <base-button type="button" class="redColor">
          <template #icon>
            <i class="el-icon-close"></i>
          </template>
          <template #default>
            <p>清空</p>
          </template>
        </base-button>
      </div>
    </div>
    <div class="pol">
      <label> + POL</label>
      <el-select
        class="inline-input"
        v-model="condition.port_load"
        :options="options"
        placeholder="輸入 POL"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
  </form>
</template>

<script>
import { getKeyWordSelect, getShipList } from '@/api/cfsApi'

export default {
  name: 'SearchBar',
  props: {
    condition: {
      type: Object,
      default: () => ({})
    },
    showAllOption: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      sea: '',
      vessel: '',
      number: '',
      pol: '',
      keywordList: [],
      voyageOptions: [],
      carrierOptions: []
    }
  },
  computed: {
    options() {
      const options = [
        {
          label: '--',
          value: ''
        },
        {
          label: '基隆',
          value: 'TWKEL'
        },
        {
          label: '台北',
          value: 'TWTPE'
        },
        {
          label: '台中',
          value: 'TWTXG'
        },
        {
          label: '高雄',
          value: 'TWKHH'
        }
      ]
      // if (this.showAllOption) {
      //   options = [
      //     {
      //       label: '--',
      //       value: ''
      //     }
      //   ].concat(options)
      // }
      return options
    },
    routeName() {
      return this.$route.name
    }
  },
  methods: {
    clear() {
      this.condition.reg_ship = ''
      this.condition.vsl_name = ''
      this.condition.voyage = ''
      this.condition.carrier = ''
      this.condition.port_load = ''
      this.voyageOptions = []
      this.carrierOptions = []
      this.$emit('clear')
    },
    async fetchGetKeyWordList() {
      const res = await getKeyWordSelect({ type: 'vsl_name', dist: 1 })
      if (res.code === '200') {
        this.keywordList = res.data.ListData.map(item => {
          return { value: item.Value }
        })
      }
    },
    querySearch(queryString, cb) {
      console.log(queryString)
      var select = this.keywordList
      var results = queryString
        ? select.filter(this.createFilter(queryString))
        : select
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      return select => {
        return (
          select.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        )
      }
    },
    handleSelect() {
      if (this.condition.vsl_name === '' || this.condition.vsl_name === undefined) {
        this.condition.voyage = ''
        this.condition.carrier = ''
        this.$emit('update')
        return
      }
      this.$emit('update')
      this.fetchOptions('voyageOptions', 'voyage')
    },
    handleCarrierOption() {
      this.fetchOptions('carrierOptions', 'carrier')
    },
    async fetchOptions(options, name, coverCondition = {}) {
      const queryCondition = {
        ...this.condition,
        ...coverCondition
      }
      const res = await getShipList({ ...queryCondition, limit: 1000, dist: 1 })
      const { ListData } = res.data
      if (res.code === '200') {
        this[options] = ListData.map(item => {
          return {
            label: item[name],
            value: item[name]
          }
        })
        this[options] = [
          ...new Map(this[options].map(v => [v.label, v])).values()
        ]
      }
    },
    async fetchVoyageAndCarrierOptionsByVslName() {
      const coverCondition = { voyage: '', carrier: '' }
      await Promise.all([
        this.fetchOptions('voyageOptions', 'voyage', coverCondition),
        this.fetchOptions('carrierOptions', 'carrier', coverCondition)
      ])
    }
  },
  mounted() {
    this.fetchGetKeyWordList()
  }
}
</script>

<style lang="scss" scoped>
.search-bar {
  border: 1px solid #ededee;
  border-radius: 8px;
  padding: 15px 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 1fr 1fr;
  align-items: center;
  grid-column-gap: 10px;
  grid-row-gap: 15px;

  & label {
    margin-right: 12px;
  }

  & input {
    padding: 8px 16px;
    border: 1px solid #e0e1e1;
    border-radius: 4px;
    width: 8vw;
  }

  & .sea {
    grid-column: 1 / 4;
    grid-row: 1 / 2;
    display: grid;
    align-items: center;
    justify-content: flex-start;
    grid-template-columns: max-content 1fr;
  }

  & .line-container {
    grid-column: 1 / 4;
    grid-row: 2 / 3;
    display: flex;
    align-items: center;

    & p {
      padding: 0 8px;
    }

    & .line {
      width: 100%;
      height: 2px;
      background-color: #25282b;
    }
  }

  & .vessel {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    display: grid;
    align-items: center;
  }

  & .number {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    display: grid;
    align-items: center;
  }
  & .carrier {
    grid-column: 3 / 4;
    grid-row: 3 / 4;
    display: grid;
    align-items: center;
  }

  & .pol {
    grid-column: 4 / 4;
    grid-row: 2 / 3;
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
  }

  .buttons {
    grid-column: 4 / 4;
    grid-row: 3 / 4;
    display: flex;
    justify-content: flex-end;
  }

  .back-button {
    display: flex;
    justify-content: flex-end;
  }

  .clear-button {
    i {
      width: 16px;
      height: 16px;
      font-size: 16px;
      margin-right: 6px;
    }
  }
}
.button::v-deep button, .clear-button::v-deep button, .back-button::v-deep button{
  width: 6rem;
  margin: 4px;
}
</style>
