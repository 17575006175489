import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'

export default {
  state() {
    return {
      selectedKind: {},
      searchResultId: 'c1',
      counterInfos: [
        {
          id: 'c1',
          basic: {
            pod: 'HONG KONG',
            closeDate: '2022/02/23',
            boardDate: '2022/03/25',
            shipCompany: '長榮海運股份有限公司'
          },
          counter: {
            totalNumber: 865,
            totalWeight: 19137,
            totalVolume: 82.4,
            min20GpNumber: 3,
            min40GpNumber: 2,
            min40HqNumber: 2
          }
        },
        {
          id: 'c2',
          basic: {
            pod: 'United Kingdom',
            closeDate: '2022/01/17',
            boardDate: '2022/02/22',
            shipCompany: '萬海航運股份有限公司'
          },
          counter: {
            totalNumber: 542,
            totalWeight: 10398,
            totalVolume: 12.6,
            min20GpNumber: 4,
            min40GpNumber: 1,
            min40HqNumber: 3
          }
        }
      ],
      boxTypes: [
        {
          id: 'c1',
          kinds: [
            {
              id: 'c1_20_gp',
              name: "20' GP",
              maxCapacity: '20.080 KG／33200 CBM',
              isSelected: false,
              boxNumbers: [
                {
                  number: '201',
                  percent: 100
                },
                {
                  number: '202',
                  percent: 45
                },
                {
                  number: '203',
                  percent: 10
                }
              ]
            },
            {
              id: 'c1_40_gp',
              name: "40' GP",
              maxCapacity: '264.80 KG／67500 CBM',
              isSelected: false,
              boxNumbers: [
                {
                  number: '401',
                  percent: 20
                },
                {
                  number: '402',
                  percent: 85
                }
              ]
            }
          ]
        },
        {
          id: 'c2',
          kinds: [
            {
              id: 'c2_20_gp',
              name: "20' GP",
              maxCapacity: '17.290 KG／56700 CBM',
              isSelected: false,
              boxNumbers: [
                {
                  number: '201',
                  percent: 33
                },
                {
                  number: '202',
                  percent: 78
                },
                {
                  number: '203',
                  percent: 12
                }
              ]
            },
            {
              id: 'c2_40_gp',
              name: "40' GP",
              maxCapacity: '174.30 KG／71200 CBM',
              isSelected: false,
              boxNumbers: [
                {
                  number: '401',
                  percent: 90
                },
                {
                  number: '402',
                  percent: 77
                },
                {
                  number: '403',
                  percent: 20
                }
              ]
            }
          ]
        }
      ],
      boxDetails: [
        {
          searchResultId: 'c1',
          id: 'c1_20_gp',
          name: "20' GP",
          selected: false,
          items: [
            {
              id: '201',
              name: '201 中轉櫃',
              number: '201',
              weight: {
                all: 100,
                kg: 40
              },
              volume: {
                all: 100,
                cbm: 100
              },
              releasedPiece: 5,
              topping: 'abcd中國'
            },
            {
              id: '202',
              name: '202 特殊櫃',
              number: '202',
              weight: {
                all: 100,
                kg: 70
              },
              volume: {
                all: 100,
                cbm: 45
              },
              releasedPiece: 500,
              topping: ''
            },
            {
              id: '203',
              name: '203 特殊櫃',
              number: '203',
              weight: {
                all: 1000,
                kg: 200
              },
              volume: {
                all: 100,
                cbm: 10
              },
              releasedPiece: 12,
              topping: 'abcd中國'
            }
          ]
        },
        {
          searchResultId: 'c1',
          id: 'c1_40_gp',
          name: "40' GP",
          selected: false,
          items: [
            {
              id: '401',
              name: '401 中轉櫃',
              number: '401',
              weight: {
                all: 37840,
                kg: 7568
              },
              volume: {
                all: 100,
                cbm: 20
              },
              releasedPiece: 5,
              topping: 'abcd中國'
            },
            {
              id: '402',
              name: '402 特殊櫃',
              number: '402',
              weight: {
                all: 1000,
                kg: 140
              },
              volume: {
                all: 100,
                cbm: 85
              },
              releasedPiece: 500,
              topping: ''
            }
          ]
        },
        {
          searchResultId: 'c2',
          id: 'c2_20_gp',
          name: "20' GP",
          selected: false,
          items: [
            {
              id: '201',
              name: '201 中轉櫃',
              number: '201',
              weight: {
                all: 37840,
                kg: 7568
              },
              volume: {
                all: 100000,
                cbm: 80000
              },
              releasedPiece: 5,
              topping: 'abcd中國'
            },
            {
              id: '202',
              name: '202 特殊櫃',
              number: '202',
              weight: {
                all: 1000,
                kg: 140
              },
              volume: {
                all: 100,
                cbm: 60
              },
              releasedPiece: 500,
              topping: ''
            }
          ]
        },
        {
          searchResultId: 'c2',
          id: 'c2_40_gp',
          name: "40' GP",
          selected: false,
          items: [
            {
              id: '401',
              name: '401 中轉櫃',
              number: '401',
              weight: {
                all: 37840,
                kg: 7568
              },
              volume: {
                all: 100000,
                cbm: 80000
              },
              releasedPiece: 5,
              topping: 'abcd中國'
            },
            {
              id: '402',
              name: '402 特殊櫃',
              number: '402',
              weight: {
                all: 1000,
                kg: 140
              },
              volume: {
                all: 100,
                cbm: 60
              },
              releasedPiece: 500,
              topping: ''
            }
          ]
        }
      ],
      packageTypeSelect: [
        {
          label: 'PLT',
          value: 'PLT'
        },
        {
          label: 'CTN',
          value: 'CTN'
        },
        {
          label: 'PKG',
          value: 'PKG'
        },
        {
          label: 'PLTS',
          value: 'PLTS'
        },
        {
          label: 'WDC',
          value: 'WDC'
        },
        {
          label: 'CTNS',
          value: 'CTNS'
        },
        {
          label: 'ROL',
          value: 'ROL'
        },
        {
          label: 'PKGS',
          value: 'PKGS'
        },
        {
          label: 'ROLLS',
          value: 'ROLLS'
        },
        {
          label: 'CRT',
          value: 'CRT'
        },
        {
          label: 'BDL',
          value: 'BDL'
        },
        {
          label: 'BAG',
          value: 'BAG'
        },
        {
          label: 'CAS',
          value: 'CAS'
        },
        {
          label: 'W/CASES',
          value: 'W/CASES'
        },
        {
          label: 'BAL',
          value: 'BAL'
        },
        {
          label: 'W/CASE',
          value: 'W/CASE'
        },
        {
          label: 'DRU',
          value: 'DRU'
        },
        {
          label: 'WDCS',
          value: 'WDCS'
        },
        {
          label: 'PALLETS',
          value: 'PALLETS'
        }
      ],
      portDischargeSelect: [],
      cfsSearchBarCondition: {
        reg_ship: '',
        vsl_name: '',
        voyage: '',
        receipt_place: '',
        carrier: '',
        port_load: ''
      }
    }
  },
  mutations,
  actions,
  getters
}
