<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div>
    <base-dialog
      :show="openDialog"
      title="新增貨櫃資訊"
      @close="$emit('handleClose')"
      @save="saveData"
    >
      <template #default>
        <div class="addBoxDetail">
          <div class="form-control">
            <label>櫃號</label>
            <input type="text" placeholder="請輸入櫃號" v-model="box.van_no" />
          </div>
          <div class="form-control">
            <label>備註</label>
            <input type="text" placeholder="請輸入備註" v-model="box.note" />
          </div>
          <div class="form-control" v-if="isNoVan">
            <label>貨櫃類型</label>
            <input
              type="text"
              placeholder="請輸入貨櫃類型"
              v-model="box.van_type"
            />
          </div>
          <div style="display:none;" class="form-control-checkbox">
            <input type="checkbox" v-model="isTopping" />
            <label>Topping</label>
          </div>
          <p>Topping</p>
          <div class="form-control-checkbox">
            <input
              type="checkbox"
              v-model="box.Area_KEL"
              :disabled="!box.Enable_KEL_Checkbox"
              @change="checkDefaultArea('TWKEL')"
            />
            <label>基隆</label>
            <el-radio
              label="TWKEL"
              v-model="box.area"
              :disabled="!box.Enable_KEL_Radio"
              >開櫃地</el-radio>
          </div>
          <div class="form-control-checkbox">
            <input
              type="checkbox"
              v-model="box.Area_TPE"
              :disabled="!box.Enable_TPE_Checkbox"
              @change="checkDefaultArea('TWTPE')"
            />
            <label>台北</label>
            <el-radio
              label="TWTPE"
              v-model="box.area"
              :disabled="!box.Enable_TPE_Radio"
              >開櫃地</el-radio>
          </div>
          <div class="form-control-checkbox">
            <input
              type="checkbox"
              v-model="box.Area_TYN"
              :disabled="!box.Enable_TYN_Checkbox"
              @change="checkDefaultArea('TWTYN')"
            />
            <label>桃園</label>
            <el-radio
              label="TWTYN"
              v-model="box.area"
              :disabled="!box.Enable_TYN_Radio"
              >開櫃地</el-radio>
          </div>
          <div class="form-control-checkbox">
            <input
              type="checkbox"
              v-model="box.Area_TXG"
              :disabled="!box.Enable_TXG_Checkbox"
              @change="checkDefaultArea('TWTXG')"
            />
            <label>台中</label>
            <el-radio
              label="TWTXG"
              v-model="box.area"
              :disabled="!box.Enable_TXG_Radio"
              >開櫃地</el-radio>
          </div>
          <div class="form-control-checkbox">
            <input
              type="checkbox"
              v-model="box.Area_KHH"
              :disabled="!box.Enable_KHH_Checkbox"
              @change="checkDefaultArea('TWKHH')"
            />
            <label>高雄</label>
            <el-radio
              label="TWKHH"
              v-model="box.area"
              :disabled="!box.Enable_KHH_Radio"
              >開櫃地</el-radio>
          </div>

          <!-- <div class="form-control">
            <label>地點</label>
            <select
              :value="box.area"
              @change="setArea($event.target.value)"
              :disabled="true"
            >
              <option value="TWKEL">基隆</option>
              <option value="TWTPE">台北</option>
              <option value="TWTXG">台中</option>
              <option value="TWKHH">高雄</option>
            </select>
          </div> -->
          <!-- <div class="form-control">
            <label>地點</label>
            <el-select v-model="box.area_end" placeholder="請選擇">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div> -->
          <!-- <div class="form-control-checkbox">
            <input type="checkbox">
            <label class="delete">刪除貨櫃</label>
          </div> -->
        </div>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import BaseDialog from '../../ui/BaseDialog.vue'
import { getToppingAllowList } from '@/api/cfsApi'
export default {
  // props: ['openDialog', 'receiptPlace'],
  props: {
    openDialog: {
      type: Boolean
    },
    receiptPlace: {},
    isNoVan: {
      type: Boolean,
      default: false
    },
    allData: {
      type: Array,
      default: () => []
    },
    row: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      box: {
        van_no: '',
        note: '',
        area: 'TWKEL',
        area_end: '',
        topping: 0,
        id: null,
        Area_KEL: true,
        Area_TPE: true,
        Area_TYN: true,
        Area_TXG: true,
        Area_KHH: true,
        Enable_KEL: 1,
        Enable_TPE: 1,
        Enable_TYN: 1,
        Enable_TXG: 1,
        Enable_KHH: 1,
        Enable_KEL_Checkbox: true,
        Enable_TPE_Checkbox: true,
        Enable_TYN_Checkbox: true,
        Enable_TXG_Checkbox: true,
        Enable_KHH_Checkbox: true,
        Enable_KEL_Radio: true,
        Enable_TPE_Radio: true,
        Enable_TYN_Radio: true,
        Enable_TXG_Radio: true,
        Enable_KHH_Radio: true
      },
      isTopping: true,
      options: [
        {
          value: 'TWKEL',
          label: '基隆'
        },
        {
          value: 'TWTPE',
          label: '台北'
        },
        {
          value: 'TWTYN',
          label: '桃園'
        },
        {
          value: 'TWTXG',
          label: '台中'
        },
        {
          value: 'TWKHH',
          label: '高雄'
        }
      ]
    }
  },
  methods: {
    saveData () {
      if (this.box.van_no === '') {
        this.$message.error('請輸入貨櫃編號')
        return
      }
      this.$emit('save', this.box)
    },
    async checkDefaultArea (area = null) {
      await this.$nextTick()
      if (area === null) {
        area = this.box.area
      }
      var areaMap = {
        TWKEL: this.box.Area_KEL,
        TWTPE: this.box.Area_TPE,
        TWTYN: this.box.Area_TYN,
        TWTXG: this.box.Area_TXG,
        TWKHH: this.box.Area_KHH
      }
      var enableCheckboxMap = {
        TWKEL: this.box.Enable_KEL_Checkbox,
        TWTPE: this.box.Enable_TPE_Checkbox,
        TWTYN: this.box.Enable_TYN_Checkbox,
        TWTXG: this.box.Enable_TXG_Checkbox,
        TWKHH: this.box.Enable_KHH_Checkbox
      }
      var availableArea = []
      for (var key in enableCheckboxMap) {
        if (enableCheckboxMap[key] === true && areaMap[key] === true) {
          availableArea.push(key)
        } else {
          this.box['Area_' + key.substr(2)] = false
        }
      }
      if (availableArea.length > 0) {
        this.box.area = availableArea[0]
      } else {
        this.box['Area_' + area.substr(2)] = true
      }
    },
    defaultBoxArea() {
      console.log('defaultBoxArea')
      var enableMap = {
        TWKEL: this.box.Enable_KEL,
        TWTPE: this.box.Enable_TPE,
        TWTYN: this.box.Enable_TYN,
        TWTXG: this.box.Enable_TXG,
        TWKHH: this.box.Enable_KHH
      }
      for (var key in enableMap) {
        if (enableMap[key] === 1) {
          this.box.area = key
          break
        }
      }
    },
    setArea(e) {
      this.box.area = e
    },
    setToArea(e) {
      this.box.area_end = e
    },
    async getToppingAllow () {
      var vslName = this.allData[0].vsl_name
      var voyage = this.allData[0].voyage
      var carrier = this.allData[0].carrier
      var body = {
        vsl_name: vslName,
        voyage: voyage,
        carrier: carrier
      }
      var res = await getToppingAllowList(body)
      var allowList = res.data.ListData[0]
      var toppingAllowCount = 0
      var enableCheckboxCount = 0
      this.box.Enable_KEL_Checkbox = allowList.Enable_KEL === 1
      this.box.Enable_TPE_Checkbox = allowList.Enable_TPE === 1
      this.box.Enable_TYN_Checkbox = allowList.Enable_TYN === 1
      this.box.Enable_TXG_Checkbox = allowList.Enable_TXG === 1
      this.box.Enable_KHH_Checkbox = allowList.Enable_KHH === 1
      this.box.Enable_KEL_Radio = allowList.Enable_KEL === 1
      this.box.Enable_TPE_Radio = allowList.Enable_TPE === 1
      this.box.Enable_TYN_Radio = allowList.Enable_TYN === 1
      this.box.Enable_TXG_Radio = allowList.Enable_TXG === 1
      this.box.Enable_KHH_Radio = allowList.Enable_KHH === 1
      this.box.Area_KEL = allowList.Enable_KEL !== 0
      enableCheckboxCount += allowList.Enable_KEL === 1 ? 1 : 0
      this.box.Area_TPE = allowList.Enable_TPE !== 0 && enableCheckboxCount < 1
      enableCheckboxCount += allowList.Enable_TPE === 1 ? 1 : 0
      this.box.Area_TYN = allowList.Enable_TYN !== 0 && enableCheckboxCount < 1
      enableCheckboxCount += allowList.Enable_TYN === 1 ? 1 : 0
      this.box.Area_TXG = allowList.Enable_TXG !== 0 && enableCheckboxCount < 1
      enableCheckboxCount += allowList.Enable_TXG === 1 ? 1 : 0
      this.box.Area_KHH = allowList.Enable_KHH !== 0 && enableCheckboxCount < 1
      if (allowList.Enable_KEL === 1) {
        toppingAllowCount++
      }
      if (allowList.Enable_TPE === 1) {
        toppingAllowCount++
      }
      if (allowList.Enable_TYN === 1) {
        toppingAllowCount++
      }
      if (allowList.Enable_TXG === 1) {
        toppingAllowCount++
      }
      if (allowList.Enable_KHH === 1) {
        toppingAllowCount++
      }
      this.box.topping = toppingAllowCount > 1 ? 1 : 0
      console.log('after check allow', this.box)
    }
  },
  components: {
    BaseDialog
  },
  async mounted() {
  },
  watch: {
    'box.area'(val, oldVal) {
      this.options = this.options.filter(e => e.value !== val)
      var areaMap = {
        TWKEL: this.box.Area_KEL,
        TWTPE: this.box.Area_TPE,
        TWTYN: this.box.Area_TYN,
        TWTXG: this.box.Area_TXG,
        TWKHH: this.box.Area_KHH
      }
      if (areaMap[val] === false) {
        this.box.area = oldVal
      }
    },
    isTopping(val) {
      val = false
      var toppingAllowCount = 0
      if (this.box.Area_KEL) {
        toppingAllowCount++
      }
      if (this.box.Area_TPE) {
        toppingAllowCount++
      }
      if (this.box.Area_TYN) {
        toppingAllowCount++
      }
      if (this.box.Area_TXG) {
        toppingAllowCount++
      }
      if (this.box.Area_KHH) {
        toppingAllowCount++
      }
      if (toppingAllowCount > 1) {
        val = true
      }
      this.box.topping = val ? '1' : '0'
      this.box.area_end = ''
      if (val) {
        this.options = [
          {
            value: 'all',
            label: '全部'
          }
        ]
        this.box.area_end = 'all'
      } else {
        this.options = [
          {
            value: 'TWKEL',
            label: '基隆'
          },
          {
            value: 'TWTPE',
            label: '台北'
          },
          {
            value: 'TWTYN',
            label: '桃園'
          },
          {
            value: 'TWTXG',
            label: '台中'
          },
          {
            value: 'TWKHH',
            label: '高雄'
          }
        ]
        this.options = this.options.filter(e => e.value !== this.box.area)
      }
    },
    async openDialog(val) {
      if (val) {
        Object.keys(this.box).forEach(item => {
          if (item !== 'area') {
            this.box[item] = ''
          }
        })
        if (this.allData[0] === undefined) {
          this.box.Enable_KEL_Checkbox = true
          this.box.Enable_TPE_Checkbox = true
          this.box.Enable_TYN_Checkbox = true
          this.box.Enable_TXG_Checkbox = true
          this.box.Enable_KHH_Checkbox = true
          this.box.Enable_KEL_Radio = true
          this.box.Enable_TPE_Radio = true
          this.box.Enable_TYN_Radio = true
          this.box.Enable_TXG_Radio = true
          this.box.Enable_KHH_Radio = true
          this.box.Area_KEL = true
          this.box.Area_TPE = true
          this.box.Area_TYN = true
          this.box.Area_TXG = true
          this.box.Area_KHH = true
        } else {
          this.box.Enable_KEL_Checkbox = this.allData[0].Enable_KEL === 1
          this.box.Enable_TPE_Checkbox = this.allData[0].Enable_TPE === 1
          this.box.Enable_TYN_Checkbox = this.allData[0].Enable_TYN === 1
          this.box.Enable_TXG_Checkbox = this.allData[0].Enable_TXG === 1
          this.box.Enable_KHH_Checkbox = this.allData[0].Enable_KHH === 1
          this.box.Enable_KEL_Radio = this.allData[0].Enable_KEL === 1
          this.box.Enable_TPE_Radio = this.allData[0].Enable_TPE === 1
          this.box.Enable_TYN_Radio = this.allData[0].Enable_TYN === 1
          this.box.Enable_TXG_Radio = this.allData[0].Enable_TXG === 1
          this.box.Enable_KHH_Radio = this.allData[0].Enable_KHH === 1
          this.box.Area_KEL = this.allData[0].Enable_KEL === 1
          this.box.Area_TPE = this.allData[0].Enable_TPE === 1
          this.box.Area_TYN = this.allData[0].Enable_TYN === 1
          this.box.Area_TXG = this.allData[0].Enable_TXG === 1
          this.box.Area_KHH = this.allData[0].Enable_KHH === 1
        }
        await this.getToppingAllow()
        await this.$nextTick()
        this.checkDefaultArea()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.addBoxDetail {
  display: grid;
  grid-template-rows: repeat(5, max-content);
  grid-row-gap: 20px;

  & .form-control {
    display: grid;
    grid-template-rows: max-content 38px;
    grid-row-gap: 8px;

    & label {
      font-size: 14px;
      font-weight: 500;
    }

    & input {
      border: 1px solid #e0e1e1;
      border-radius: 4px;
      padding: 8px 16px;
      width: 365px;

      &::placeholder {
        color: #9ea0a2;
      }
    }

    & select {
      border: 1px solid #e0e1e1;
      border-radius: 4px;
      padding: 8px 16px;
      width: 100%;
    }

    &-checkbox {
      display: flex;
      align-items: center;

      & input {
        margin-right: 7px;
      }
      & label {
        margin-right: 10px;
        font-size: 14px;
      }
      // & label.delete {
      //   color: #D04433;
      // }
    }
  }
}
</style>
