<template>
  <div>
    <base-dialog
      :show="openDialogOver"
      title="超過承載量"
      @close="$emit('handleClose')"
      @save="$emit('save')"
    >
      <div>
        <label>所選已超過貨櫃裝載上限，是否確認配置</label>
      </div>
    </base-dialog>
  </div>
</template>

<script>
import BaseDialog from '../../ui/BaseDialog.vue'

export default {
  props: ['openDialogOver'],
  components: {
    BaseDialog
  }
}
</script>

<style scoped>
label {
  font-size: 18px;
  font-weight: 500;
}
</style>
