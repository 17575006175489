
// eslint-disable-next-line
import { shipmentSingle, shipmentList, shipmentAction } from '@/api/shipment'
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
const shipment = {
  state: {
    shipmentAddInputlist: [{
      label: 'Job No',
      input: 'JobNo31',
      key: 'pre_book_no',
      msg: ''
    }, {
      label: '船名Vessel',
      input: 'vslName',
      key: 'vsl_name',
      msg: ''
    }, {
      label: '航次Voyage',
      input: 'Voyage',
      key: 'voyage',
      msg: ''
    }, {
      label: 'SO No',
      input: 'soNo',
      key: 'so_no',
      msg: ''
    }, {
      label: 'HB/L No',
      input: 'soNo',
      key: 'o_bl_no',
      msg: ''
    }, {
      label: '客戶名稱',
      input: 'customerCode',
      key: 'customer_code',
      msg: ''
    }, {
      label: '結關日期',
      input: 'closingDate',
      key: 'closing_date',
      msg: ''
    }, {
      label: '裝船日期',
      input: 'onboardDate',
      key: 'onboard_date',
      msg: ''
    }, {
      label: '裝貨港POL',
      input: 'POL',
      key: 'port_load',
      msg: ''
    }, {
      label: '卸貨港POD',
      input: 'POD',
      key: 'port_discharge',
      msg: ''
    }, {
      label: 'POL',
      input: 'POL',
      key: 'port_load',
      msg: ''
    }, {
      label: 'PLD',
      input: 'PLD',
      key: 'delivery_place',
      msg: ''
    }, {
      label: 'VoyID',
      input: 'VoyID',
      key: 'ship_num',
      msg: ''
    }, {
      label: 'salesCode',
      input: 'salesCode',
      key: 'sales_code',
      msg: ''
    }, {
      label: 'entryUserCode',
      input: 'test',
      key: 'entry_user_code',
      msg: ''
    }, {
      label: 'cfsDeliveryPlace',
      input: 'cfsDeliveryPlace',
      key: 'cfs_delivery_place',
      msg: ''
    }, {
      label: 'brokerCode',
      input: 'brokerCode',
      key: 'broker_code',
      msg: ''
    }, {
      label: 'truckCompany',
      input: 'truckCompany',
      key: 'truck_company',
      msg: ''
    }, {
      label: 'carrier',
      input: 'carrier',
      key: 'carrier',
      msg: ''
    }, {
      label: 'selfPodAgent',
      input: 'selfPodAgent',
      key: 'self_pod_agent',
      msg: ''
    }, {
      label: 'ediType',
      input: 'aa',
      key: 'edi_type',
      msg: ''
    }, {
      label: 'transferBy',
      input: 'transferBy',
      key: 'transfer_by',
      msg: ''
    }, {
      label: 'transfer_dt',
      input: '',
      key: 'transferDt',
      msg: ''
    }],
    shipmentTableLabel: [{
      label: 'Job No',
      minWidth: 130,
      fixed: 'left',
      link: true,
      prop: 'pre_book_no',
      node: 'edit',
      url: 'shipment'
    }, {
      label: '船名Vessel',
      minWidth: 200,
      prop: 'vsl_name'
    }, {
      label: '航次Voyage',
      minWidth: 130,
      prop: 'voyage'
    }, {
      label: 'SO No',
      prop: 'so_no'
    }, {
      label: 'HB/L No',
      minWidth: 180,
      prop: 'o_bl_no'
    }, {
      label: '客戶中文名稱',
      minWidth: 200,
      prop: 'customer_code'
    }, {
      label: '結關日期',
      minWidth: 120,
      prop: 'closing_date'
    }, {
      label: '裝船日期',
      minWidth: 120,
      prop: 'onboard_date'
    }, {
      label: '卸貨港POD',
      minWidth: 150,
      prop: 'port_discharge'
    }, {
      label: '裝貨港POL',
      minWidth: 150,
      prop: 'port_load'
    }],
    shipmentDetailTableLabel: [{
      label: 'version',
      prop: 'version',
      minWidth: 40
    }, {
      label: 'update_time',
      prop: 'transfer_dt',
      minWidth: 55
    }, {
      label: 'uploader',
      prop: 'transfer_by',
      minWidth: 55
    }, {
      label: 'status',
      prop: 'status',
      minWidth: 50,
      fixed: 'left'
    }, {
      label: 'Edit',
      prop: 'Edit',
      icon: true,
      minWidth: 20,
      node: 'Edit',
      key: 'id'
    }, {
      label: 'View',
      prop: 'View',
      icon: true,
      minWidth: 20,
      node: 'View',
      key: 'id'
    }],
    shipmentMasterItem: {
      pre_book_no: '',
      vsl_name: '',
      voyage: '',
      so_no: '',
      o_bl_no: '',
      customer_code: '',
      closing_date: '',
      onboard_date: '',
      receipt_place: '',
      port_discharge: '',
      port_load: '',
      delivery_place: '',
      ship_num: '',
      sales_code: '',
      entry_user_code: '',
      cfs_delivery_place: '',
      broker_code: '',
      truck_company: '',
      carrier: '',
      self_pod_agent: '',
      pre_depart_date: '',
      pre_arr_date: '',
      loading_term: '',
      discharge_term: '',
      cy_number_desc: '',
      cy_receipt_place: '',
      cy_receipt_code: '',
      cy_delivery_place: '',
      cy_delivery_code: '',
      cfs_number_desc: ''
    },
    shipmentOrderid: ''
  },

  getters: {
    shipmentAddInputlist: (state) => state.shipmentAddInputlist,
    shipmentTableLabel: (state) => state.shipmentTableLabel,
    shipmentOrderid: (state) => state.shipmentOrderid,
    shipmentDetailTableLabel: (state) => state.shipmentDetailTableLabel,
    shipmentMasterItem: (state) => state.shipmentMasterItem
  },

  mutations: {
    shipmentAddInputlist: (state, payload) => { state.shipmentAddInputlist = payload },
    shipmentTableLabel: (state, payload) => { state.shipmentTableLabel = payload },
    shipmentOrderid: (state, payload) => { state.shipmentOrderid = payload },
    shipmentDetailTableLabel: (state, payload) => { state.shipmentDetailTableLabel = payload },
    shipmentMasterItem: (state, payload) => { state.shipmentMasterItem = payload }
  },
  actions: {
    setshipmentInputList: async (state) => {
      state.commit('searchInputList', state.getters.shipmentSearchInputList)
      state.commit('addInputlist', state.getters.shipmentAddInputlist)
      state.commit('tableLabel', state.getters.shipmentTableLabel)
    },
    shipmentSearch: async (state, payload) => {
      console.log('shipmentSearch payload', payload)
      let body = { offset: 0, limit: 10 }
      if (payload === null && JSON.stringify(state.getters.shipmentQuery) === '{}') {
        body.offset = 0
        body.limit = 10
      } else {
        if (payload !== null) {
          body.pre_book_no = payload.orderId
        // } else {
        //   body = state.getters.shipmentQuery
          // body = payload
        }
      }
      if (body === undefined) {
        body = {}
      }
      // Object.values(state.getters.searchInputList).forEach(item => {
      //   if (item.type === 'input' && item.input !== '') {
      //     body[item.key] = item.input
      //   }
      // })
      console.log('shipmentSearch body', body)
      var result = await shipmentSingle(body)
      console.log('shipmentSearch result', result)
      state.dispatch('singleFileInfoData', { rows: result.data, pre_book_no: '', pdf_serial: 0 })
      const orderDataInputList = []
      Object.values(state.getters.containerloadTableLabel).forEach(async (item, idx) => {
        if (idx > 1) {
          orderDataInputList.push({ label: item.label, input: state.getters.editRowData[item.prop], key: item.prop })
        }
      })
      switch (result.status) {
        case 200:
          await Object.values(result.data.data.ListData).forEach(item => {
            item.Edit = faEdit
            item.View = faEye
            // item.pre_book_no = state.getters.orderId !== '' ? :
          })
          state.commit('pageData', result.data.data.PageData)
          state.commit('tableData', result.data.data.ListData)
          state.commit('tableLabel', state.getters.shipmentDetailTableLabel)
          // await state.commit('orderDataInfo', orderDataInputList)
          break
        default:
          state.commit('tableLabel', [])
          state.commit('pageData', [])
          state.commit('tableData', [])
      }
      console.log('tableData', state.getters.tableData)
      console.log('shipmentSearch result??', result)
    },
    beforEditByShipment: async (state, payload) => {
      console.log('beforEditByShipment payload', payload)
      const body = { pre_book_no: payload.orderId }
      if (payload.version !== undefined) {
        body.version = payload.version
      }
      console.log('beforEditByShipment body', body)
      var result = await shipmentSingle(body)
      console.log('beforEditByShipment result', result)
      await state.commit('pdfUrl', result.data.data.ListData[0].file_path)
      state.commit('shipmentMasterItem', result.data.data.ListData[0])
    },
    editDataByShipment: async (state, payload) => {
      console.log('editDataByShipment', payload)
      state.getters.shipmentMasterItem.action = 'U'
      state.getters.shipmentMasterItem.version = payload.version
      console.log('editDataByShipment', JSON.stringify(state.getters.shipmentMasterItem))
      var result = await shipmentAction(state.getters.shipmentMasterItem)
      console.log('editDataByShipment', result)
      state.commit('commRsp', result)
    },
    addDataByShipment: async (state, payload) => {
      state.getters.editRowData.action = 'A'
      state.getters.editRowData.version = 0
      console.log('addDataByShipment', JSON.stringify(state.getters.editRowData))
      var result = await shipmentAction(state.getters.editRowData)
      console.log('addDataByShipment', result)
      state.commit('commRsp', result)
      state.dispatch('shipmentSearch', { orderId: state.getters.editRowData.pre_book_no })
    },
    delDataByShipment: async (state, payload) => {
      const result = []
      await Object.values(state.getters.tableSelectData).forEach(async item => {
        // console.log('item', item.pre_book_no)
        const body = { pre_book_no: item.pre_book_no, version: item.version, action: 'D' }
        console.log('delDataByShipment', JSON.stringify(body))
        result.push(await shipmentAction(body))
      })
      console.log('delDataByShipment', result)
      state.commit('commRsp', result[0])
      state.dispatch('shipmentSearch')
    },
    shipmentHandleCurrentChange: async (state, payload) => {
      const currentPage = payload
      var result = {}
      const body = {
        offset: (parseInt(currentPage) - 1) * parseInt(state.getters.pageData.limit),
        limit: 10
      }
      if (JSON.stringify(state.getters.shipmentQuery) !== '{}') {
        body.track_point = state.getters.shipmentQuery.track_point
      }
      Object.values(state.getters.searchInputList).forEach(item => {
        if (item.type === 'input' && item.input !== '') {
          body[item.key] = item.input
        }
      })
      result = await shipmentList(body)
      result.data.data.PageData.totalPage = state.getters.pageData.totalPage
      result.data.data.PageData.currentPage = currentPage
      state.commit('pageData', result.data.data.PageData)
      state.commit('tableData', result.data.data.ListData)
      // console.log('handleCurrentChange body', body)
      // console.log('handleCurrentChange result', result)
    }
  }
}

export default shipment
