<template>
  <div>
    <base-dialog
      :show="openDialog"
      :title="title"
      @close="$emit('handleClose')"
      @save="handleSave"
      @delete="$emit('deleteCL')"
      :isDelete="clIn && actionType !== 'add'"
    >
      <template #default>
        <div class="addClin">
          <div class="addClin__top">
            <el-checkbox
              class="transit"
              :value="isTransit"
              @change="handleTransitChange"
            >
              崴航中轉
            </el-checkbox>
            <!-- <div class="transit-block" v-if="isTransit">
              <div class="form-control">
                <label>船名</label>
                <el-select
                  filterable
                  :filter-method="filterKeyWordList"
                  placeholder="船名"
                  v-model="conditionRow.transit_vsl_name"
                  :disabled="!isAdd"
                  @change="() => handleTransitFieldChange('transit_vsl_name')"
                >
                  <el-option
                    v-for="item in column[4].options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div class="form-control">
                <label>航次</label>
                <el-select
                  filterable
                  placeholder="航次"
                  v-model="conditionRow.transit_voyage"
                  :disabled="!isAdd"
                  @change="() => handleTransitFieldChange('transit_voyage')"
                >
                  <el-option
                    v-for="item in column[5].options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div class="form-control">
                <label>船公司</label>
                <el-select
                  filterable
                  placeholder="船公司"
                  :disabled="!isAdd"
                  v-model="conditionRow.transit_carrier"
                  @change="() => handleTransitFieldChange('transit_carrier')"
                >
                  <el-option
                    v-for="item in column[6].options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </div> -->
            <div class="form-control">
              <label>S/O</label>
              <input
                type="text"
                placeholder="請輸入 S/O"
                v-model="conditionRow.so_no"
              />
            </div>
            <div class="form-control">
              <label>客戶</label>
              <el-select
                v-if="isTransit"
                v-model="conditionRow.customer_code"
                placeholder="請選擇"
                filterable
              >
                <el-option label="崴航台北" value="KFICTPE"></el-option>
                <el-option label="崴航台中" value="KFICTXG"></el-option>
                <el-option label="崴航高雄" value="KFICKHH"></el-option>
                <el-option label="崴航中轉" value="KFICPRC"></el-option>
                <el-option label="崴航進口" value="KFICIMP"></el-option>
              </el-select>
              <el-select
                v-else
                v-model="conditionRow.customer_code"
                placeholder="請選擇"
                filterable
                :filter-method="filterCustomerCode"
              >
                <el-option
                  v-for="item in column[2].options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="form-control">
              <label>SALES</label>
              <el-select
                v-model="conditionRow.sales_code"
                placeholder="請選擇"
              >
                <el-option
                  v-for="item in column[0].options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="form-control">
              <label>同櫃</label>
              <input
                type="text"
                placeholder="請輸入同一英文字母"
                v-model="conditionRow.van_code"
                @input="
                  e => {
                    conditionRow.van_code = limitSingleLetter(e.target.value)
                  }
                "
              />
            </div>
            <div class="form-control">
              <label>配櫃備註</label>
              <input
                type="text"
                placeholder="請輸入備註"
                v-model="conditionRow.note"
              />
            </div>
          </div>
          <div class="addClin__btm">
            <div class="form-control">
              <label>重量 (KGS)</label>
              <input
                type="number"
                placeholder="請輸入重量"
                v-model="conditionRow.cargo_gross_wt"
                @input="
                  e => {
                    conditionRow.cargo_gross_wt = limitDecimalPlaces(
                      e.target.value,
                      3
                    )
                  }
                "
              />
            </div>
            <!--
            <div class="form-control">
              <label>重量單位</label>
              <input
                type="text"
                placeholder="請輸入重量單位"
                v-model="conditionRow.weight_unit"
              />
            </div>
            -->
            <div class="form-control">
              <label>材積 (CBM)</label>
              <input
                type="number"
                placeholder="請輸入材積"
                v-model="conditionRow.cargo_measure"
                @input="
                  e => {
                    conditionRow.cargo_measure = limitDecimalPlaces(
                      e.target.value,
                      4
                    )
                  }
                "
              />
            </div>
            <div class="form-control">
              <label>放行件數</label>
              <input
                type="text"
                placeholder="請輸入放行件數"
                v-model="conditionRow.package_no"
                @input="
                  e => {
                    conditionRow.package_no = limitInteger(e.target.value)
                  }
                "
              />
            </div>
            <div class="form-control">
              <label>件數單位</label>
              <el-select
                v-model="conditionRow.package_unit"
                placeholder="請選擇"
                filterable
              >
                <el-option
                  v-for="item in packageTypeSelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>

            <!--
            <div class="form-control">
              <label>材積單位</label>
              <input
                type="text"
                placeholder="請輸入材積單位"
                v-model="conditionRow.measure_unit"
              />
            </div>
            -->
            <div class="form-control">
              <label>航線</label>
              <input
                type="text"
                placeholder="請輸入航線"
                v-model="conditionRow.line_code"
              />
            </div>
            <div class="form-control">
              <label>裝貨港</label>
              <el-select
                v-model="conditionRow.port_load"
                placeholder="請選擇裝貨港"
                :disabled="
                  searchPortLoad !== '' && searchPortLoad !== undefined
                "
              >
                <el-option value="TWKEL" label="基隆"></el-option>
                <el-option value="TWTPE" label="台北"></el-option>
                <el-option value="TWTXG" label="台中"></el-option>
                <el-option value="TWKHH" label="高雄"></el-option>
              </el-select>
            </div>
            <div class="form-control">
              <label>卸貨港</label>
              <el-select
                v-model="conditionRow.port_discharge"
                placeholder="請選擇卸貨港"
              >
                <el-option
                  v-for="item in portDischargeSelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="form-control">
              <label>船公司</label>
              <el-select
                v-model="conditionRow.carrier"
                placeholder="請選擇船公司"
              >
                <el-option
                  v-for="item in column[3].options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="form-control">
              <label>收貨地</label>
              <el-select
                v-model="conditionRow.receipt_place"
                placeholder="請選擇收貨地"
              >
                <el-option value="TWKEL" label="基隆"></el-option>
                <el-option value="TWTPE" label="台北"></el-option>
                <el-option value="TWTYN" label="桃園"></el-option>
                <el-option value="TWTXG" label="台中"></el-option>
                <el-option value="TWKHH" label="高雄"></el-option>
              </el-select>
            </div>
            <div class="form-control" v-if="clOut">
              <label>Coloader</label>
              <el-select
                v-model="conditionRow.coload_agent"
                placeholder="請選擇"
                filterable
                :filter-method="filterColoader"
              >
                <el-option
                  v-for="item in coloaderOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="form-control" v-else></div>
            <div class="form-control">
              <div class="check-box">
                <el-checkbox v-model="clIn" v-if="isClIn" disabled
                  >CL-in</el-checkbox
                >
                <el-checkbox v-model="clOut" v-if="isClOut">CL-out</el-checkbox>
              </div>
            </div>
          </div>
        </div>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import BaseDialog from '../../ui/BaseDialog.vue'
import { getSelectList, getKeyWordSelect, getShipList, queryCfs } from '@/api/cfsApi'
import inputMixin from '@/mixins/inputMixin.js'

export default {
  mixins: [inputMixin],
  props: [
    'openDialog',
    'cfsLiCondition',
    'cfsLiEditCondition',
    'actionType',
    'searchPortLoad',
    'searchCondition'
  ],
  computed: {
    title() {
      return this.actionType === 'add' ? '新增 CL_IN' : '編輯 貨物資料'
    },
    conditionRow() {
      return this.actionType === 'add'
        ? this.cfsLiCondition
        : this.cfsLiEditCondition
    },
    isClIn() {
      if (this.actionType === 'add') {
        return true
      }
      return true
    },
    isClOut() {
      return this.actionType !== 'add'
    },
    clIn: {
      get() {
        return this.conditionRow.cl_in === '1'
      },
      set(value) {
        this.conditionRow.cl_in = value ? '1' : '0'
      }
    },
    clOut: {
      get() {
        return this.conditionRow.cl_out === '1'
      },
      set(value) {
        this.conditionRow.cl_out = value ? '1' : '0'
      }
    },
    packageTypeSelect() {
      return this.$store.getters.getPackageTypeSelect || []
    },
    portDischargeSelect() {
      return this.$store.getters.getPortDischargeSelect || []
    },
    isTransit() {
      return this.conditionRow.is_transit.toString() === '1'
    },
    isAdd() {
      return this.actionType === 'add'
    }
  },
  components: {
    BaseDialog
  },
  data() {
    return {
      column: [
        {
          field: 'sales_code',
          options: []
        },
        {
          field: 'coload_agent',
          options: []
        },
        {
          field: 'customer_code',
          options: []
        },
        {
          field: 'carrier_code',
          options: []
        },
        {
          field: 'vsl_name',
          options: []
        },
        {
          field: 'voyage',
          options: []
        },
        {
          field: 'carrier',
          options: []
        },
        {
          field: 'so_no',
          options: []
        }
      ],
      customersLC: [],
      coloaderOption: []
    }
  },
  methods: {
    async fetchSelectList(val, name) {
      const res = await getSelectList({ basic_type: val })
      if (res.code === '200') {
        this.column.forEach(item => {
          if (item.field === name) {
            item.options = res.data.ListData.map(item => {
              return {
                label: item.Local_Name,
                value: item.Basic_Code
              }
            })
          }
        })
      }
    },
    async fetchCustomersLC() {
      const res = await getSelectList({ basic_type: 'LC' })
      if (res.code === '200') {
        this.customersLC = res.data.ListData

        await this.filterCustomerCode()
      }
    },
    async filterCustomerCode(query) {
      this.column.forEach(item => {
        if (item.field === 'customer_code') {
          if (this.customersLC !== null) {
            if (query === null || query === '') {
              item.options = []
              return
            }

            const filter1 = e =>
              e.Basic_Code.includes(query) ||
              e.English_Name.includes(query) ||
              e.Local_Name.includes(query)

            const pagedList = this.customersLC.filter(filter1).splice(0, 100)
            item.options = pagedList.map(item => {
              return {
                label: item.Local_Name,
                value: item.Basic_Code
              }
            })
          }
        }
      })
    },
    filterColoader(query) {
      if (query === '' || query === null) {
        this.coloaderOption = []
        return
      }
      this.coloaderOption = this.column[1].options.filter(x => x.label.includes(query))
    },
    handleTransitChange(value) {
      this.conditionRow.is_transit = value ? 1 : 0
      this.conditionRow.customer_code = ''
      // this.fetchGetKeyWordList()
    },
    async fetchGetKeyWordList() {
      const index = this.column.findIndex(({ field }) => field === 'vsl_name')
      const res = await getKeyWordSelect({ type: 'vsl_name', dist: 1 })
      if (res.code === '200') {
        const _temp = new Set(res.data.ListData.map(item => item.Value))
        this.column[index].options = Array.from(_temp).map(x => ({
          label: x,
          value: x
        }))
      }
    },
    async fetchVoyageOrCarrier(options) {
      const query = {
        reg_ship: '',
        vsl_name: this.conditionRow.transit_vsl_name,
        voyage: this.conditionRow.transit_voyage,
        receipt_place: '',
        carrier: this.conditionRow.transit_carrier,
        limit: 20,
        offset: 0
      }

      const res = await getShipList({
        ...query,
        limit: 1000,
        dist: 1
      })
      const { ListData } = res.data
      if (res.code === '200') {
        const index = this.column.findIndex(({ field }) => field === options)
        this.column[index].options = ListData.map(item => {
          return {
            label: item[options],
            value: item[options]
          }
        })
        this.column[index].options = [
          ...new Map(this.column[index].options.map(v => [v.label, v])).values()
        ]
      }
    },
    filterKeyWordList(queryString) {
      if (queryString === '') return
      const index = this.column.findIndex(({ field }) => field === 'vsl_name')
      const options = this.column[index].options
      this.column[index].options = options.filter(
        x => x.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      )
    },
    handleTransitFieldChange(field) {
      switch (field) {
        case 'transit_vsl_name':
          this.conditionRow.transit_voyage = ''
          this.conditionRow.transit_carrier = ''
          this.fetchVoyageOrCarrier('voyage')
          this.fetchSoNoList()
          break
      case 'transit_voyage':
          this.conditionRow.transit_carrier = ''
          this.fetchVoyageOrCarrier('carrier')
          this.fetchSoNoList()
          break
      case 'transit_carrier':
          this.fetchSoNoList()
          break
      }
    },
    async fetchSoNoList() {
      const index = this.column.findIndex(({ field }) => field === 'so_no')
      const query = {
        vsl_name: this.conditionRow.transit_vsl_name,
        voyage: this.conditionRow.transit_voyage,
        receipt_place: '',
        carrier: this.conditionRow.transit_carrier,
        dist: 1,
        so_no: ''
      }
      const { data: { ListData } } = await queryCfs(query)
      const options = new Set(ListData.map((item) => item.so_no))
      this.column[index].options = Array.from(options).map(x => ({
        label: x,
        value: x
      }))
    },
    handleSave() {
      if (this.conditionRow.cargo_gross_wt === '') {
        this.conditionRow.cargo_gross_wt = 0
      }
      if (this.conditionRow.cargo_measure === '') {
        this.conditionRow.cargo_measure = 0
      }
      if (this.conditionRow.package_no === '') {
        this.conditionRow.package_no = 0
      }
      this.$emit('save')
    }
  },
  watch: {
    actionType() {
      if (this.actionType === 'add') {
        this.cl_out = false
      } else {
        this.cl_out = this.conditionRow.cl_out === '1'
      }
    },
    openDialog(val) {
      if (val) {
        this.fetchSelectList('SA', 'sales_code')
        this.fetchSelectList('FW', 'coload_agent')
        this.fetchSelectList('CA', 'carrier_code')
        this.fetchCustomersLC()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.check-box {
  display: flex;
}
.addClin {
  display: grid;
  grid-row-gap: 48px;

  &__top {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: max-content 1fr 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 24px;

    .transit {
      grid-column: 1/-1;
    }

    .transit-block {
      grid-column: 1/-1;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 24px;
      width: 100%;
    }
  }

  &__btm {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  & .form-control {
    display: grid;
    grid-template-rows: max-content 38px;
    grid-row-gap: 8px;

    & label {
      font-size: 14px;
      font-weight: 500;
    }

    & input {
      border: 1px solid #e0e1e1;
      border-radius: 4px;
      padding: 8px 16px;

      &::placeholder {
        color: #9ea0a2;
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
