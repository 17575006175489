<template>
  <div>
    <base-dialog
      :show="openDialogSplit"
      title="拆分"
      @close="$emit('handleCloseSplit')"
      @save="$emit('splitBoxSave', { data: splitData, del_list })"
    >
      <template #default>
        <div class="addClin">
          <div class="information">
            <div class="so">
              <h3>S/O</h3>
              <p>{{ dataRow.so_no }}</p>
            </div>
            <div class="customer">
              <h3>客戶</h3>
              <p>{{ dataRow.customer_code }}</p>
            </div>
            <div></div>
            <div class="piece">
              <h3>放行件數</h3>
              <p>{{ total.package_no }}</p>
            </div>
            <div class="kgs">
              <h3>KGS</h3>
              <p>{{ autoFillFloat(total.cargo_gross_wt, 3) }}</p>
            </div>
            <div class="cbm">
              <h3>CBM</h3>
              <p>{{ autoFillFloat(total.cargo_measure, 4) }}</p>
            </div>
          </div>
          <div>
            <div class="split-header">
              <div>拆分</div>
              <div>放行件數</div>
              <div>KGS</div>
              <div>CBM</div>
              <div></div>
            </div>
            <div
              class="split-first"
              v-for="(data, index) in splitData"
              :key="index"
            >
              <div>{{ index + 1 }}</div>
              <div>{{ data.package_no }}</div>
              <div>{{ autoFillFloat(data.cargo_gross_wt, 3) }}</div>
              <div>{{ autoFillFloat(data.cargo_measure, 4) }}</div>
              <div v-if="index === 0"></div>
              <div v-else class="del-button-box" @click="handleDelete(index)">
                <i class="el-icon-delete delete-btn"></i>
              </div>
            </div>
          </div>
          <form @submit.prevent="handleInput">
            <div>
              <label for="piece">放行件數</label>
              <input
                v-model.number="piece"
                type="number"
                placeholder="輸入件數"
                :min="1"
              />
            </div>
            <div>
              <label for="kgs">KGS</label>
              <input
                v-model.number="kgs"
                type="number"
                placeholder="輸入 KGS"
                :min="0.001"
                :step="0.001"
              />
            </div>
            <div>
              <label for="cbm">CBM</label>
              <input
                v-model.number="cbm"
                type="number"
                placeholder="輸入 CBM"
                :min="0.0001"
                :step="0.0001"
              />
            </div>
            <button>新增</button>
          </form>
          <p class="error" v-if="!inputIsValid">＊請輸入有效值</p>
        </div>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import BaseDialog from '../../ui/BaseDialog.vue'
import { getCfsById } from '@/api/cfsApi'
import floatMixin from '@/mixins/floatMixin'

export default {
  props: ['openDialogSplit', 'dataRow'],
  components: {
    BaseDialog
  },
  mixins: [floatMixin],
  data() {
    return {
      piece: 1,
      kgs: 1.000,
      cbm: 1.0000,
      inputIsValid: true,
      splitData: [],
      del_list: [],
      total: {
        package_no: 0,
        cargo_gross_wt: 0,
        cargo_measure: 0
      }
    }
  },
  methods: {
    autoFillFloat (val, decimal) {
      return parseFloat(val).toFixed(decimal)
    },
    handleError() {
      this.inputIsValid = false
    },
    handleValid() {
      this.inputIsValid = true
    },
    handleClear() {
      this.piece = 1
      this.kgs = 1
      this.cbm = 1
      this.splitData = []
      this.del_list = []
      this.inputIsValid = true
      Object.keys(this.total).forEach(item => {
        this.total[item] = 0
      })
    },
    handleDelete(index) {
      this.del_list.push(this.splitData[index]?.id)
      this.addNumber(this.splitData[index])
      this.splitData = this.splitData.filter((item, idx) => {
        return idx !== index
      })
    },
    addNumber(val) {
      this.splitData[0].package_no += val.package_no
      this.splitData[0].cargo_gross_wt = this.FloatAdd(
        this.splitData[0].cargo_gross_wt, val.cargo_gross_wt
      )
      this.splitData[0].cargo_measure = this.FloatAdd(
        this.splitData[0].cargo_measure, val.cargo_measure
      )
    },
    decreaseNumber() {
      this.splitData.forEach((item, index) => {
        if (index === this.splitData.length - 1) {
          this.splitData[0].package_no = this.FloatSubtraction(
            this.splitData[0].package_no,
            item.package_no
          )
          this.splitData[0].cargo_gross_wt = this.FloatSubtraction(
            this.splitData[0].cargo_gross_wt,
            item.cargo_gross_wt
          )
          this.splitData[0].cargo_measure = this.FloatSubtraction(
            this.splitData[0].cargo_measure,
            item.cargo_measure
          )
        }
      })
    },
    handleInput() {
      this.handleCheck()
      if (!this.inputIsValid) return
      this.splitData.push({
        package_no: this.piece,
        cargo_gross_wt: this.kgs,
        cargo_measure: this.cbm
      })
      this.decreaseNumber()
      this.piece = 1
      this.kgs = 1
      this.cbm = 1
    },
    handleCheck() {
      if (this.splitData[0].package_no - this.piece <= 0) {
        return this.handleError()
      }
      if (this.splitData[0].cargo_gross_wt - this.kgs <= 0) {
        return this.handleError()
      }
      if (this.splitData[0].cargo_measure - this.cbm <= 0) {
        return this.handleError()
      }
      this.handleValid()
    },
    async fetchCfsById() {
      const res = await getCfsById({ id: this.dataRow.id, limit: 999 })

      if (res.code === '200') {
        this.splitData = res.data.ListData.map(item => {
          return {
            id: item.id,
            package_no: Number(item.package_no),
            cargo_gross_wt: Number(item.cargo_gross_wt),
            cargo_measure: Number(item.cargo_measure)
          }
        })
        this.splitData.forEach(item => {
          this.total.package_no += item.package_no
          this.total.cargo_gross_wt += item.cargo_gross_wt
          this.total.cargo_measure += item.cargo_measure
        })
        this.splitData.cargo_gross_wt =
          Number(this.splitData.cargo_gross_wt).toFixed(3) * 1
        this.splitData.cargo_measure =
          Number(this.splitData.cargo_measure).toFixed(4) * 1
      }
    }
  },
  watch: {
    openDialogSplit(val, oldVal) {
      if (val) {
        return this.fetchCfsById()
      }
      this.handleClear()
    },
    kgs(val) {
      var tmpVal = Number(val).toFixed(3) * 1
      // 小數點自動補0 到第三位
      if (tmpVal.toString().split('.')[1] === undefined) {
        tmpVal = tmpVal + '.000'
      } else if (tmpVal.toString().split('.')[1].length === 1) {
        tmpVal = tmpVal + '00'
      } else if (tmpVal.toString().split('.')[1].length === 2) {
        tmpVal = tmpVal + '0'
      }
      this.kgs = parseFloat(tmpVal)
    },
    cbm(val) {
      var tmpVal = Number(val).toFixed(4) * 1
      // 小數點自動補0 到第四位
      if (tmpVal.toString().split('.')[1] === undefined) {
        tmpVal = tmpVal + '.0000'
      } else if (tmpVal.toString().split('.')[1].length === 1) {
        tmpVal = tmpVal + '000'
      } else if (tmpVal.toString().split('.')[1].length === 2) {
        tmpVal = tmpVal + '00'
      } else if (tmpVal.toString().split('.')[1].length === 3) {
        tmpVal = tmpVal + '0'
      }
      this.cbm = parseFloat(tmpVal)
    }
  }
}
</script>

<style lang="scss" scoped>
.addClin {
  & .information {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: max-content max-content;
    border-top: 1px solid rgb(190, 190, 190);
    border-left: 1px solid rgb(190, 190, 190);
    margin-bottom: 24px;

    & div {
      display: grid;
      grid-template-columns: 5.6rem max-content;
      text-align: center;
      border-bottom: 1px solid rgb(190, 190, 190);
      border-right: 1px solid rgb(190, 190, 190);

      & > h3 {
        border-right: 1px solid rgb(190, 190, 190);
        padding: 10px;
        background-color: rgb(244, 244, 244);
      }

      & > p {
        padding: 10px;
      }
    }

    & .so {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }

    & .customer {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      border-right: none;
    }

    & .piece {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }

    & .kgs {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
    }

    & .cbm {
      grid-column: 3 / 4;
      grid-row: 2 / 3;
    }
  }

  & .split-header {
    display: grid;
    grid-template-columns: 60px 1fr 1fr 1fr 40px;
    border-top: 1px solid rgb(190, 190, 190);
    border-left: 1px solid rgb(190, 190, 190);

    & div {
      &:first-child {
        text-align: center;
      }

      border-right: 1px solid rgb(190, 190, 190);
      padding: 10px;
      background-color: rgb(244, 244, 244);
    }
  }

  & .split-first {
    display: grid;
    grid-template-columns: 60px 1fr 1fr 1fr 40px;
    border-top: 1px solid rgb(190, 190, 190);
    border-left: 1px solid rgb(190, 190, 190);

    & div {
      &:first-child {
        color: white;
        background-color: #4ba676;
        text-align: center;
      }

      border-right: 1px solid rgb(190, 190, 190);
      border-bottom: 1px solid rgb(190, 190, 190);
      padding: 10px;
      background-color: #f1fff4;
    }

    .del-button-box{
      cursor: pointer;
      &:hover i{
        color: #F00;
      }
    }
  }

  & .split-group li {
    display: grid;
    grid-template-columns: 60px 1fr 1fr 1fr 40px;
    border-left: 1px solid rgb(190, 190, 190);

    & div {
      &:first-child {
        color: white;
        background-color: #4ba676;
        text-align: center;
      }

      border-bottom: 1px solid rgb(190, 190, 190);
      border-right: 1px solid rgb(190, 190, 190);
      padding: 10px;
    }

    & .delete-btn {
      cursor: pointer;
    }
  }

  & form {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr max-content;
    grid-column-gap: 24px;

    & div {
      display: grid;
      grid-template-columns: max-content 1fr;
      grid-column-gap: 8px;
      align-items: center;

      & input {
        padding: 6px;
        border: 1px solid rgb(190, 190, 190);
        border-radius: 4px;
      }
    }

    & button {
      border-radius: 2px;
      padding: 5px 20px;
      display: flex;
      align-items: center;
      background-color: #f7f7f9;
      color: #3b4044;
      border: 1px solid #e0e1e1;
      cursor: pointer;
    }
  }
}
.error {
  margin-top: 12px;
  color: #cc2626;
  font-size: 14px;
}
</style>
