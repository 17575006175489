export default {
  en: {
    Language: 'Language',
    Login: 'Login',
    Account: 'Account',
    Password: 'Password',
    CompanyAccount: 'CompanyAccount',
    Admin: 'Admin',
    ChangePassword: 'Change Password',
    Signout: 'Signout',
    Home: 'Home',
    BookingMgmt: 'Booking Mgmt',
    FormMgmt: 'Form Mgmt',
    MasterFileMgmt: 'Master File Mgmt',
    SystemMgmt: 'System Mgmt',
    Delete: 'Delete',
    Edit: 'Edit',
    Add: 'Add',
    Neworder: 'New Order',
    JobNo: 'Job No',
    VesselName: 'Ship name',
    Voyage: 'Voyage',
    SONo: 'SO No',
    CustomerName: 'Customer Name',
    CustomsClearanceDate: 'Customs Clearance Date',
    ShipDate: 'Ship date',
    HBLNo: 'HB/LNo',
    PLR: 'Port of Receipt',
    POD: 'Unloading Port',
    All: 'All',
    Shipment: 'Shipping Quotation',
    CompleteBooking: 'Complete booking',
    Delivery: 'Goods into the counter',
    CargoMeasure: 'Cargo Measurement',
    SoReleased: 'SO Provided',
    CustomsReleased: 'Exit release',
    CheckReleased: 'Bill of lading check',
    QuotationConfirmed: 'Quote Confirmed',
    DocumentReleased: 'Notice of issuance',
    Departure: 'Estimated departure',
    PouchReceived: 'POUCH file received',
    PrivateKey: 'Private Key',
    Arrival: 'Estimated arrival',
    DoRelease: 'DO release',
    NoData: 'No Data',
    OrderId: 'Order Id',
    Status: 'Status',
    CargoConditionNode: 'Cargo condition node',
    sentence: {
      sentence_001: 'King Freight Blockchain Platform'
    },
    vslName: 'vslName',
    closingDate: 'closingDate',
    onboardDate: 'onboardDate'
  }
}
