<template>
  <div>
    <el-dialog :title="dataList.title" :visible="value" @close="handleClose">
      <el-form :label-position="'top'" :model="dataList" class="form-box">
        <el-form-item
          v-for="(data, index) in dataList.list"
          :key="index"
          :label="data.label"
          :prop="index"
        >
          <template v-if="data.type === 'input'">
            <el-input
              v-model="data.val"
              :placeholder="data.placeholder"
            ></el-input>
          </template>
          <template v-else-if="data.type === 'select'">
            <el-select
              v-model="data.val"
              :placeholder="data.placeholder"
              filterable
            >
              <el-option
                v-for="item in data.options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
          <template v-else-if="data.type === 'date'">
            <el-date-picker
              v-model="data.val"
              type="date"
              :placeholder="data.placeholder"
              format="yyyy/MM/dd"
            >
            </el-date-picker>
          </template>
        </el-form-item>
      </el-form>
      <div slot="footer" :class="dataList.isEdit ? 'dialog-footer' : ''">
        <el-button
          v-if="dataList.isEdit"
          @click="handleDelete"
          class="delete-btn"
          >刪 除</el-button
        >
        <div>
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="handleUpdate">確 定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    dataList: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['close', 'shipDataSave', 'delete'],
  data() {
    return {}
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    handleUpdate() {
      this.$emit('shipDataSave')
    },
    handleDelete() {
      this.$emit('delete')
    },
    checkEmpty(val) {
      console.log(val)
    }
  },
  mounted() {},
  watch: {
    value(val) {}
  }
}
</script>

<style lang="scss" scoped>
.form-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  // grid-row-gap: 1em;

  .el-select,
  .el-date-editor.el-input {
    width: 100%;
  }
  ::v-deep .el-form-item__label {
    padding-bottom: 0;
  }
}
::v-deep .el-dialog {
  border-radius: 8px;
}
::v-deep .el-dialog__body {
  margin: 10px 20px;
  padding: 10px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
  .delete-btn {
    color: #f43f5e;
    background: #fff1f2;
    border-color: #fda4af;
  }
}
</style>
