<template>
  <div :class="$style.root">
    <!-- <navComponent :class="$style.left"/> -->
    <topComponent :class="$style.top" />
    <router-view :class="$style.main" />
    <!-- <div :class="$style.footer">版權所有：© 艾旺科技(股)公司<br>
 I-ONE Information Tech. Co., Ltd.  All Rights Reserved.</div> -->
  </div>
</template>

<script>
import topComponent from '@/views/cfs/components/layout/TheHeader.vue'
export default {
  components: {
    topComponent
  }
}
</script>

<style lang="scss" module>
.root {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas: 'content';
  grid-template-columns: auto;
  grid-template-rows: 76px auto;
}

// .left {
//   width: 100%;
//   height: 100%;
//   grid-area: nav;
//   overflow-y: auto;
//   display: flex;
//   flex-direction: column;
// }

.top {
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 23px;
  box-sizing: border-box;
}

.main {
  width: 100%;
  height: 100%;
}
</style>
