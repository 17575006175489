
// eslint-disable-next-line
import { measureSingle, measureList } from '@/api/measure'
// eslint-disable-next-line
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
const measure = {
  state: {
    measureSearchInputList: [{
      label: '船名Vessel',
      input: '',
      type: 'input',
      key: 'vsl_name'
    }, {
      label: '航次Voyage',
      input: '',
      type: 'input',
      key: 'voyage'
    }, {
      label: '貨櫃場代碼 ',
      input: '',
      type: 'input',
      key: 'cfs_delivery_place'
    }, {
      label: '結關日期',
      input: '',
      type: 'input',
      key: 'closing_date'
    }, {
      label: '公證行代碼',
      input: '',
      type: 'input',
      key: 'marine_survey'
    }],
    measureAddInputlist: [{
      label: 'Job No',
      input: 'JobNo31',
      key: 'pre_book_no',
      msg: ''
    }, {
      label: '船名Vessel',
      input: 'vslName',
      key: 'vsl_name',
      msg: ''
    }, {
      label: '航次Voyage',
      input: 'Voyage',
      key: 'voyage',
      msg: ''
    }, {
      label: 'SO No',
      input: 'soNo',
      key: 'so_no',
      msg: ''
    }, {
      label: 'HB/L No',
      input: 'soNo',
      key: 'o_bl_no',
      msg: ''
    }, {
      label: '客戶名稱',
      input: 'customerCode',
      key: 'customer_code',
      msg: ''
    }, {
      label: '結關日期',
      input: 'closingDate',
      key: 'closing_date',
      msg: ''
    }, {
      label: '裝船日期',
      input: 'onboardDate',
      key: 'onboard_date',
      msg: ''
    }, {
      label: '裝貨港POL',
      input: 'POL',
      key: 'port_load',
      msg: ''
    }, {
      label: '卸貨港POD',
      input: 'POD',
      key: 'port_discharge',
      msg: ''
    }, {
      label: 'POL',
      input: 'POL',
      key: 'port_load',
      msg: ''
    }, {
      label: 'PLD',
      input: 'PLD',
      key: 'delivery_place',
      msg: ''
    }, {
      label: 'VoyID',
      input: 'VoyID',
      key: 'ship_num',
      msg: ''
    }, {
      label: 'salesCode',
      input: 'salesCode',
      key: 'sales_code',
      msg: ''
    }, {
      label: 'entryUserCode',
      input: 'test',
      key: 'entry_user_code',
      msg: ''
    }, {
      label: 'cfsDeliveryPlace',
      input: 'cfsDeliveryPlace',
      key: 'cfs_delivery_place',
      msg: ''
    }, {
      label: 'brokerCode',
      input: 'brokerCode',
      key: 'broker_code',
      msg: ''
    }, {
      label: 'truckCompany',
      input: 'truckCompany',
      key: 'truck_company',
      msg: ''
    }, {
      label: 'carrier',
      input: 'carrier',
      key: 'carrier',
      msg: ''
    }, {
      label: 'selfPodAgent',
      input: 'selfPodAgent',
      key: 'self_pod_agent',
      msg: ''
    }, {
      label: 'ediType',
      input: 'aa',
      key: 'edi_type',
      msg: ''
    }, {
      label: 'transferBy',
      input: 'transferBy',
      key: 'transfer_by',
      msg: ''
    }, {
      label: 'transfer_dt',
      input: '',
      key: 'transferDt',
      msg: ''
    }],
    measureTableLabel: [{
      label: 'View',
      minWidth: 130,
      fixed: 'left',
      icon: true,
      prop: 'View',
      node: 'edit',
      url: 'measure'
    }, {
      label: '船名Vessel',
      minWidth: 200,
      prop: 'vsl_name'
    }, {
      label: '航次Voyage',
      minWidth: 130,
      prop: 'voyage'
    }, {
      label: '貨櫃場代碼',
      prop: 'cfs_delivery_place'
    }, {
      label: '結關日期',
      minWidth: 180,
      prop: 'closing_date'
    }, {
      label: '公證行代碼',
      minWidth: 200,
      prop: 'marine_survey'
    }],
    measureDetailTableLabel: [{
      label: 'version',
      prop: 'version',
      minWidth: 50
    }, {
      label: 'update_time',
      prop: 'transfer_dt',
      minWidth: 50
    }, {
      label: 'uploader',
      prop: 'transfer_by',
      minWidth: 50
    }, {
      label: 'status',
      prop: 'status',
      minWidth: 50,
      fixed: 'left'
    }, {
      label: 'Edit',
      prop: 'Edit',
      icon: true,
      minWidth: 20,
      node: 'Edit',
      key: 'id'
    }, {
      label: 'View',
      prop: 'View',
      icon: true,
      minWidth: 20,
      node: 'View',
      key: 'id'
    }],
    measureOrderid: ''
  },

  getters: {
    measureAddInputlist: (state) => state.measureAddInputlist,
    measureTableLabel: (state) => state.measureTableLabel,
    measureOrderid: (state) => state.measureOrderid,
    measureDetailTableLabel: (state) => state.measureDetailTableLabel,
    measureMasterItem: (state) => state.measureMasterItem,
    measureSearchInputList: (state) => state.measureSearchInputList
  },

  mutations: {
    measureAddInputlist: (state, payload) => { state.measureAddInputlist = payload },
    measureTableLabel: (state, payload) => { state.measureTableLabel = payload },
    measureOrderid: (state, payload) => { state.measureOrderid = payload },
    measureDetailTableLabel: (state, payload) => { state.measureDetailTableLabel = payload },
    measureMasterItem: (state, payload) => { state.measureMasterItem = payload }
  },
  actions: {
    setmeasureInputList: async (state) => {
      state.commit('searchInputList', state.getters.measureSearchInputList)
      state.commit('addInputlist', state.getters.measureAddInputlist)
    },
    measureSearch: async (state, payload) => {
      // console.log('measureSearch', payload)
      var body = { offset: 0, limit: 10 }
      Object.values(state.getters.searchInputList).forEach(item => {
        // console.log('item', item)
        if (item.type === 'input' && item.input !== '') {
          body[item.key] = item.input
        }
      })
      console.log('measureSearch body', body)
      var result = await measureList(body)
      console.log('measureSearch result', result)
      const orderDataInputList = []
      Object.values(state.getters.measureTableLabel).forEach(async (item, idx) => {
        if (idx > 1) {
          orderDataInputList.push({ label: item.label, input: state.getters.editRowData[item.prop], key: item.prop })
        }
      })
      switch (result.status) {
        case 200:
          await Object.values(result.data.data.ListData).forEach(item => {
            // item.Edit = faEdit
            item.View = faEye
            state.getters.measureTableLabel[1].path = 'measure'
            state.getters.measureTableLabel[1].prop2 = 'file_path'
            // item.pre_book_no = state.getters.orderId
          })
          state.commit('pageData', result.data.data.PageData)
          state.commit('tableData', result.data.data.ListData)
          state.commit('tableLabel', state.getters.measureTableLabel)
          await state.commit('orderDataInfo', orderDataInputList)
          // state.commit('tableLabel', state.getters.measureDetailTableLabel)
          break
        default:
          state.commit('pageData', [])
          state.commit('tableData', [])
          await state.commit('orderDataInfo', [])
      }
    },
    measureDetailSearch: async (state, payload) => {
      // console.log('measureSearch', payload)
      const body = { offset: 0, limit: 10, ship_num: payload }
      state.dispatch('getPdfEmailList', 'MS')
      Object.values(state.getters.searchInputList).forEach(item => {
        // console.log('item', item)
        if (item.type === 'input' && item.input !== '') {
          body[item.key] = item.input
        }
      })
      console.log('measureDetailSearch body', body)
      var result = await measureSingle(body)
      console.log('measureDetailSearch result', result)
      state.dispatch('singleFileInfoData', { rows: result.data, pre_book_no: '', pdf_serial: 0 })
      const orderDataInputList = []
      Object.values(state.getters.measureTableLabel).forEach(async (item, idx) => {
        if (idx > 0) {
          orderDataInputList.push({ label: item.label, input: state.getters.editRowData[item.prop], key: item.prop })
        }
      })

      // await state.commit('pdfUrl', result.data.data.ListData[0].file_path)
      switch (result.status) {
        case 200:
          await Object.values(result.data.data.ListData).forEach(item => {
            item.Edit = faEdit
            item.View = faEye
            // item.pre_book_no = state.getters.orderId
          })
          state.commit('pageData', result.data.data.PageData)
          state.commit('tableData', result.data.data.ListData)
          state.commit('tableLabel', state.getters.measureDetailTableLabel)
          await state.commit('orderDataInfo', orderDataInputList)
          break
        default:
          state.commit('pageData', [])
          state.commit('tableData', [])
          await state.commit('orderDataInfo', [])
      }
      console.log('tableData', state.getters.tableData)
      console.log('measureSearch result', result)
    },
    beforEditBymeasure: async (state, payload) => {
      var result = await measureSingle({ pre_book_no: payload.orderId })
      console.log('beforEditBymeasure', result)
      state.commit('measureMasterItem', result.data.data.ListData[0])
    },
    measureHandleCurrentChange: async (state, payload) => {
      const currentPage = payload
      var result = {}
      const body = {
        offset: (parseInt(currentPage) - 1) * parseInt(state.getters.pageData.limit),
        limit: 10
      }
      console.log('measureHandleCurrentChange', JSON.stringify(state.getters.measureQuery))
      if (JSON.stringify(state.getters.measureQuery) !== '{}' && state.getters.measureQuery !== undefined) {
        body.track_point = state.getters.measureQuery.track_point
      }
      Object.values(state.getters.searchInputList).forEach(item => {
        if (item.type === 'input' && item.input !== '') {
          body[item.key] = item.input
        }
      })
      result = await measureList(body)
      console.log('measureHandleCurrentChange body', body)
      console.log('measureHandleCurrentChange result', result)
      switch (result.status) {
        case 200:
          await Object.values(result.data.data.ListData).forEach(item => {
            item.Edit = faEdit
            item.View = faEye
            // item.pre_book_no = state.getters.orderId
          })
          result.data.data.PageData.totalPage = state.getters.pageData.totalPage
          result.data.data.PageData.currentPage = currentPage
          state.commit('pageData', result.data.data.PageData)
          state.commit('tableData', result.data.data.ListData)
          break
        default:
          state.commit('pageData', [])
          state.commit('tableData', [])
          await state.commit('orderDataInfo', [])
      }
    }
  }
}

export default measure
