<template>
  <div>
    <div v-if="show" class="backdrop"></div>
    <transition name="dialog">
      <dialog open v-if="show">
        <header>
          <slot name="header">
            <h2>{{ title }}</h2>
            <span @click="tryClose">
              <i class="el-icon-close"></i>
            </span>
          </slot>
        </header>
        <section>
          <slot></slot>
        </section>
        <hr />
        <menu v-if="!fixed">
          <slot name="actions">
            <div class="flex">
              <div>
                <button
                  v-if="isDelete"
                  class="del"
                  type="button"
                  @click="$emit('delete')"
                >
                  刪除
                </button>
              </div>
              <div class="btn-box">
                <button class="cancel" type="button" @click="tryClose">
                  取消
                </button>
                <button type="button" @click="$emit('save')">確認</button>
              </div>
            </div>
          </slot>
        </menu>
      </dialog>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false
    },
    isDelete: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close'],
  methods: {
    tryClose() {
      if (this.fixed) {
        return
      }
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.591);
  z-index: 10;
}

dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 300px;
  max-height: 100vh;
  z-index: 100;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  background-color: white;
  overflow-x: hidden;
}

header {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;

  & span {
    cursor: pointer;
  }
}

header h2 {
  margin: 0;
}

section {
  padding: 1rem;
}

hr {
  border: 0.5px solid #d7d7d7;
  margin: 1rem;
}

menu {
  padding: 1rem;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 1rem;
  justify-content: flex-end;
  margin: 0;
}

button {
  background-color: #4ba676;
  border: none;
  border-radius: 2px;
  color: #ffffff;
  padding: 5px 20px;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &.cancel {
    background-color: #f7f7f9;
    color: #3b4044;
    border: 1px solid #e0e1e1;
  }
  &.del {
    background-color: #fff1f2;
    color: #f43f5e;
    border: 1px solid #fda4af;
  }
}
.flex {
  width: 600px;
  display: flex;
  justify-content: space-between;

  .btn-box {
    width: 200px;
    display: flex;
    justify-content: flex-end;
    button {
      margin-left: 12px;
    }
  }
}

// .dialog-enter-from,
// .dialog-leave-to {
//   opacity: 0;
//   transform: scale(0.8);
// }

// .dialog-enter-active {
//   transition: all 0.3s ease-out;
// }

// .dialog-leave-active {
//   transition: all 0.3s ease-in;
// }

// .dialog-enter-to,
// .dialog-leave-from {
//   opacity: 1;
//   transform: scale(1);
// }

// @media (min-width: 768px) {
//   dialog {
//     left: calc(50% - 20rem);
//     width: 40rem;
//   }
// }
</style>
