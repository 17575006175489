<template>
  <div>
    <base-dialog
      :show="openDialogOver"
      title="提示！"
      @close="$emit('handleClose')"
      @save="$emit('confirm')"
    >
      <div>
        <label>因超過貨櫃裝載上限以致無法儲存此次變更，是否選擇直接切換至 配櫃 功能進行修改？</label>
      </div>
    </base-dialog>
  </div>
</template>

<script>
import BaseDialog from '../../ui/BaseDialog.vue'

export default {
  props: ['openDialogOver'],
  components: {
    BaseDialog
  }
}
</script>

<style scoped>
label {
  font-size: 17px;
  font-weight: 500;
}
</style>
