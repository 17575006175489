export default {
  methods: {
    FloatAdd(arg1, arg2) {
      let r1, r2
      try {
        r1 = arg1.toString().split('.')[1].length
      } catch (e) {
        r1 = 0
      }
      try {
        r2 = arg2.toString().split('.')[1].length
      } catch (e) {
        r2 = 0
      }
      const m = Math.pow(10, Math.max(r1, r2))
      return (this.FloatMul(arg1, m) + this.FloatMul(arg2, m)) / m
    },
    FloatSubtraction(arg1, arg2) {
      let r1, r2
      try {
        r1 = arg1.toString().split('.')[1].length
      } catch (e) {
        r1 = 0
      }
      try {
        r2 = arg2.toString().split('.')[1].length
      } catch (e) {
        r2 = 0
      }
      const m = Math.pow(10, Math.max(r1, r2))
      return (arg1 * m - arg2 * m) / m
    },
    FloatMul(arg1, arg2) {
      let m = 0
      const s1 = arg1.toString()
      const s2 = arg2.toString()
      try {
        m += s1.split('.')[1].length
      } catch (e) {}
      try {
        m += s2.split('.')[1].length
      } catch (e) {}
      return (
        (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
        Math.pow(10, m)
      )
    }
  }
}
