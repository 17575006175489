export default {
  setSelectedKind(state, payload) {
    const boxTypeId = state.searchResultId
    const boxTypeIndex = state.boxTypes.findIndex(
      element => element.id === boxTypeId
    )
    const kindIndex = state.boxTypes[boxTypeIndex].kinds.findIndex(
      element => element.id === payload.kindId
    )

    state.boxTypes[boxTypeIndex].kinds[kindIndex].isSelected = !state.boxTypes[
      boxTypeIndex
    ].kinds[kindIndex].isSelected
  },
  setSelectedDetailItem(state, payload) {
    console.log(payload)
    const boxTypeId = state.searchResultId
    // const selectedBoxDetail =  state.boxDetails.filter(ele => ele.searchResultId === boxTypeId)
    // const a = selectedBoxDetail.filter(de => de.id === payload.kindId)
    // console.log(a)

    state.boxDetails.forEach(ele => {
      if (ele.searchResultId === boxTypeId) {
        if (ele.id === payload.kindId) {
          ele.selected = !ele.selected
        }
      }
    })
  },
  setGetPackageTypeSelect(state, value) {
    state.packageTypeSelect = value
  },
  setPortDischargeSelect(state, value) {
    state.portDischargeSelect = value
  },
  setCfsSearchBarCondition(state, value) {
    state.cfsSearchBarCondition.reg_ship = value.reg_ship
    state.cfsSearchBarCondition.vsl_name = value.vsl_name
    state.cfsSearchBarCondition.voyage = value.voyage
    state.cfsSearchBarCondition.receipt_place = value.receipt_place
    state.cfsSearchBarCondition.carrier = value.carrier
    state.cfsSearchBarCondition.port_load = value.port_load
  }
}
