import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/cfsIndex.vue'
// import Home from '@/views/home/index.vue'
import Cfs from '@/views/cfs/index.vue'
import ShippingSchedule from '@/views/cfs/shippingSchedule'
import heavyMaterials from '@/views/cfs/heavyMaterials'
import shippingNotice from '@/views/cfs/shippingNotice'
import shippingNoticeList from '@/views/cfs/shippingNoticeList'
import shippingNoticeEdit from '@/views/cfs/shippingNoticeEdit'
// import PrivateKey from '@/views/privateKey/index.vue'
// import ChangePassword from '@/views/systemfile/changePassword.vue'
// import List from '@/router/list/index.js'
// import Spouch from '@/router/spouch/index.js'
import Login from '@/login.vue'
import ResetPassword from '@/views/resetPassword.vue'
import Usermanagement from '@/views/systemfile/usermanagement.vue'
// import { refresh } from '@/api/login'
// import { checkToken } from '@/api/system'
Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/home',
  //   component: Cfs
  // },
  {
    path: '/login',
    component: Login,
    name: 'login'
  },
  {
    path: '/reset_password/:uuid/',
    component: ResetPassword,
    name: 'resetPassword'
  },
  {
    path: '/',
    component: Layout,
    redirect: { name: 'home' },
    children: [
      {
        path: 'home',
        name: 'home',
        component: ShippingSchedule,
        meta: { title: 'Shipping Schedule', class: 'home', index: 1 }
      },
      {
        path: 'shippingNotice',
        component: shippingNotice,
        meta: { title: 'Shipping Notice', class: 'home', index: 2 },
        children: [
          {
            path: '',
            name: 'shippingNotice',
            component: shippingNoticeList,
            meta: { title: 'Shipping Notice List', class: 'home', index: 2 }
          },
          {
            path: ':id',
            name: 'shippingNoticeEdit',
            component: shippingNoticeEdit,
            meta: { title: 'Shipping Notice Edit', class: 'home', index: 2 }
          },
          {
            path: 'add',
            name: 'shippingNoticeAddNew',
            component: shippingNoticeEdit,
            meta: { title: 'Shipping Notice add', class: 'home', index: 2 }
          }
        ]
      },
      {
        path: 'heavyMaterials',
        name: 'heavyMaterials',
        component: heavyMaterials,
        meta: { title: 'CFS', class: 'home', index: 3 }
      },
      {
        path: 'cabinet',
        name: 'cabinet',
        component: Cfs,
        meta: { title: 'Cabinet', class: 'home', index: 4 }
      },
      {
        path: 'usermanagement',
        name: 'usermanagement',
        component: Usermanagement,
        meta: { title: '使用者管理', class: 'userList', index: 5 }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const isLogin =
    localStorage.getItem('accessToken') !== null &&
    localStorage.getItem('userid')
  if (isLogin) {
    if (Object.keys(router.app.$options.store.getters.pageData).length > 0) {
      router.app.$options.store.commit('currentPagePath', to.name)
    }
    router.app.$options.store.dispatch('preProcessinghandler', to)
    sessionStorage.removeItem('referrer')
    next()
  } else {
    if (to.path !== '/login' && to.name !== 'resetPassword') {
      sessionStorage.setItem('referrer', JSON.stringify(to))
      next('/login')
    } else {
      next()
    }
  }
})

export default router
