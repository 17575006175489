<template>
  <div class="container">
    <section class="search-counter-wrapper">
      <search-bar
        ref="searchBar"
        :condition="searchCondition"
        @clear="handleClear"
        @query="handleSearch"
        :showAllOption="false"
        @update="updateSearchCondition"
      ></search-bar>
      <counter-info
        :row="generalData"
        :cfsList="cfsList"
        :boxTypeData="boxTypeData"
      ></counter-info>
    </section>
    <section v-if="startQuery">
      <box-status
        :cfsVanList="cfsVanList"
        :cfsList="cfsList"
        :csfSunList="csfSunList"
        :searchCondition="searchCondition"
        :carrier="carrier"
        @query="fetchAllData"
      ></box-status>
    </section>
    <section v-if="startQuery">
      <box-data
        :tableData="cfsList"
        :searchCondition="searchCondition"
        :carrier="carrier"
        :searchPortLoad="searchPortLoad"
        @query="fetchCfsList"
      ></box-data>
    </section>
    <HeavyMaterialsFilterIdDialog
      :value="showDialog"
      :dataList="shipListData"
      :total="total"
      @close="closeDialog"
      @fetchListById="handleHeavyMaterialsFilterIdDialogConfirm"
      @handlePage="handleCurrentChange"
    />
  </div>
</template>

<script>
import SearchBar from './section/search/SearchBar.vue'
import CounterInfo from './section/counter/CounterInfo.vue'
import BoxStatus from './section/status/BoxStatus.vue'
import BoxData from './section/data/BoxData.vue'
import { getHeavyMaterialsList, getCfsVanSun, getShipList } from '@/api/cfsApi'
import HeavyMaterialsFilterIdDialog from './section/dialog/heavyMaterialsFilterIdDialog'
import { getCfsVanTypeList } from '@/api/cfsVanTypeApi'

export default {
  components: {
    SearchBar,
    CounterInfo,
    BoxStatus,
    BoxData,
    HeavyMaterialsFilterIdDialog
  },
  data() {
    return {
      total: 0,
      searchCondition: {
        reg_ship: '',
        vsl_name: '',
        voyage: '',
        receipt_place: '',
        port_load: '',
        carrier: '',
        limit: 20,
        offset: 0
      },
      generalData: {
        // port_discharge: '-',
        closing_date: '-',
        onboard_date: '-'
        // carrier: '-'
      },
      cfsList: [],
      cfsVanList: [],
      csfSunList: [],
      shipListData: {
        id: null,
        title: '船期主檔',
        list: []
      },
      showDialog: false,
      startQuery: false,
      boxTypeData: [],
      searchPortLoad: undefined
    }
  },
  computed: {
    params() {
      return this.$route.params
    },
    paramsId() {
      return this.shipListData.id || this.$route.params.id || null
    },
    carrier() {
      return this.searchCondition.carrier
    },
    isCfsSearchBarConditionEmpty() {
      return this.$store.getters.isCfsSearchBarConditionEmpty
    }
  },
  methods: {
    closeDialog() {
      this.showDialog = false
    },
    handleCurrentChange(val) {
      this.searchCondition.offset = (val - 1) * 20
      this.fetchList()
    },
    handleClear() {
      delete this.shipListData.id
      this.cfsList = []
      this.csfSunList = []
      this.searchPortLoad = this.searchCondition.port_load
      this.generalData.closing_date = '-'
      this.generalData.onboard_date = '-'
    },
    handleSearch() {
      this.handleClear()
      this.fetchList()
    },
    fetchAllData () {
      this.fetchGetCfsVanSun()
      this.fetchCfsList()
    },
    async fetchList() {
      const res = await getShipList({ ...this.searchCondition, dist: 1, dist_pod: 1 })
      const { ListData: listData, PageData: pageData } = res.data

      if (res.code === '200') {
        this.shipListData.list = listData
        this.total = pageData.count

        // this.handlePortDischargeSelect(listData)

        if (listData.length === 1) {
          this.$set(this.shipListData, 'id', listData[0].id)
          this.generalData = {
            // port_discharge: listData[0].port_discharge,
            closing_date: this.handleDateFormat(listData[0].closing_date),
            onboard_date: this.handleDateFormat(listData[0].onboard_date)
            // carrier: listData[0].carrier
          }
          return this.fetchCfsList()
        }

        this.showDialog = true
      }
    },

    handlePortDischargeSelect(listData) {
      const portDischargeSelect = listData.map(item => {
        return {
          label: item.port_discharge,
          value: item.port_discharge
        }
      })

      const filterTheSameKey = [
        ...new Map(portDischargeSelect.map(item => [item.label, item])).values()
      ]

      this.$store.commit('setPortDischargeSelect', filterTheSameKey)
    },

    async fetchCfsList(mounted) {
      if (!mounted || this.$route.params.id) {
        this.startQuery = true
      }

      const found =
        this.shipListData.list.length > 1
          ? this.shipListData.list.find(e => e.id === this.shipListData.id)
          : this.shipListData.list[0]

      if (found) {
        Object.keys(this.searchCondition).forEach(item => {
          return (this.searchCondition[item] = found[item])
        })
        this.generalData = {
          // port_discharge: found.port_discharge,
          closing_date: this.handleDateFormat(found.closing_date),
          onboard_date: this.handleDateFormat(found.onboard_date)
          // carrier: found.carrier
        }
      }

      const res = await getHeavyMaterialsList({
        ...this.searchCondition,
        limit: 1000,
        merge: 1,
        current_page: 'cfs'
      })
      if (res.code === '200') {
        if (res.data.ListData.length === 0) {
          this.cfsList = []
          this.generalData = {
            port_discharge: '-',
            closing_date: '-',
            onboard_date: '-',
            carrier: '-'
          }
          this.$message({
            message: '查無資料',
            type: 'warning'
          })
          return
        }
        this.cfsList = res.data.ListData

        this.cfsList.forEach(item => {
          if (parseFloat(item.package_no) === 0) item.package_no = ''
          if (parseFloat(item.cargo_gross_wt) === 0) item.cargo_gross_wt = ''
          if (parseFloat(item.cargo_measure) === 0) item.cargo_measure = ''
        })
        this.handlePortDischargeSelect(res.data.ListData)
      }

      this.fetchGetCfsVanSun()
    },
    async fetchGetCfsVanSun() {
      const res = await getCfsVanSun({
        ...this.searchCondition
      })
      if (res.code === '200') {
        this.csfSunList = res.data.ListData
      }
    },
    handleDateFormat(date) {
      const format = []
      for (let i = 0, j = 0; i < 3; i++) {
        format.push(date.slice(j, j + 2))
        j += 2
      }
      const [YY, MM, DD] = format
      return `20${YY}/${MM}/${DD}`
      // 77年以後的bug
    },
    async fetchVanType() {
      const { data: res, code } = await getCfsVanTypeList()
      if (code === '200') {
        this.boxTypeData = res.ListData.map(item => {
          return {
            id: item.id,
            van_type: item.van_type,
            name:
              item.van_type === '20' || item.van_type === '40'
                ? `${item.van_type}' GP`
                : item.van_type === '40hq'
                ? "40' HQ"
                : '其他類型',
            measure: parseInt(item.van_cbm, 10),
            maxMeasure: parseInt(item.van_cbm, 10),
            weight: parseInt(item.van_kgs, 10),
            maxWeight: parseInt(item.van_kgs, 10),
            isSelected: false,
            boxNumbers: [],
            count: 0
          }
        })
      }
    },
    updateSearchCondition() {
      this.searchCondition.voyage = ''
      this.searchCondition.carrier = ''
    },
    async handleHeavyMaterialsFilterIdDialogConfirm() {
      await Promise.all([
        this.fetchCfsList(),
        this.$refs.searchBar.fetchVoyageAndCarrierOptionsByVslName()
      ])
    },
    handleBack() {
      this.$router.push({
        name: 'heavyMaterials',
        params: {
          id: this.paramsId,
          ...this.searchCondition
        }
      })
    }
  },
  async mounted() {
    this.startQuery = false
    if (this.paramsId) {
      this.searchCondition.reg_ship = this.params.reg_ship
      this.searchCondition.vsl_name = this.params.vsl_name
      this.searchCondition.voyage = this.params.voyage
      this.searchCondition.receipt_place = this.params.receipt_place
      this.searchCondition.carrier = this.params.carrier
      await this.fetchList()
    } else {
      const cfsSearchBarCondition = this.$store.getters.cfsSearchBarCondition
      this.searchCondition.reg_ship = cfsSearchBarCondition.reg_ship
      this.searchCondition.vsl_name = cfsSearchBarCondition.vsl_name
      this.searchCondition.voyage = cfsSearchBarCondition.voyage
      this.searchCondition.receipt_place = cfsSearchBarCondition.receipt_place
      this.searchCondition.carrier = cfsSearchBarCondition.carrier
      this.searchCondition.port_load = cfsSearchBarCondition.port_load ?? ''
      if (!this.isCfsSearchBarConditionEmpty) await this.fetchList()
    }
    await this.fetchVanType()
  },
  watch: {
    searchCondition: {
      handler(val) {
        this.$store.commit('setCfsSearchBarCondition', val)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.search-counter-wrapper {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 16px;
  padding: 12px 24px;
}
</style>
