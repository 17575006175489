export default {
  searchResultId(state) {
    return state.searchResultId
  },
  counterInfos(state) {
    return state.counterInfos
  },
  boxTypes(state) {
    return state.boxTypes
  },
  boxDetails(state) {
    return state.boxDetails
  },
  getPackageTypeSelect(state) {
    return state.packageTypeSelect
  },
  getPortDischargeSelect(state) {
    return state.portDischargeSelect
  },
  cfsSearchBarCondition(state) {
    return state.cfsSearchBarCondition
  },
  isCfsSearchBarConditionEmpty(state) {
    return Object.values(state.cfsSearchBarCondition).every(x => x === '' || !x)
  }
}
