import ExcelJS from 'exceljs'

export default {
  methods: {
    async readExcelByPath(path) {
      const arrayBuffer = await fetch(path).then((res) => res.arrayBuffer())
      const _workbook = new ExcelJS.Workbook()
      const workbook = await _workbook.xlsx.load(arrayBuffer)
      return workbook
    },
    exportXlsx(workbook, filename) {
      workbook.xlsx.writeBuffer().then((content) => {
        const link = document.createElement('a')
        const blobData = new Blob([content], {
          type: 'application/vnd.ms-excel;charset=utf-8;'
        })
        link.download = `${filename}.xlsx`
        link.href = URL.createObjectURL(blobData)
        link.click()
      })
    },
    copySheet(workbook, worksheet, newSheetName) {
      const copySheet = workbook.addWorksheet('newdemo')
      copySheet.model = Object.assign(worksheet.model, {
        mergeCells: worksheet.model.merges
      })
      copySheet.name = newSheetName
      return copySheet
    },
    async convertToBlob(workbook) {
      const content = await workbook.xlsx.writeBuffer()
      return new Blob([content], {
        type: 'application/vnd.ms-excel;charset=utf-8;'
      })
    }
  }
}
