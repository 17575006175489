<!-- eslint-disable no-unreachable -->
<template>
  <div class="box-data">
    <h1 class="box-data-header">貨物資料</h1>
    <div class="table">
      <div class="table-header">
        <div>
          <h3>已勾選合計：</h3>
        </div>
        <div class="info">
          <h4>件數</h4>
          <span>{{ selectedSummary.package_no }}</span>
        </div>
        <div class="info">
          <h4>重量</h4>
          <span>{{ selectedSummary.cargo_gross_wt }}</span>
        </div>
        <div class="info">
          <h4>材積</h4>
          <span>{{ selectedSummary.cargo_measure }}</span>
        </div>
        <div class="set">
          <el-input placeholder="請輸入櫃號" v-model="setBoxNumberInput">
            <el-button type="primary" slot="append" @click="handleClCfs"
              >配置</el-button
            >
          </el-input>
        </div>
        <div class="search">
          <el-row type="flex" align="middle" :gutter="20">
            <el-col :lg="20" class="search-radio-box">
              <el-radio-group v-model="value" @change="handleSearch">
                <el-radio
                  v-for="option in searchBoxNumOptions"
                  :key="option.value"
                  :label="option.value"
                >
                  {{ option.label }}
                </el-radio>
              </el-radio-group>
            </el-col>
            <el-col>
              <el-input placeholder="請輸入櫃號" v-model="searchBoxNumberInput">
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="handleSearch"
                ></el-button>
              </el-input>
            </el-col>
          </el-row>
        </div>
        <div class="btn-first-box">
          <div @click="toggleTransitMode">
            <button class="btn transit-btn">崴航中轉</button>
          </div>
          <div @click="handleSaveButtonClick">
            <button class="btn save-btn" :disabled="!enableSaveButton">
              儲存
            </button>
          </div>
          <div @click="openDialogExport = true">
            <button class="btn export-btn">匯出</button>
          </div>
        </div>
        <div @click="addFn">
          <button class="btn">新增CL_IN</button>
        </div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <el-popover
          popper-class="dropdown-popup"
          placement="bottom-end"
          trigger="click"
        >
          <div>
            <el-checkbox
              v-model="column_by_prop[prop].checked"
              v-for="prop in Object.keys(column_by_prop)"
              :key="prop"
              >{{ column_by_prop[prop].label }}</el-checkbox
            >
            <el-button class="btn primary margin-top" @click="saveShowColumns"
              >套用</el-button
            >
          </div>
          <el-button slot="reference" class="btn info float-right"
            >隱藏欄位</el-button
          >
        </el-popover>
      </div>
      <div class="table-content">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          height="calc(62vh - 18px)"
          stripe
          border
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <el-table-column
            key="sort"
            label="序號"
            prop="sort"
            width="78"
            sortable
            v-if="column_by_prop['sort'].show"
          >
            <template slot-scope="scope">
              <template>
                <el-input
                  v-model="scope.row['sort']"
                  type="text"
                  autosize
                ></el-input>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            key="van_code"
            label="同櫃"
            prop="van_code"
            width="78"
            sortable
            v-if="column_by_prop['van_code'].show"
          >
            <template slot-scope="scope">
              <template>
                <el-input
                  v-model="scope.row['van_code']"
                  type="text"
                  autosize
                  @input="
                    (val) => {
                      scope.row['van_code'] = limitSingleLetter(val)
                    }
                  "
                ></el-input>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            key="van_no"
            label="櫃號"
            prop="van_no"
            min-width="120"
            width="auto"
            sortable
          >
            <template slot-scope="scope">
              <template>
                <el-input
                  v-model="scope.row['van_no']"
                  type="text"
                  autosize
                ></el-input>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            key="van_no_split"
            label="拆分"
            prop="van_no_split"
            min-width="78"
            width="auto"
            sortable
          ></el-table-column>
          <el-table-column
            key="so_no"
            label="S/O"
            prop="so_no"
            min-width="78"
            width="auto"
            sortable
          ></el-table-column>
          <el-table-column
            key="package_no"
            label="放行件數"
            prop="package_no"
            min-width="105"
            width="auto"
            sortable
          ></el-table-column>
          <el-table-column
            key="package_unit"
            label="單位"
            prop="package_unit"
            min-width="78"
            width="auto"
            sortable
          ></el-table-column>
          <el-table-column
            key="cargo_gross_wt"
            label="KGS"
            prop="cargo_gross_wt"
            min-width="78"
            width="auto"
            sortable
          ></el-table-column>
          <!-- <el-table-column
            label="重量單位"
            prop="weight_unit"
            width="78"
            sortable
          ></el-table-column> -->
          <el-table-column
            key="cargo_measure"
            label="CBM"
            prop="cargo_measure"
            min-width="85"
            width="auto"
            sortable
          ></el-table-column>
          <!-- <el-table-column
            label="材積單位"
            prop="measure_unit"
            width="78"
            sortable
          ></el-table-column> -->
          <!-- <el-table-column label="CL_in" width="85" prop="cl_in" sortable>
            <template slot-scope="scope">
              <i class="el-icon-minus" v-if="scope.row.cl_in === '0'"></i>
              <i class="el-icon-check" v-else></i>
            </template>
          </el-table-column> -->
          <el-table-column
            key="receipt_place"
            label="收貨地"
            prop="receipt_place"
            width="110"
            sortable
            v-if="column_by_prop['receipt_place'].show"
          ></el-table-column>
          <el-table-column
            key="port_load"
            label="裝貨港"
            prop="port_load"
            width="100"
            sortable
            v-if="column_by_prop['port_load'].show"
          ></el-table-column>
          <el-table-column
            key="port_discharge"
            label="卸貨港"
            prop="port_discharge"
            width="100"
            sortable
            v-if="column_by_prop['port_discharge'].show"
          ></el-table-column>
          <el-table-column
            key="customer_code_name"
            label="客戶"
            prop="customer_code_name"
            width="150"
            sortable
            v-if="column_by_prop['customer_code_name'].show"
          >
            <template slot-scope="scope">
              <template v-if="scope.row['is_transit'] === 1">
                <el-select
                  v-model="scope.row['customer_code']"
                  placeholder="請選擇"
                  filterable
                  @change="
                    () => handleTransitSelectChange(scope, 'customer_code')
                  "
                >
                  <el-option
                    v-for="item in transitCustomerOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </template>
              <template v-else>
                {{ scope.row['customer_code_name'].substring(0, 11) }}
              </template>
            </template>
          </el-table-column>
          <el-table-column
            key="sales_code_name"
            label="SALES"
            prop="sales_code_name"
            width="120"
            sortable
            v-if="column_by_prop['sales_code_name'].show"
          >
            <template slot-scope="scope">
              <template v-if="scope.row['is_transit'] === 1">
                <el-select
                  v-model="scope.row['sales_code']"
                  placeholder="請選擇"
                  filterable
                  @change="() => handleTransitSelectChange(scope, 'sales_code')"
                >
                  <el-option
                    v-for="item in salesOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </template>
              <template v-else>
                {{ scope.row['sales_code_name'] }}
              </template>
            </template>
          </el-table-column>
          <el-table-column
            key="cl_out"
            label="CL_out"
            width="95"
            prop="cl_out"
            sortable
            v-if="column_by_prop['cl_out'].show"
          >
            <template slot-scope="scope">
              <el-checkbox
                v-model="scope.row['cl_out']"
                true-label="1"
                false-label="0"
                @change="
                  () => {
                    if (scope.row['cl_out'] === '0')
                      scope.row['coload_agent'] = null
                  }
                "
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            key="coload_agent_name"
            label="Coloader"
            prop="coload_agent_name"
            width="auto"
            sortable
            v-if="column_by_prop['coload_agent_name'].show"
          >
            <template slot-scope="scope">
              <template v-if="scope.row['cl_out'] === '1'">
                <el-select
                  v-model="scope.row['coload_agent']"
                  filterable
                  placeholder="請選擇"
                  :filter-method="filterColoader"
                >
                  <el-option
                    v-for="item in showColoadAgentOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </template>
              <template v-else>
                {{ scope.row['coload_agent_name'] }}
              </template>
            </template>
          </el-table-column>
          <el-table-column
            key="note"
            label="配櫃備註"
            prop="note"
            width="auto"
            sortable
            v-if="column_by_prop['note'].show"
          >
            <template slot-scope="scope">
              <template>
                <el-input
                  v-model="scope.row['note']"
                  type="textarea"
                  autosize
                ></el-input>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="auto" min-width="150">
            <!-- <i @click="editFn" class="el-icon-edit-outline"></i> -->
            <template slot-scope="scope">
              <el-button
                :disabled="scope.row.id === ''"
                size="mini"
                @click="handleEdit(scope.$index, scope.row)"
                >編輯
              </el-button>
              <el-button
                :disabled="scope.row.id === ''"
                size="mini"
                @click="handleSplit(scope.$index, scope.row)"
                >拆分
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <add-ci-in
      :openDialog="openDialog"
      @handleClose="handleClose"
      @save="handleSave"
      @deleteCL="handleDelete"
      :actionType="actionType"
      :cfsLiCondition="cfsLiCondition"
      :cfsLiEditCondition="cfsLiEditCondition"
      :searchPortLoad="searchPortLoad"
      :searchCondition="searchCondition"
    ></add-ci-in>
    <split-box
      :openDialogSplit="openDialogSplit"
      @handleCloseSplit="handleCloseSplit"
      @splitBoxSave="handleSaveSplit"
      :dataRow="cfsLiEditCondition"
    ></split-box>
    <over-capacity-box
      :openDialogOver="openDialogOver"
      @handleClose="openDialogOver = false"
      @save="handleOverCapacityBoxSave"
    ></over-capacity-box>
    <export-box
      :openDialogExport="openDialogExport"
      :cfsList="tableData"
      :searchCondition="searchCondition"
      @handleClose="openDialogExport = false"
    ></export-box>
  </div>
</template>

<script>
import AddCiIn from '../../components/modal/box-data/AddCiIn.vue'
import SplitBox from '../../components/modal/box-data/SplitBox.vue'
import OverCapacityBox from '../../components/modal/box-data/OverCapacityBox.vue'
import ExportBox from '../../components/modal/box-data/ExportBox.vue'

import inputMixin from '@/mixins/inputMixin.js'

import {
  cfsListAction,
  clCfs,
  putHeavyMaterialsSave,
  splitCfs,
  getSelectList
} from '@/api/cfsApi'

import { pageSettingAction, pageSettingList } from '@/api/pageSetting'

export default {
  mixins: [inputMixin],
  components: {
    AddCiIn,
    SplitBox,
    OverCapacityBox,
    ExportBox
  },
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    searchCondition: {
      type: Object,
      default: () => {}
    },
    carrier: {
      type: String,
      default: ''
    },
    searchPortLoad: {
      type: String,
      default: undefined
    }
  },
  computed: {
    selectedSummary() {
      var tmpObj = {
        package_no: 0,
        cargo_gross_wt: 0,
        cargo_measure: 0
      }
      this.multipleSelection.forEach((item) => {
        tmpObj.package_no += parseInt(item.package_no) || 0
        tmpObj.cargo_gross_wt += parseFloat(item.cargo_gross_wt) || 0
        tmpObj.cargo_measure += parseFloat(item.cargo_measure) || 0
      })
      tmpObj.cargo_gross_wt = tmpObj.cargo_gross_wt.toFixed(3)
      tmpObj.cargo_measure = tmpObj.cargo_measure.toFixed(4)
      return tmpObj
    },
    selectedRowId() {
      return this.multipleSelection.map((e) => e.id)
    }
  },
  data() {
    return {
      pageSettingsId: 0,
      column_by_prop: {
        sort: {
          label: '序號',
          checked: true,
          show: true
        },
        van_code: {
          label: '同櫃',
          checked: true,
          show: true
        },
        receipt_place: {
          label: '收貨地',
          checked: true,
          show: true
        },
        port_load: {
          label: '裝貨港',
          checked: true,
          show: true
        },
        port_discharge: {
          label: '卸貨港',
          checked: true,
          show: true
        },
        customer_code_name: {
          label: '客戶',
          checked: true,
          show: true
        },
        sales_code_name: {
          label: 'SALES',
          checked: true,
          show: true
        },
        cl_out: {
          label: 'CL_out',
          checked: true,
          show: true
        },
        coload_agent_name: {
          label: 'Coloader',
          checked: true,
          show: true
        },
        note: {
          label: '配櫃備註',
          checked: true,
          show: true
        }
      },
      cfsLiCondition: {
        action: 'A',
        pre_book_no: '',
        vsl_name: this.searchCondition.vsl_name,
        voyage: this.searchCondition.voyage,
        so_no: '',
        customer_code: '',
        o_bl_no: '',
        closing_date: '',
        onboard_date: '',
        receipt_place: '',
        port_discharge: '',
        port_load: this.searchCondition.port_load,
        delivery_place: '',
        ship_num: '',
        sales_code: '',
        entry_user_code: '',
        cfs_delivery_place: '',
        broker_code: 'NIL',
        truck_company: 'NIL',
        carrier: this.searchCondition.carrier,
        carrier_agent: '',
        self_pod_agent: '',
        pre_depart_date: '',
        pre_arr_date: '',
        cfs_number_desc: '',
        coload_agent: '',
        package_no: '',
        package_unit: '',
        package_uint: 'PLT',
        cargo_gross_wt: '',
        weight_unit: 'KGS',
        measure_unit: 'CBM',
        cargo_measure: '',
        m_bl_no: null,
        edi_type: 'A',
        van_type: '20',
        van_code: '',
        van_no: '',
        van_no_split: null,
        cl_in: '1',
        cl_out: '0',
        note: '',
        reg_ship: '',
        sort: 0,
        is_transit: 0
      },
      cfsLiEditCondition: {
        action: 'E',
        pre_book_no: '',
        vsl_name: this.searchCondition.vsl_name,
        voyage: this.searchCondition.voyage,
        so_no: '',
        customer_code: '',
        o_bl_no: '',
        closing_date: '',
        onboard_date: '',
        receipt_place: '',
        port_discharge: '',
        port_load: this.searchCondition.port_load,
        delivery_place: '',
        ship_num: '',
        sales_code: '',
        entry_user_code: '',
        cfs_delivery_place: '',
        broker_code: 'NIL',
        truck_company: 'NIL',
        carrier: this.searchCondition.carrier,
        carrier_agent: '',
        self_pod_agent: '',
        pre_depart_date: '',
        pre_arr_date: '',
        cfs_number_desc: '',
        coload_agent: '',
        package_no: '',
        package_unit: '',
        package_uint: 'PLT',
        cargo_gross_wt: '',
        weight_unit: 'KGS',
        measure_unit: 'CBM',
        cargo_measure: '',
        m_bl_no: null,
        edi_type: 'A',
        van_type: '20',
        van_code: '',
        van_no: '',
        van_no_split: null,
        cl_in: '0',
        cl_out: '0',
        note: '',
        reg_ship: '',
        sort: 0,
        is_transit: 0
      },
      actionType: '',
      value: 'all',
      searchBoxNumOptions: [
        { label: '全部', value: 'all' },
        { label: '未配櫃', value: 'no' },
        { label: '已配櫃', value: 'yes' }
      ],
      searchBoxNumberInput: '',
      setBoxNumberInput: '',
      multipleSelection: [],
      openDialog: false,
      openDialogSplit: false,
      tableDataShow: [],
      beDelete: [],
      allColoadAgentOptions: [],
      showColoadAgentOptions: [],
      openDialogOver: false,
      onOverCapacityCallBack: undefined,
      enableSaveButton: true,
      openDialogExport: false,
      salesOptions: [],
      transitCustomerOptions: [
        { label: '崴航台北', value: 'KFICTPE' },
        { label: '崴航台中', value: 'KFICTXG' },
        { label: '崴航高雄', value: 'KFICKHH' },
        { label: '崴航中轉', value: 'KFICPRC' },
        { label: '崴航進口', value: 'KFICIMP' }
      ],
      cfsLiEditConditionHistory: {
        index: undefined,
        data: undefined
      }
    }
  },
  methods: {
    async handleOverCapacityBoxSave() {
      await this.onOverCapacityCallBack()
      this.openDialogOver = false
    },
    async handleDelete() {
      const res = await cfsListAction({
        action: 'D',
        id: this.cfsLiEditCondition.id
      })
      if (res.code === '200') {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
        this.openDialog = false
        return this.$emit('query')
      }

      this.$message({
        message: '保存失敗',
        type: 'error'
      })
    },
    handleSearch() {
      let td, i, txtValue
      const table = this.$refs.multipleTable.$el
      const tr = table.getElementsByTagName('tr')
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName('td')[3]
        if (td) {
          const input = td.getElementsByTagName('input')[0]
          txtValue = input.value
          if (this.matchSearch(txtValue)) {
            tr[i].style.display = ''
          } else {
            tr[i].style.display = 'none'
          }
        }
      }
    },
    matchSearch(vanNo) {
      switch (this.value) {
        case 'no':
          return vanNo === null || vanNo === ''
        case 'yes':
          if (this.searchBoxNumberInput !== '') {
            return (
              vanNo !== null &&
              vanNo !== '' &&
              vanNo === this.searchBoxNumberInput
            )
          }
          return vanNo !== null && vanNo !== ''
        default:
          if (
            this.searchBoxNumberInput !== null &&
            this.searchBoxNumberInput !== ''
          ) {
            return (
              vanNo !== null &&
              vanNo !== '' &&
              vanNo === this.searchBoxNumberInput
            )
          }
          return true
      }
    },
    async handleSave() {
      var res = null
      switch (this.actionType) {
        case 'add':
          this.cfsLiCondition.coload_agent = null
          res = await cfsListAction(this.cfsLiCondition)
          break
        case 'edit':
          if (
            this.cfsLiEditCondition.coload_agent === '' ||
            this.cfsLiCondition.cl_out !== '1'
          ) {
            this.cfsLiEditCondition.coload_agent = null
          }
          res = await cfsListAction(this.cfsLiEditCondition)
          break
      }

      if (res.code === '200') {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
        this.$emit('query')
        this.openDialog = false
      } else {
        this.$message({
          message: `保存失敗 ${res.detail}`,
          type: 'error'
        })
      }
      // switch (res.code) {
      //   case '200':
      //     this.$message({
      //       message: '保存成功',
      //       type: 'success'
      //     })
      //     this.$emit('query')
      //     this.openDialog = false
      //     break
      //   case '500':
      //   case '400':
      //   case '401':
      //     this.$message({
      //       message: '保存失敗',
      //       type: 'error'
      //     })
      //     break
      // }
    },
    handleEdit(index, rowData) {
      this.cfsLiEditCondition = rowData
      this.cfsLiEditCondition.action = 'U'
      this.actionType = 'edit'
      this.openDialog = true
      this.cfsLiEditConditionHistory = {
        index,
        data: JSON.parse(JSON.stringify(rowData))
      }
    },
    handleCloseEdit() {
      this.openDialog = false
    },
    handleSplit(index, rowData) {
      this.cfsLiEditCondition = rowData
      this.openDialogSplit = true
    },
    handleCloseSplit() {
      this.openDialogSplit = false
    },
    async handleSaveSplit(data) {
      await splitCfs(data)
      this.$emit('query')
      this.openDialogSplit = false
    },
    handleClose() {
      const { index, data } = this.cfsLiEditConditionHistory
      if (this.actionType === 'edit') {
        this.tableData.splice(index, 1, data)
      }
      this.openDialog = false
    },
    async fetchSaveHeavyMaterials(acceptOver = 0) {
      if (this.tableData.length <= 0) return

      for (let i = 0; i < this.tableData.length; i++) {
        const _data = this.tableData[i]
        if (_data.package_no === '') _data.package_no = '0'
        if (_data.package_unit === '') _data.package_unit = 'PLT'
        if (_data.cargo_gross_wt === '') _data.cargo_gross_wt = '0'
        if (_data.cargo_measure === '') _data.cargo_measure = '0'
      }

      const groupDataByVanCode = this.tableData.reduce((result, current) => {
        if (current.van_code !== '') {
          if (!result[current.van_code]) {
            result[current.van_code] = []
          }
          result[current.van_code].push(current)
        }
        return result
      }, {})

      for (const vanCode in groupDataByVanCode) {
        const _data = groupDataByVanCode[vanCode].find(x => x.van_no !== '')
        if (_data === undefined) continue
        groupDataByVanCode[vanCode].forEach(x => {
          if (x.van_no === '') x.van_no = _data.van_no
        })
      }

      const data = this.tableData.filter((item) => {
        return item.so_no !== '' && item.package_no !== ''
      })

      if (data.length === 0) {
        return this.$message({
          message: '資料不完整，無法儲存',
          type: 'error'
        })
      }

      const res = await putHeavyMaterialsSave({
        data,
        del_list: this.beDelete,
        accept_over: acceptOver !== 1 ? 0 : 1
      })

      if (res.code === '200') {
        this.$emit('query')
        if (res.warnMessage !== '' && !res.warnMessage.match(/尚有已設定同櫃 .* 之貨物未完成配櫃/)) {
          return this.$message({
            message: res.warnMessage,
            type: 'warning'
          })
        }
        return this.$message({
          message: '修改成功',
          type: 'success'
        })
      }

      if (res.detail.includes('已超過貨櫃最大')) {
        this.openDialogOver = true
        this.onOverCapacityCallBack = async () => {
          await this.fetchSaveHeavyMaterials(1)
        }
        return
      }

      if (res.detail.match(/已設定為同櫃 .* 之貨物不可配櫃於不同櫃號之貨櫃/)) {
        this.$message({
          message: '同櫃S/O指定不同櫃號，請先修正後才可儲存；若於修正前離開本畫面，該同櫃S/O之櫃號將全部清空。',
          type: 'error'
        })
        return
      }

      this.$message({
        message: res.detail,
        type: 'error'
      })
    },
    addFn() {
      this.cfsLiCondition.van_code = ''
      this.cfsLiCondition.van_no = ''
      this.cfsLiCondition.so_no = ''
      this.cfsLiCondition.customer_code = ''
      this.cfsLiCondition.package_no = ''
      this.cfsLiCondition.package_unit = 'PLT'
      this.cfsLiCondition.cargo_gross_wt = ''
      this.cfsLiCondition.cargo_measure = ''
      this.cfsLiCondition.coload_agent = ''
      this.cfsLiCondition.sales_code = ''
      this.cfsLiCondition.cl_in = '1'
      this.cfsLiCondition.cl_out = '0'
      this.cfsLiCondition.sort = 0
      this.actionType = 'add'
      this.openDialog = true
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    async saveShowColumns() {
      const showColumns = Object.keys(this.column_by_prop).filter(
        (prop) => this.column_by_prop[prop].checked
      )
      const pageTitles = showColumns.join(',')
      const resp = await pageSettingAction({
        action: 'U',
        pages: 'cabinet',
        page_titles: pageTitles,
        id: this.pageSettingsId
      })

      if (resp && resp.code === '200') {
        Object.keys(this.column_by_prop).forEach((prop) => {
          this.column_by_prop[prop].show = this.column_by_prop[prop].checked
        })
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    async handleClCfs(acceptOver = 0) {
      const body = {
        carrier: this.searchCondition.carrier,
        vsl_name: this.searchCondition.vsl_name,
        voyage: this.searchCondition.voyage,
        port_load: this.searchCondition.port_load,
        van_no: this.setBoxNumberInput,
        ids: this.multipleSelection.map((e) => e.id),
        accept_over: acceptOver !== 1 ? 0 : 1
      }
      try {
        const res = await clCfs(body)

        if (res.code === '200') {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.$emit('query')
          return
        }

        if (res.detail.includes('已超過貨櫃最大')) {
          this.openDialogOver = true
          this.onOverCapacityCallBack = async () => {
            await this.handleClCfs(1)
          }
          return
        }

        this.$message({
          message: '保存失敗',
          type: 'error'
        })
      } catch (e) {
        this.$message({
          message: e.message,
          type: 'error'
        })
      }
    },
    async initShowColumns() {
      let reps = await pageSettingList({
        pages: 'cabinet'
      })

      let init = false
      if (reps && reps.code === '200') {
        const [pt] = reps.data.ListData
        if (pt) {
          const showColumns = pt.page_titles.split(',')
          Object.keys(this.column_by_prop).forEach((prop) => {
            const hasProp = showColumns.indexOf(prop) > -1
            this.column_by_prop[prop].show = hasProp
            this.column_by_prop[prop].checked = hasProp
          })

          this.pageSettingsId = pt.id
          init = true
        }
      }

      if (!init) {
        const pageTitles = Object.keys(this.column_by_prop).join(',')

        reps = await pageSettingAction({
          action: 'A',
          pages: 'cabinet',
          page_titles: pageTitles
        })

        if (reps && reps.code === '200') {
          this.pageSettingsId = reps.data.id
        }
      }
    },
    async fetchSelectList(val, name) {
      const res = await getSelectList({ basic_type: val })
      if (res.code === '200') {
        this.allColoadAgentOptions = res.data.ListData.map((item) => {
          return {
            label: item.Local_Name,
            value: item.Basic_Code
          }
        })
      }
    },
    async handleSaveButtonClick() {
      if (!this.enableSaveButton) return
      this.enableSaveButton = false
      try {
        await this.fetchSaveHeavyMaterials()
        this.enableSaveButton = true
      } catch (error) {
        this.enableSaveButton = true
      }
    },
    filterColoader(query) {
      if (query === '' || query === null) {
        this.showColoadAgentOptions = []
        return
      }
      this.showColoadAgentOptions = this.allColoadAgentOptions
        .filter((x) => x.label.includes(query))
        .map((x) => ({
          label: x.label.substring(0, 11),
          value: x.value
        }))
    },
    async toggleTransitMode() {
      // give default transit value
      for (let i = 0; i < this.multipleSelection.length; i++) {
        const selectedData = this.multipleSelection[i]
        if (selectedData.is_transit === 0) {
          selectedData.is_transit = 1
          selectedData.transit_vsl_name = this.searchCondition.vsl_name
          selectedData.transit_voyage = this.searchCondition.voyage
          selectedData.transit_carrier = this.searchCondition.carrier
          selectedData.customer_code = this.transitCustomerOptions[0].value
          selectedData.customer_code_name = this.transitCustomerOptions[0].label
        }
      }
      this.$refs.multipleTable.clearSelection()
    },
    handleTransitSelectChange(scope, field) {
      if (field === 'customer_code') {
        const index = this.transitCustomerOptions.findIndex(
          ({ value }) => value === scope.row.customer_code
        )
        scope.row.customer_code_name = this.transitCustomerOptions[index].label
      } else if (field === 'sales_code') {
        const index = this.salesOptions.findIndex(
          ({ value }) => value === scope.row.sales_code
        )
        scope.row.sales_code_name = this.salesOptions[index].label
      }
    },
    async fetchSalesOption() {
      const res = await getSelectList({ basic_type: 'SA' })
      if (res.code === '200') {
        this.salesOptions = res.data.ListData.map((item) => {
          return {
            label: item.Local_Name,
            value: item.Basic_Code
          }
        })
      }
    }
  },

  mounted() {
    this.initShowColumns()
    this.fetchSelectList('FW', 'coload_agent')
    this.fetchSalesOption()
  },
  watch: {
    tableData() {
      this.tableDataShow = JSON.parse(JSON.stringify(this.tableData))
      // for (let i = 0; i < this.tableDataShow.length; i++) {
      //   const element = this.tableDataShow[i].

      // }
    },
    'searchCondition.port_load': function () {
      this.cfsLiCondition.port_load = this.searchCondition.port_load
    }
  }
}
</script>
<style lang="scss" scoped>
.box-data {
  background-color: #f5f5f5;

  & .box-data-header {
    padding-bottom: 8px;
    padding-left: 24px;
  }

  & .table {
    background-color: white;
    margin: 8px 24px;
    border-radius: 8px;
    padding: 12px 16px;
  }

  & .table-header {
    display: grid;
    grid-template-columns: repeat(4, max-content) 0.7fr 2fr max-content;
    align-items: center;
    grid-gap: 10px;
    margin-bottom: 8px;

    & h3 {
      font-size: 12px;
      font-weight: 400;
      color: #25282b;
    }

    & .info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 8px;
      display: flex;
      align-items: center;

      & h4 {
        font-size: 13px;
        font-weight: 400;
        color: #696c70;
      }

      & span {
        font-size: 14px;
        font-weight: 500;
      }
    }

    & .set {
      margin: 0 35px;
    }

    & .search {
      .search-radio-box {
        display: flex;
        justify-content: flex-end;
      }

      margin-right: 30px;
    }

    & .btn-first-box {
      & > div {
        display: inline;
      }
    }

    & .btn {
      background-color: #4ba676;
      border: none;
      border-radius: 2px;
      color: #ffffff;
      padding: 5px 20px;
      display: inline-flex;
      align-items: center;

      &:hover {
        cursor: pointer;
      }

      &.cancel {
        background-color: #f7f7f9;
        color: #3b4044;
        border: 1px solid #e0e1e1;
      }

      &.transit-btn {
        background-color: #f69220b6;
      }

      &.export-btn {
        margin-left: 10px;
        background-color: #4894d2;
      }

      &.save-btn {
        margin-left: 10px;
      }
    }

    & .btn[disabled] {
      background-color: rgb(172, 169, 169);

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  ///

  & .el-select .el-input {
    width: 95px;
  }

  & .el-table thead {
    color: #25282b;
    font-weight: 500;
    font-size: 14px;
  }

  .el-table {
    ::v-deep .el-checkbox__inner {
      border: 1px solid black;
    }
  }

  .el-button.btn {
    border: none;
    border-radius: 2px;
    color: #ffffff;
    padding: 5px 20px;
    display: inline-flex;
    align-items: center;
    height: 28.4px;
  }

  .el-button.info {
    background-color: #4894d2;
  }
}
</style>
<style lang="scss">
.el-popover.dropdown-popup {
  .el-checkbox {
    display: block;
  }

  .el-button.btn {
    border: none;
    border-radius: 2px;
    color: #ffffff;
    padding: 5px 20px;
    display: inline-flex;
    align-items: center;
    height: 28.4px;
  }

  .el-button.btn.primary {
    background-color: #4ba676;
  }
}

.float-right {
  float: right;
}

.margin-top {
  margin-top: 4px !important;
}
</style>
