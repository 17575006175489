<template>
  <div class="container">
    <section class="search-counter-wrapper">
      <search-bar
        :condition="searchCondition"
        @clear="handleClear"
        @query="fetchList"
        @update="updateSearchCondition"
      ></search-bar>
    </section>
    <section class="table-box">
      <div class="table">
        <div class="title-box">
          <p>船期主檔</p>
          <!-- <el-button class="btn" @click="showAddDialog = true">
            新增船期
            <i class="el-icon-plus el-icon--right" />
          </el-button> -->
        </div>
        <div v-if="tableData.length > 0">
          <el-table
            :data="tableData" border style="width: 100%;color: #333"
            height="calc(62vh - 18px)"
            @sort-change="handleSortChange"
          >
            <el-table-column
              v-for="(data, index) in column"
              :key="index"
              :prop="data.field"
              :label="data.label"
              :min-width="data.width || 'auto'"
              :sortable="data.sortable"

            >
              <template slot-scope="scope">
                <template v-if="data.field === 'action'">
                  <div class="flex">
                    <el-button
                      v-for="(btn, index) in data.btns"
                      :key="btn"
                      @click="handleClick(scope.row, index)"
                      :disabled="
                        !allowButtonReceiptPlace.includes(
                          scope.row['port_load']
                        )
                      "
                      size="small"
                      class="btn"
                      >{{ btn }}</el-button
                    >
                  </div>
                </template>
                <!-- <template v-else-if="data.field === 'edit'">
                  <el-button
                    @click="handleClick(scope.row)"
                    type="text"
                    icon="el-icon-edit"
                  ></el-button>
                </template> -->
                <template v-else>
                  {{ scope.row[data.field] }}
                </template>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            layout="prev, pager, next"
            :page-size="20"
            :total="total"
          >
          </el-pagination>
        </div>
        <div class="no-data-info" v-else>
          請搜尋相關內容，以帶出船期資料
        </div>
      </div>
    </section>
    <ShipDialog
      :value="showAddDialog"
      :dataList="shipListData"
      @close="closeDialog"
      @shipDataSave="handleSave"
      @delete="handleDelete"
    />
  </div>
</template>

<script>
import SearchBar from './section/search/SearchBar.vue'
import ShipDialog from './section/dialog/shipDialog'
import {
  getShipList,
  getShipListUpdate,
  getSelectList,
  getPortDischargeSelectList
} from '@/api/cfsApi'
import dayjs from 'dayjs'

export default {
  components: {
    SearchBar,
    ShipDialog
  },
  data() {
    return {
      showAddDialog: false,
      currentPage: 0,
      total: 0,
      searchCondition: {
        reg_ship: '',
        vsl_name: '',
        voyage: '',
        port_load: '',
        carrier: '',
        limit: 20,
        offset: 0,
        sort: ''
      },
      column: [
        {
          field: 'reg_ship',
          label: '海掛',
          sortable: false
        },
        {
          field: 'call_sign',
          label: '呼號',
          sortable: false
        },
        {
          field: 'vsl_name',
          label: '船名',
          sortable: true
        },
        {
          field: 'voyage',
          label: '航次',
          sortable: true
        },
        {
          field: 'carrier',
          label: '船公司',
          sortable: true
        },
        {
          field: 'port_discharge',
          label: '卸貨港',
          sortable: true
        },
        {
          field: 'closing_date',
          label: '結關日',
          sortable: true
        },
        {
          field: 'onboard_date',
          label: '裝船日',
          sortable: true
        },
        {
          field: 'port_load',
          label: '裝貨港',
          sortable: true
        },
        {
          field: 'line_code',
          label: '航線',
          sortable: true
        },
        {
          field: 'action',
          label: '操作',
          btns: ['CFS重材', 'CFS配櫃'],
          width: '120px',
          sortable: false
        }
        // {
        //   field: 'edit',
        //   label: '編輯',
        //   width: '30px'
        // }
      ],
      tableData: [],
      shipListData: {
        title: '新增船期',
        isEdit: false,
        id: null,
        list: {
          reg_ship: {
            label: '海掛',
            val: null,
            placeholder: '請輸入海掛',
            type: 'input'
          },
          call_sign: {
            label: '呼號',
            val: null,
            placeholder: '請輸入呼號',
            type: 'input'
          },
          vsl_name: {
            label: '船名',
            val: null,
            placeholder: '請輸入船名',
            type: 'input'
          },
          voyage: {
            label: '航次',
            val: null,
            placeholder: '請輸入航次',
            type: 'input'
          },
          carrier: {
            label: '船公司',
            val: null,
            placeholder: '請輸入船公司',
            type: 'select',
            options: []
          },
          port_discharge: {
            label: '卸貨港',
            val: null,
            placeholder: '請選擇卸貨港',
            type: 'select',
            options: []
          },
          closing_date: {
            label: '結關日',
            val: null,
            placeholder: '請選擇結關日',
            type: 'date'
          },
          onboard_date: {
            label: '裝船日',
            val: null,
            placeholder: '請選擇裝船日',
            type: 'date'
          },
          port_load: {
            label: '裝貨港',
            val: null,
            placeholder: '請選擇裝貨港',
            type: 'select',
            options: [
              {
                label: '基隆',
                value: 'TWKEL'
              },
              {
                label: '台北',
                value: 'TWTPE'
              },
              {
                label: '台中',
                value: 'TWTXG'
              },
              {
                label: '高雄',
                value: 'TWKHH'
              }
            ]
          },
          line_code: {
            label: '航線',
            val: null,
            placeholder: '請輸入航線',
            type: 'input'
          }
        }
      },
      allowButtonReceiptPlace: ['TWTXG', 'TWKHH', 'TWKEL', 'TWTPE']
    }
  },
  methods: {
    handleSortChange({ prop, order }) {
      if (
        [
          'vsl_name',
          'voyage',
          'carrier',
          'port_load',
          'closing_date',
          'onboard_date',
          'line_code',
          'port_discharge'
        ].indexOf(prop) > -1
      ) {
        let queryField = prop
        if (['closing_date', 'onboard_date', 'line_code', 'port_discharge'].indexOf(prop) > -1) {
          queryField = prop + '_min'
        }

        if (order === 'ascending') {
          this.searchCondition.sort = queryField
        } else if (order === 'descending') {
          this.searchCondition.sort = '-' + queryField
        } else {
          this.searchCondition.sort = ''
        }
      } else {
        this.searchCondition.sort = ''
      }
      this.fetchList(false)
    },
    handleCurrentChange(val) {
      this.searchCondition.offset = (val - 1) * 20
      this.fetchList(false)
    },
    clearDialog() {
      this.shipListData.title = '新增船期'
      this.shipListData.isEdit = false
      this.shipListData.id = null
      Object.keys(this.shipListData.list).forEach(item => {
        this.shipListData.list[item].val = null
      })
    },
    closeDialog() {
      this.showAddDialog = false
      this.clearDialog()
    },
    handleEditData(val) {
      this.shipListData.title = '編輯船期'
      this.shipListData.isEdit = true
      this.shipListData.id = val.id
      Object.keys(this.shipListData.list).forEach(item => {
        this.shipListData.list[item].val = val[item]
      })
    },
    handleSave() {
      const { isEdit } = this.shipListData
      if (isEdit) return this.handleEdit()
      this.handleAddNew()
    },
    handleAddNew() {
      const obj = this.handleRefactorData('A')
      this.fetchListUpdate(obj, '新增成功')
    },
    handleEdit() {
      const obj = this.handleRefactorData('U')
      this.fetchListUpdate(obj, '編輯成功')
    },
    handleDelete() {
      const { id } = this.shipListData
      const obj = {
        action: 'D',
        id
      }
      this.fetchListUpdate(obj, '刪除成功')
    },
    handleRefactorData(action) {
      const { list, id } = this.shipListData
      let obj = { action }
      if (action !== 'A') obj = { ...obj, id }

      Object.keys(list).forEach(item => {
        if (['closing_date', 'onboard_date'].includes(item)) {
          return (obj = {
            ...obj,
            [item]: dayjs(list[item].val).format('YYMMDD')
          })
        }
        obj = {
          ...obj,
          [item]: list[item].val
        }
      })
      return obj
    },
    async fetchListUpdate(val, msg) {
      const res = await getShipListUpdate(val)
      if (res.code === '200') {
        this.$message({
          message: msg,
          type: 'success'
        })
        this.closeDialog()
        return this.fetchList()
      }
      this.$message({
        message: res.detail,
        type: 'error'
      })
    },
    handleClear() {
      this.tableData = []
      this.total = 0
      this.searchCondition.offset = 0
    },
    async fetchList(repage = true) {
      if (repage) {
        this.searchCondition.offset = 0
      }
      const res = await getShipList(this.searchCondition)
      if (res.code === '200') {
        this.tableData = res.data.ListData
        this.total = res.data.PageData.count
        this.handleDateFormat('closing_date')
        this.handleDateFormat('onboard_date')
      }
    },
    handleDateFormat(data) {
      this.tableData.forEach(item => {
        const format = []
        for (let i = 0, j = 0; i < 3; i++) {
          format.push(item[data].slice(j, j + 2))
          j += 2
        }
        const [YY, MM, DD] = format
        item[data] = `20${YY}/${MM}/${DD}`
        // 77年以後的bug
      })
    },
    handleClick(val, index = null) {
      switch (index) {
        case 0:
          return this.handleGoHeavyMaterials(val)
        case 1:
          return this.handleGoCabinet(val)
        default:
          this.showAddDialog = true
          return this.handleEditData(val)
      }
    },
    handleGoHeavyMaterials(val) {
      this.$router.push({
        name: 'heavyMaterials',
        params: {
          id: val.id,
          ...val
        }
      })
    },
    handleGoCabinet(val) {
      this.$router.push({
        name: 'cabinet',
        params: {
          id: val.id,
          ...val
        }
      })
    },
    async fetchPortDischargeSelectList() {
      const res = await getPortDischargeSelectList()
      if (res.code === '200') {
        this.shipListData.list.port_discharge.options = res.data.ListData.map(
          item => {
            return {
              label: item.AddressName,
              value: item.code
            }
          }
        )
      }
    },
    async fetchSelectList(val, name) {
      const res = await getSelectList({ basic_type: val })
      if (res.code === '200') {
        const { list } = this.shipListData
        list[name].options = res.data.ListData.map(item => {
          return {
            label: item.Local_Name,
            value: item.Basic_Code
          }
        })
      }
    },
    updateSearchCondition() {
      this.searchCondition.voyage = ''
      this.searchCondition.carrier = ''
    }
  },
  mounted() {
    this.fetchSelectList('CA', 'carrier')
    this.fetchPortDischargeSelectList()
  }
}
</script>

<style lang="scss" scoped>
.search-counter-wrapper {
  width: 50%;
}

.table-box {
  background: #f7f7f9;
  padding: 24px;

  .table {
    padding: 16px;
    background: #fff;
    border-radius: 8px;
    min-height: 62vh;
    display: column;
    justify-content: center;

    .title-box {
      margin: 8px 0 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .btn {
      border: 1px solid #e0e1e1;
      background: #f7f7f9;
      color: #3b4044;
      padding: 8px 10px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .no-data-info {
    color: #87898c;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    min-height: 58vh;
  }
}
.flex {
  display: flex;
  justify-content: flex-start;
}
::v-deep .el-table th.is-leaf {
  color: #333;
}
</style>
