<template>
  <div
    @click="selectType"
    v-if="maxWeight >= 0"
    class="type"
    :class="{ pressed: isSelected }"
  >
    <h3>{{ name }}</h3>
    <div class="capacity-info-box">
      <p v-if="name !== '其他類型'">
        最大容量：{{ maxWeight }} KG／{{ maxMeasure }} CBM
      </p>
    </div>
    <div class="icons">
      <div
        v-for="boxNum in boxNumbers"
        :key="boxNum.number"
        class="icons-wrapper"
      >
        <div class="border"></div>
        <div
          class="width"
          :style="{
            width: vanWidth(boxNum.weight, boxNum.measure)
          }"
        ></div>
        <div class="text">{{ boxNum.number }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['selectType'],
  props: ['name', 'boxNumbers', 'maxWeight', 'maxMeasure', 'isSelected', 'id'],
  methods: {
    selectType() {
      // var matches = this.name.match(/([0-9]+)/)
      this.$emit('selectKind', this.id)
    },
    vanWidth(width, measure) {
      const w = Math.round((width / this.maxWeight) * 100)
      const m = Math.round((measure / this.maxMeasure) * 100)
      return w > m ? `${w}%` : `${m}%`
    }
  }
}
</script>

<style lang="scss" scoped>
.type {
  height: 60px;
  min-width: 20vw;
  margin-left: 24px;
  background-color: #eaeaea;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: max-content 1fr;
  padding: 10px 16px;
  align-items: center;
  cursor: pointer;

  &:last-child {
    margin-right: 24px;
  }

  & h3 {
    font-size: 14px;
    font-weight: 500;
  }

  & .capacity-info-box{
    display: flex;
    justify-content: flex-end;

    p {
      font-size: 12px;
      font-weight: 400;
      color: #87898c;
    }
  }

  & .icons {
    align-self: flex-end;
    display: flex;
    align-items: center;
    position: relative;

    & .icons-wrapper {
      position: relative;
      margin-right: 10px;
      overflow: hidden;

      & .border {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 20px;
        border: 1px solid #c2c4c5;
      }

      & .width {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        height: 20px;
        background-color: #c2c4c5;
        border-top: 1px solid #c2c4c5;
        border-bottom: 1px solid #c2c4c5;
      }

      & .text {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 12px;
        font-weight: 500;
        color: #696c70;
      }
    }
  }

  &.pressed {
    border: 1px solid #83e2a3;
    background-color: #f1fff4;

    & .icons {
      & .icons-wrapper {
        & .border {
          border: 1px solid #83e2a3;
        }

        & .width {
          background-color: #83e2a3;
          border-top: 1px solid #83e2a3;
          border-bottom: 1px solid #83e2a3;
        }

        & .text {
          color: #347769;
        }
      }
    }
  }
}
</style>
