<template>
  <div class="header">
    <img src="@/assets/bb/image_logo_default_x1.png" />
    <el-menu
      :default-active="activeIndex"
      class="elMenuBlock"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="1" class="tabName">船期主檔</el-menu-item>
      <el-menu-item index="2" class="tabName">裝船通知書</el-menu-item>
      <el-menu-item index="3" class="tabName">CFS重材</el-menu-item>
      <el-menu-item index="4" class="tabName">配櫃</el-menu-item>
      <el-menu-item index="5" class="tabName" v-if="isManager">使用者管理</el-menu-item>
    </el-menu>

    <el-dropdown
      trigger="click"
      :class="activeIndex === '3' ? 'margin-right' : ''"
    >
      <span class="el-dropdown-link avatar-box">
        <el-avatar>{{ username.substr(0, 1) }}</el-avatar>
        <p>{{ username }}</p>
        <i class="el-icon-caret-bottom"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item class="logout" @click.native="routerFn('logout')"
          >登出</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
export default {
  data() {
    return {
      username: ''
    }
  },
  props: {
    tabIndex: String
  },
  computed: {
    activeIndex() {
      return this.$route.meta.index.toString()
    },
    isManager() {
      return localStorage.getItem('roleName')?.includes('管理者')
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, typeof key, keyPath)
      const routerName = {
        1: 'home',
        2: 'shippingNotice',
        3: 'heavyMaterials',
        4: 'cabinet',
        5: 'usermanagement'
      }
      if (this.$route.name === routerName[key]) return
      this.$router.push({ name: routerName[key] })
    },
    routerFn(location) {
      if (location === 'logout') {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('pkCode')
        localStorage.removeItem('userid')
        localStorage.removeItem('lang')
        this.$router.push({ name: 'login' })
      } else {
        this.$router.push({ name: location })
      }
    }
  },
  mounted: function() {
    this.username = window.localStorage.getItem('name')
  }
}
</script>
<style scoped lang="scss">
.margin-right {
  margin-right: 80px;
}
.header {
  display: grid;
  grid-template-columns: max-content 1fr max-content max-content;
  align-items: center;
  grid-column-gap: 24px;
  padding: 15px 24px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.04);

  & img {
    width: 64px;
  }

  & p {
    font-size: 14px;
  }
}
.tabName {
  min-width: 118px;
  text-align: center;
}
.elMenuBlock {
  border-bottom: 0 !important;
  margin-left: 24px;
}
.cfsHeader {
  display: flex;
}
.avatar-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    cursor: pointer;
  }

  p {
    margin: 0 12px;
  }
}
.logout {
  padding: 0 60px;
}
</style>
