<template>
  <button :class="{ redColor: color }">
    <div class="icon">
      <slot name="icon"></slot>
    </div>
    <div>
      <slot></slot>
    </div>
  </button>
</template>

<script>
export default {
  props: ['color']
}
</script>

<style lang="scss" scoped>

.redColor {
  background-color: #F56C6C;
}

button {
  background-color: #4ba676;
  border: none;
  border-radius: 2px;
  color: #ffffff;
  padding: 5px 20px;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  & .icon {
    display: flex;

    & img {
      width: 16px;
      margin-right: 6px;
    }
  }
}
</style>
