<template>
  <div class="counter-info">
    <ul class="information">
      <!-- <li>
        <h4>POD</h4>
        <p>{{ row.port_discharge }}</p>
      </li> -->
      <li>
        <h4>結關日</h4>
        <p>{{ row.closing_date }}</p>
      </li>
      <li>
        <h4>裝船日</h4>
        <p>{{ row.onboard_date }}</p>
      </li>
      <!-- <li>
        <h4>船公司</h4>
        <p>{{ row.carrier }}</p>
      </li> -->
    </ul>
    <ul class="summary">
      <li>
        <p>CFS 合計件數</p>
        <h2>{{ cfsTotalPackageNo }}</h2>
      </li>
      <li>
        <p>CFS 合計重量</p>
        <h2>{{ cfsTotalWeight !== 0 ? autoFillFloat(cfsTotalWeight, 3) : "-" }}</h2>
      </li>
      <li>
        <p>CFS 合計材積</p>
        <h2>{{ cfsTotalMeasure !== 0 ? autoFillFloat(cfsTotalMeasure, 4) :"-" }}</h2>
      </li>
      <li>
        <p>CFS 最少貨櫃數（20' GP）</p>
        <h2>{{ gp20Count }}</h2>
      </li>
      <li>
        <p>CFS 最少貨櫃數（40' GP）</p>
        <h2>{{ gp40Count }}</h2>
      </li>
      <li>
        <p>CFS 最少貨櫃數（40' HQ）</p>
        <h2>{{ hq40Count }}</h2>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      default: () => ({})
    },
    cfsList: {
      type: Array,
      default: () => []
    },
    boxTypeData: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    counterInfo() {
      const searchResultId = this.$store.getters.searchResultId
      const counterInfos = this.$store.getters.counterInfos
      return counterInfos.find(info => info.id === searchResultId)
    },
    cfsTotalPackageNo() {
      let tmpPackageNo = 0
      this.cfsList.forEach(item => {
        tmpPackageNo += Number(item.package_no)
      })
      return tmpPackageNo.toFixed(0) * 1 || '-'
    },
    cfsTotalWeight() {
      let tmpWeight = 0
      this.cfsList.forEach(item => {
        tmpWeight += Number(item.cargo_gross_wt)
      })
      return tmpWeight.toFixed(3) * 1 || 0
    },
    cfsTotalMeasure() {
      let tmpMeasure = 0
      this.cfsList.forEach(item => {
        tmpMeasure += Number(item.cargo_measure)
      })
      return tmpMeasure.toFixed(4) * 1 || 0
    },
    gp20Count() {
      return this.getCargoCount('20')
    },
    gp40Count() {
      return this.getCargoCount('40')
    },
    hq40Count() {
      return this.getCargoCount('40hq')
    }
  },
  methods: {
    autoFillFloat (val, decimal) {
      return parseFloat(val).toFixed(decimal)
    },
    getCargoCount(vanType) {
      const boxSpec = this.boxTypeData.find(e => e.van_type === vanType)
      if (boxSpec) {
        const byMeasure = this.cfsTotalMeasure / boxSpec.maxMeasure
        const byWeight = this.cfsTotalMeasure / boxSpec.maxWeight
        return Math.ceil(byMeasure > byWeight ? byMeasure : byWeight) || '-'
      }
      return 0
    }
  }
}
</script>

<style lang="scss" scoped>
.counter-info {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 16px;

  & .information {
    border-radius: 8px;
    border: 1px solid #ededee;
    padding: 15px 20px;
    list-style: none;
    display: grid;
    grid-column: 1fr;
    align-items: center;

    & li {
      display: grid;
      grid-template-columns: 4vw 1fr;
      align-items: center;

      & h4 {
        color: #696c70;
        font-size: 13px;
        font-weight: 400;
      }

      & p {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  & .summary {
    border-radius: 8px;
    border: 1px solid #ededee;
    padding: 15px 20px;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    & li {
      display: grid;
      align-items: center;

      & h2 {
        font-size: 24px;
      }

      & p {
        color: #696c70;
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
}
</style>
