<template>
  <div class="form-box">
    <p class="title">裝船通知表單</p>
    <el-card class="box-card" shadow="never">
      <el-card
        class="my-card"
        shadow="never"
        v-for="(data, index) in formData"
        :key="index"
      >
        <div
          :class="
            ['1', '2', '4'].includes(index)
              ? 'basic'
              : index === '3'
              ? 'mutation'
              : 'single'
          "
        >
          <div v-for="(item, idx) in data" :key="idx">
            <p>{{ item.label }}</p>
            <el-input v-model="item.val"></el-input>
          </div>
        </div>
      </el-card>
      <hr class="color-grey" />
      <div class="btn-box">
        <div>
          <el-button type="danger" @click="handleBack">取消</el-button>
        </div>
        <div>
          <p>狀態： {{ status }}</p>
          <el-button @click="fetchUpload">上傳至ERP</el-button>
          <el-button type="primary" @click="fetchSave">儲存</el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status: '未上傳',
      formData: {
        1: {
          x1: {
            label: 'Job No',
            val: null
          },
          x2: {
            label: '船名 Vessel',
            val: null
          },
          x3: {
            label: 'SO No',
            val: null
          },
          x4: {
            label: '結關日 Closing Date',
            val: null
          },
          x5: {
            label: '收貨港 PLR',
            val: null
          },
          x6: {
            label: '卸貨港 POD',
            val: null
          }
        },
        2: {
          c1: {
            label: '船公司',
            val: null
          },
          c2: {
            label: '船次 Voyage',
            val: null
          },
          c3: {
            label: 'HB/L No',
            val: null
          },
          c4: {
            label: '裝船日 OnBoard',
            val: null
          },
          c5: {
            label: '裝貨港 POL',
            val: null
          },
          c6: {
            label: '裝貨港 POL',
            val: null
          }
        },
        3: {
          v1: {
            label: '客戶',
            val: null
          },
          v2: {
            label: '業務 Sale',
            val: null
          },
          v3: {
            label: '船期編號 VoyID',
            val: null
          },
          v4: {
            label: '報關行',
            val: null
          },
          v5: {
            label: '貨運行',
            val: null
          },
          v6: {
            label: '國外代理商',
            val: null
          },
          v7: {
            label: 'CFS 交貨處（貨櫃場）',
            val: null
          }
        },
        4: {
          b1: {
            label: 'CFS 貨量',
            val: null
          },
          b2: {
            label: 'CY 貨量',
            val: null
          },
          b3: {
            label: 'CY 領櫃處',
            val: null
          },
          b4: {
            label: '領櫃代碼',
            val: null
          },
          b5: {
            label: 'CY 交櫃處',
            val: null
          },
          b6: {
            label: 'CY 交櫃號碼',
            val: null
          }
        },
        5: {
          n1: {
            label: '預計啟航日 ETD',
            val: null
          },
          n2: {
            label: '預計抵達日 ETA',
            val: null
          },
          n3: {
            label: '運輸條款_1',
            val: null
          },
          n4: {
            label: '運輸條款_2',
            val: null
          },
          n5: {
            label: '備註',
            val: null
          }
        }
      }
    }
  },
  computed: {
    pageName() {
      return this.$route.name
    }
  },
  methods: {
    handleBack() {
      this.$router.push({ name: 'shippingNotice' })
    },
    fetchSave() {},
    fetchUpload() {},
    clearData() {
      // if need
      Object.keys(this.formData).forEach(obj => {
        Object.keys(this.formData[obj]).forEach(item => {
          console.log(item)
          this.formData[obj][item].val = null
        })
      })
    },
    fetchForm() {}
  },
  mounted() {
    if (this.pageName === 'shippingNoticeEdit') {
      // 要把資料轉進去
      this.fetchForm()
    }
    // shippingNoticeAddNew
  }
}
</script>

<style lang="scss" scoped>
.color-grey {
  border: 0.5px solid #eee;
}
.form-box {
  padding: 24px;
  box-sizing: border-box;
  background: #f7f7f9;

  .title {
    margin-bottom: 20px;
  }

  .box-card {
    border-radius: 8px;
    border: 0;
  }
}
.my-card {
  background: #f7f7f9;
  border: 0;
  margin-bottom: 20px;

  .basic,
  .single,
  .mutation {
    display: grid;
    grid-column-gap: 16px;
    grid-row-gap: 2rem;
    p {
      margin-bottom: 12px;
      font-size: 0.875rem;
    }
  }

  .basic {
    grid-template-columns: repeat(3, 1fr);
  }
  .mutation {
    grid-template-columns: 0.5fr 0.5fr repeat(2, 1fr);
    div {
      &:nth-child(5) {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }
  .single {
    grid-template-columns: repeat(4, 1fr) 2fr;
  }
}
.btn-box {
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    margin-top: 16px;
    > * {
      margin-left: 12px;
    }
  }
}
</style>
