<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div>
    <base-dialog
      :show="openDialogEdit"
      title="修改貨櫃資訊"
      @close="$emit('handleCloseEdit')"
      @save="saveData"
      @delete="$emit('vanDelete', box.id)"
      :isDelete="true"
    >
      <template #default>
        <div class="addBoxDetail">
          <div class="form-control">
            <label>櫃號</label>
            <input type="text" placeholder="請輸入櫃號" v-model="box.van_no" />
          </div>
          <div class="form-control">
            <label>備註</label>
            <input type="text" placeholder="請輸入備註" v-model="box.note" />
          </div>
          <div style="display:none;" class="form-control-checkbox">
            <input type="checkbox" v-model="isTopping" />
            <label>Topping</label>
          </div>
          <!-- 下面要再換成api變數 -->
          <p>Topping</p>
          <div class="form-control-checkbox">
            <input
              type="checkbox"
              v-model="box.Area_KEL"
              :disabled="box.Enable_KEL === 0"
              @change="checkDefaultArea('TWKEL')"
            />
            <label>基隆</label>
            <el-radio
              label="TWKEL"
              v-model="box.area"
              :disabled="box.Enable_KEL === 0"
              >開櫃地</el-radio>
          </div>
          <div class="form-control-checkbox">
            <input
              type="checkbox"
              v-model="box.Area_TPE"
              :disabled="box.Enable_TPE === 0"
              @change="checkDefaultArea('TWTPE')"
            />
            <label>台北</label>
            <el-radio
              label="TWTPE"
              v-model="box.area"
              :disabled="box.Enable_TPE === 0"
              >開櫃地</el-radio>
          </div>
          <div class="form-control-checkbox">
            <input
              type="checkbox"
              v-model="box.Area_TYN"
              :disabled="box.Enable_TYN === 0"
              @change="checkDefaultArea('TWTYN')"
            />
            <label>桃園</label>
            <el-radio
              label="TWTYN"
              v-model="box.area"
              :disabled="box.Enable_TYN === 0"
              >開櫃地</el-radio>
          </div>
          <div class="form-control-checkbox">
            <input
              type="checkbox"
              v-model="box.Area_TXG"
              :disabled="box.Enable_TXG === 0"
              @change="checkDefaultArea('TWTXG')"
            />
            <label>台中</label>
            <el-radio
              label="TWTXG"
              v-model="box.area"
              :disabled="box.Enable_TXG === 0"
              >開櫃地</el-radio>
          </div>
          <div class="form-control-checkbox">
            <input
              type="checkbox"
              v-model="box.Area_KHH"
              :disabled="box.Enable_KHH === 0"
              @change="checkDefaultArea('TWKHH')"
            />
            <label>高雄</label>
            <el-radio
              label="TWKHH"
              v-model="box.area"
              :disabled="box.Enable_KHH === 0"
              >開櫃地</el-radio>
          </div>

          <!-- <div class="form-control">
            <label>地點</label>
            <select
              :value="box.area"
              @change="setArea($event.target.value)"
              :disabled="true"
            >
              <option value="TWKEL">基隆</option>
              <option value="TWTPE">台北</option>
              <option value="TWTXG">台中</option>
              <option value="TWKHH">高雄</option>
            </select>
          </div> -->
          <!-- <div class="form-control">
            <label>地點</label>
            <el-select v-model="box.area_end" placeholder="請選擇">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div> -->
          <!-- <div class="form-control-checkbox">
            <input type="checkbox">
            <label class="delete">刪除貨櫃</label>
          </div> -->
        </div>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import BaseDialog from '../../ui/BaseDialog.vue'
export default {
  props: ['openDialogEdit', 'data', 'value', 'receiptPlace'],
  components: {
    BaseDialog
  },
  data() {
    return {
      box: {
        van_no: '',
        note: '',
        area: 'TWKHH',
        area_end: '',
        topping: null,
        id: null,
        Area_KEL: null,
        Area_TPE: null,
        Area_TYN: null,
        Area_TXG: null,
        Area_KHH: null,
        Enable_KEL: null,
        Enable_TPE: null,
        Enable_TYN: null,
        Enable_TXG: null,
        Enable_KHH: null
      },
      isTopping: true,
      options: [
        {
          value: 'TWKEL',
          label: '基隆'
        },
        {
          value: 'TWTPE',
          label: '台北'
        },
        {
          value: 'TWTYN',
          label: '桃園'
        },
        {
          value: 'TWTXG',
          label: '台中'
        },
        {
          value: 'TWKHH',
          label: '高雄'
        }
      ]
    }
  },
  methods: {
    saveData() {
      if (this.box.van_no === '') {
        this.$message.error('請輸入貨櫃編號')
        return
      }
      this.$emit('vanEdit', this.box)
    },
    async checkDefaultArea (area = null) {
      await this.$nextTick()
      if (area === null) {
        area = this.box.area
      }
      var areaMap = {
        TWKEL: this.box.Area_KEL,
        TWTPE: this.box.Area_TPE,
        TWTYN: this.box.Area_TYN,
        TWTXG: this.box.Area_TXG,
        TWKHH: this.box.Area_KHH
      }
      var enableMap = {
        TWKEL: this.box.Enable_KEL,
        TWTPE: this.box.Enable_TPE,
        TWTYN: this.box.Enable_TYN,
        TWTXG: this.box.Enable_TXG,
        TWKHH: this.box.Enable_KHH
      }
      if (areaMap[area] === false) {
        var availableArea = []
        for (var key in areaMap) {
          if (areaMap[key] === true && enableMap[key] === 1) {
            availableArea.push(key)
          } else {
            this.box['Area_' + key.substr(2)] = false
          }
        }
        if (availableArea.length > 0) {
          this.box.area = availableArea[0]
        } else {
          this.box['Area_' + area.substr(2)] = true
        }
        this.box.topping = '0'
        if (availableArea.length > 1) {
          this.box.topping = '1'
        }
      }
    },
    defaultBoxArea() {
      console.log('defaultBoxArea')
      var enableMap = {
        TWKEL: this.box.Enable_KEL,
        TWTPE: this.box.Enable_TPE,
        TWTYN: this.box.Enable_TYN,
        TWTXG: this.box.Enable_TXG,
        TWKHH: this.box.Enable_KHH
      }
      for (var key in enableMap) {
        if (enableMap[key] === 1) {
          this.box.area = key
          break
        }
      }
    },
    setArea(e) {
      this.box.area = e
    },
    setToArea(e) {
      this.box.area_end = e
    }
  },
  mounted() {
    this.box.area = this.receiptPlace
  },
  watch: {
    value(val) {
      if (val) {
        console.log(this.data)
        Object.keys(this.box).forEach(item => {
          if (item === 'note') {
            this.box[item] = this.data.name
            return
          }
          if (item === 'van_no') {
            this.box[item] = this.data.number
            return
          }
          this.box[item] = this.data[item]
        })
        this.isTopping = this.box.topping !== 0
      }
    },
    isTopping(val) {
      val = false
      var toppingAllowCount = 0
      if (this.box.Area_KEL) {
        toppingAllowCount++
      }
      if (this.box.Area_TPE) {
        toppingAllowCount++
      }
      if (this.box.Area_TYN) {
        toppingAllowCount++
      }
      if (this.box.Area_TXG) {
        toppingAllowCount++
      }
      if (this.box.Area_KHH) {
        toppingAllowCount++
      }
      if (toppingAllowCount > 1) {
        val = true
      }
      this.box.topping = val ? '1' : '0'
      this.box.area_end = ''
      if (val) {
        this.options = [
          {
            value: 'all',
            label: '全部'
          }
        ]
        this.box.area_end = 'all'
      } else {
        this.options = [
          {
            value: 'TWKEL',
            label: '基隆'
          },
          {
            value: 'TWTPE',
            label: '台北'
          },
          {
            value: 'TWTYN',
            label: '桃園'
          },
          {
            value: 'TWTXG',
            label: '台中'
          },
          {
            value: 'TWKHH',
            label: '高雄'
          }
        ]
        this.options = this.options.filter(e => e.value !== this.box.area)
      }
    },
    receiptPlace(val) {
      this.box.area = val
    },
    'box.area'(val, oldVal) {
      this.options = this.options.filter(e => e.value !== val)
      var areaMap = {
        TWKEL: this.box.Area_KEL,
        TWTPE: this.box.Area_TPE,
        TWTYN: this.box.Area_TYN,
        TWTXG: this.box.Area_TXG,
        TWKHH: this.box.Area_KHH
      }
      if (areaMap[val] === false || areaMap[val] === 0) {
        this.box.area = oldVal
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.addBoxDetail {
  display: grid;
  grid-template-rows: repeat(5, max-content);
  grid-row-gap: 20px;

  & .form-control {
    display: grid;
    grid-template-rows: max-content 38px;
    grid-row-gap: 8px;

    & label {
      font-size: 14px;
      font-weight: 500;
    }

    & input {
      border: 1px solid #e0e1e1;
      border-radius: 4px;
      padding: 8px 16px;
      width: 365px;

      &::placeholder {
        color: #9ea0a2;
      }
    }

    & select {
      border: 1px solid #e0e1e1;
      border-radius: 4px;
      padding: 8px 16px;
      width: 100%;
    }

    &-checkbox {
      display: flex;
      align-items: center;

      & input {
        margin-right: 7px;
      }
      & label {
        margin-right: 10px;
        font-size: 14px;
      }
      // & label.delete {
      //   color: #D04433;
      // }
    }
  }
}
</style>
