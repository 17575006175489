import axios from 'axios'

// 貨物列表
export const queryCfs = async body =>
  await axios.post('/api/cfs/list', { ...body }).then(response => response.data)

// 貨櫃列表
export const queryCfsVanList = async body =>
  await axios
    .post('/api/cfs/van/list', { ...body })
    .then(response => response.data)

export const cfsListAction = async body =>
  await axios
    .post('/api/cfs/action', { ...body })
    .then(response => response.data)

// 貨櫃統計
export const getCfsVanSun = async body =>
  await axios
    .post('/api/cfs/van/sum', { ...body })
    .then(response => response.data)

// get topping allow list
export const getToppingAllowList = async (body) => await axios.post('/api/cfs/topping/list', { ...body }).then((response) => response.data)

export const putCfsVanEditAndAdd = async body =>
  await axios
    .post('/api/cfs/van/action', { ...body })
    .then(response => response.data)

// 船期主檔 list
export const getShipList = async body =>
  await axios
    .post('/api/cfs/vslschedule/list', { ...body })
    .then(response => response.data)

// 船期主檔 新增＆修改＆刪除 action: A U D
export const getShipListUpdate = async body =>
  await axios
    .post('/api/cfs/vslschedule/action', { ...body })
    .then(response => response.data)

// 櫃場CY 船公司CA 選項list
export const getSelectList = async body =>
  await axios
    .post('/api/cfs/customer/list', { ...body })
    .then(response => response.data)

// 卸貨港 選項list
export const getPortDischargeSelectList = async body =>
  await axios
    .post('/api/cfs/address/list', { ...body })
    .then(response => response.data)

// autocomplete list
export const getKeyWordSelect = async body =>
  await axios
    .post('/api/cfs/searchkeyword', { ...body })
    .then(response => response.data)

// 重材 list 還沒有
export const getHeavyMaterialsList = async body =>
  await axios
    .post('/api/cfs/vwlist', { ...body })
    .then(response => response.data)

// 重材 save
export const putHeavyMaterialsSave = async body =>
  await axios
    .post('/api/cfs/vwadd', { ...body })
    .then(response => response.data)

// 裝船
export const getFormList = async body =>
  await axios
    .post('/api/purchase/formlist/SA', { ...body })
    .then(response => response.data)

// cfs single查詢單一貨物
export const getCfsById = async body =>
  await axios
    .post('/api/cfs/single', { ...body })
    .then(response => response.data)

// 拆分
export const splitCfs = async body =>
  await axios
    .post('/api/cfs/split', { ...body })
    .then(response => response.data)

// 配置
export const clCfs = async body =>
  await axios.post('/api/cfs/cl', { ...body }).then(response => response.data)

export const exportCfsPdf = async body =>
  await axios.post('/api/cfs/export/pdf', { ...body }).then(response => response.data)
