<template>
  <div class="box-status">
    <div class="flex">
      <h1>CFS</h1>
    </div>

    <div class="box-types">
      <box-type
        v-for="kind in boxTypeData"
        :key="kind.id"
        :id="kind.id"
        :boxNumbers="kind.boxNumbers"
        :name="kind.name"
        :maxWeight="kind.maxWeight"
        :maxMeasure="kind.maxMeasure"
        :isSelected="kind.isSelected"
        @selectKind="selectKind"
      ></box-type>
    </div>
    <div>
      <box-detail-item
        v-for="boxDetail in boxTypeData"
        :key="boxDetail.id"
        :row="boxDetail"
        :name="boxDetail.name"
        :items="boxDetail.boxNumbers"
        :selected="boxDetail.isSelected"
        :allData="cfsList"
        :receiptPlace="searchCondition.receipt_place"
        :searchCondition="searchCondition"
        @update="$emit('query')"
      ></box-detail-item>
    </div>
  </div>
</template>

<script>
import BoxType from './BoxType.vue'
import BoxDetailItem from './BoxDetailItem.vue'
import { putCfsVanEditAndAdd } from '@/api/cfsApi'
import { getCfsVanTypeList } from '@/api/cfsVanTypeApi'

export default {
  data() {
    return {
      expandKind: '',
      selectedKindId: '',
      boxTypeData: {}
    }
  },
  props: {
    cfsList: {
      type: Array,
      default: () => []
    },
    cfsVanList: {
      type: Array,
      default: () => []
    },
    csfSunList: {
      type: Array,
      default: () => []
    },
    searchCondition: {
      type: Object,
      default: () => {}
    },
    carrier: {
      type: String,
      default: null
    }
  },
  components: {
    BoxType,
    BoxDetailItem
  },
  computed: {
    // boxTypeData() {
    //   var tmpList = {
    //     GP20: {
    //       name: "20' GP",
    //       maxMeasure: 0,
    //       maxWeight: 0,
    //       isSelected: false,
    //       boxNumbers: [],
    //       count: 0
    //     },
    //     GP40: {
    //       name: "40' GP",
    //       maxMeasure: 0,
    //       maxWeight: 0,
    //       isSelected: false,
    //       boxNumbers: [],
    //       count: 0
    //     }
    //   }
    //   this.csfSunList.forEach(item => {
    //     var tmpRow = tmpList['GP' + item.Van_Type]
    //     if (['20', '40'].indexOf(item.Van_Type) > -1) {
    //       tmpRow.maxMeasure += parseInt(item.Total_KGS)
    //       tmpRow.maxWeight += parseFloat(item.Total_CBM)
    //       tmpRow.count += 1

    //       var checkBox = tmpRow.boxNumbers.filter(box => {
    //         return box.number === item.Van_No
    //       })
    //       if (checkBox.length === 0) {
    //         tmpRow.boxNumbers.push({
    //           name: item.Note,
    //           number: item.Van_No,
    //           weight: parseFloat(item.Total_CBM),
    //           measure: parseInt(item.Total_KGS),
    //           packageCount: parseInt(item.Total_Package),
    //           id: item.Id || null,
    //           area: item.Area,
    //           area_end: item.Area_End,
    //           topping: item.Topping
    //         })
    //       } else {
    //         checkBox[0].weight += parseInt(item.Total_CBM)
    //         checkBox[0].measure += parseInt(item.Total_KGS)
    //         checkBox[0].packageCount += parseInt(item.Total_Package)
    //       }
    //     }
    //   })
    //   if (this.expandKind === '20') {
    //     tmpList.GP20.isSelected = true
    //   }
    //   if (this.expandKind === '40') {
    //     tmpList.GP40.isSelected = true
    //   }
    //   return tmpList
    // },

    boxDetailList() {
      var tmpList = {
        GP20: {
          searchResultId: 'c1',
          name: "20' GP",
          selected: false,
          items: [
            {
              id: '201',
              name: '201 中轉櫃',
              number: '201',
              weight: {
                all: 100,
                kg: 40
              },
              volume: {
                all: 100,
                cbm: 100
              },
              releasedPiece: 5,
              topping: 'abcd中國'
            }
          ]
        },
        GP40: {
          name: "40' GP",
          maxMeasure: 0,
          maxWeight: 0,
          isSelected: false,
          boxNumbers: []
        }
      }
      this.cfsList.forEach(item => {
        var tmpRow = tmpList['GP' + item.van_type]
        if (['20', '40'].indexOf(item.van_type) > -1) {
          tmpRow.maxMeasure += parseInt(item.cargo_measure)
          tmpRow.maxWeight += parseFloat(item.cargo_gross_wt)
          // check if van_no not in boxNumbers
          var checkitems = tmpRow.items.filter(
            box => box.number === item.van_no
          )
          if (checkitems.length === 0) {
            tmpRow.items.push({
              id: item.van_no,
              name: item.van_no + ' ' + item.van_code,
              number: item.van_no,
              weight: {
                all: 100,
                kg: 40
              },
              volume: {
                all: 100,
                cbm: 100
              },
              releasedPiece: 5,
              topping: 'abcd中國'
            })
          } else {
            checkitems[0].weight += parseInt(item.cargo_gross_wt)
            checkitems[0].percent += parseInt(item.cargo_gross_wt)
          }
          // tmp20Row.boxNumbers.forEach(box => {
          //   // 如果有就加入他的重量
          //   if (box.number === item.van_no) {
          //     box.percent = parseInt(item.cargo_gross_wt)
          //   }
          // })
        }
      })
      if (this.expandKind === '20') {
        tmpList.GP20.isSelected = true
      }
      if (this.expandKind === '40') {
        tmpList.GP40.isSelected = true
      }
      return tmpList
    },
    boxType() {
      // 從 c2, c2 之中挑出 c1
      const searchResultId = this.$store.getters.searchResultId
      const boxTypes = this.$store.getters.boxTypes
      return boxTypes.find(type => type.id === searchResultId)
    }
  },
  methods: {
    selectKind(id) {
      // this.expandKind = id
      this.boxTypeData.forEach(item => {
        if (item.id === id) {
          return (item.isSelected = !item.isSelected)
        }
        // item.isSelected = false
      })
    },
    async fetchAddNew(e) {
      console.log(this.searchCondition)
      const res = await putCfsVanEditAndAdd({
        action: 'A',
        edi_type: 'A',
        ...this.searchCondition,
        ...e
      })

      if (res.code === '200') {
        this.$message({
          type: 'info',
          message: '新增成功'
        })
        this.$emit('query')
        this.handleClose()
        return
      }
      this.$message({
        type: 'info',
        message: '新增失敗'
      })
    },
    async fetchVanType() {
      const { data: res, code } = await getCfsVanTypeList()
      if (code === '200') {
        this.boxTypeData = res.ListData.map(item => {
          return {
            id: item.id,
            van_type: item.van_type,
            name:
              item.van_type === '20' || item.van_type === '40'
                ? `${item.van_type}' GP`
                : item.van_type === '40hq'
                ? "40' HQ"
                : '其他類型',
            measure: Number(item.van_cbm),
            maxMeasure: Number(item.van_cbm),
            weight: Number(item.van_kgs),
            maxWeight: Number(item.van_kgs),
            isSelected: false,
            boxNumbers: [],
            count: 0,
            Area_KEL: item.Area_KEL,
            Area_TPE: item.Area_TPE,
            Area_TYN: item.Area_TYN,
            Area_TXG: item.Area_TXG,
            Area_KHH: item.Area_KHH,
            Enable_KEL: item.Enable_KEL,
            Enable_TPE: item.Enable_TPE,
            Enable_TYN: item.Enable_TYN,
            Enable_TXG: item.Enable_TXG,
            Enable_KHH: item.Enable_KHH
          }
        })
      }
    }
  },
  watch: {
    csfSunList(val) {
      this.boxTypeData.forEach(item => {
        item.boxNumbers = []
      })
      val.forEach(sun => {
        this.boxTypeData.forEach(item => {
          if (sun.Van_Type === item.van_type) {
            item.boxNumbers = [
              ...item.boxNumbers,
              {
                name: sun.Note,
                number: sun.Van_No,
                weight: parseFloat(sun.Total_KGS) || 0,
                measure: parseFloat(sun.Total_CBM) || 0,
                packageCount: parseInt(sun.Total_Package),
                id: sun.Id || null,
                area: sun.Area,
                area_end: sun.Area_End,
                topping: sun.Topping,
                Area_KEL: sun.Area_KEL,
                Area_TPE: sun.Area_TPE,
                Area_TYN: sun.Area_TYN,
                Area_TXG: sun.Area_TXG,
                Area_KHH: sun.Area_KHH,
                Enable_KEL: sun.Enable_KEL,
                Enable_TPE: sun.Enable_TPE,
                Enable_TYN: sun.Enable_TYN,
                Enable_TXG: sun.Enable_TXG,
                Enable_KHH: sun.Enable_KHH
              }
            ]
            item.count += 1
            // item.maxMeasure += item.measure
            // item.maxWeight += item.weight
          }
        })
      })
    }
  },
  async mounted() {
    this.fetchVanType()
  }
}
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  justify-content: space-between;

  .btn {
    margin-right: 24px;
    background-color: #4ba676;
    border: none;
    border-radius: 2px;
    color: #ffffff;
    padding: 5px 20px;
    display: flex;
    align-items: center;
  }
}
.box-status {
  display: grid;
  grid-template-rows: auto auto;
  background-color: #f5f5f5;
  padding: 12px 0;

  & h1 {
    margin-left: 24px;
    margin-bottom: 10px;
  }
}

.box-types {
  width: 100vw;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
