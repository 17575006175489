<template>
  <div>
    <section class="save-btn">
      <el-button
        size="small"
        :disabled="!enableSaveButton"
        @click="handleSaveButtonClick"
      >
        儲存
      </el-button>
    </section>
    <section class="search-counter-wrapper">
      <search-bar
        ref="searchBar"
        :condition="searchCondition"
        @clear="handleClear"
        @query="handleSearch"
        :showAllOption="false"
        @update="updateSearchCondition"
      ></search-bar>
    </section>

    <section class="bg-box">
      <p>貨物資料</p>
      <div class="table">
        <el-table
          v-if="tableData.length > 0"
          :data="tableData"
          height="calc(60vh - 18px)"
          border
          style="width: 100%;color: #333"
        >
          <el-table-column
            v-for="(data, index) in column"
            :key="index"
            :prop="data.field"
            :label="data.label"
            :min-width="data.width || 'auto'"
            :sortable="data.field !== 'action'"
          >
            <template slot-scope="scope">
              <template v-if="data.field === 'action'">
                <div
                  class="flex"
                  v-if="
                    !scope.row.van_no_split || scope.row.van_no_split === ''
                  "
                >
                  <!-- <el-button
                    @click="handleDelete(scope.row, scope.$index)"
                    type="text"
                    icon="el-icon-delete"
                  ></el-button> -->
                </div>
                <div v-else class="flex">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="該貨物已經拆分以致無法修改，若仍需修改請先至配櫃刪除拆分。"
                    placement="top-start"
                  >
                    <div style="color: red">*註</div>
                  </el-tooltip>
                </div>
              </template>
              <template v-else>
                <template
                  v-if="
                    !scope.row.van_no_split || scope.row.van_no_split === ''
                  "
                >
                  <template v-if="data.type === 'input'">
                    <el-input
                      v-model="scope.row[data.field]"
                      type="textarea"
                      autosize
                      @focus="clearInputIfZeroOnFocus(scope.row, data.field)"
                    ></el-input>
                  </template>
                  <template v-else-if="data.field === 'customer_code_name'">
                    <div class="margin">{{ scope.row[data.field].substring(0,11) }}</div>
                  </template>
                  <template v-else-if="data.type === 'onlyView'">
                    <div class="margin">{{ scope.row[data.field] }}</div>
                  </template>
                  <template v-else-if="data.field === 'package_unit'">
                    <el-select
                      ref="packageUnitSelect"
                      v-model="scope.row[data.field]"
                      @keydown.tab.native.capture="() => handlePackageUnitSelectKeydown(scope)"
                      @keydown.enter.native.capture="() => handlePackageUnitSelectKeydown(scope)"
                      filterable
                    >
                      <el-option
                        v-for="item in data.options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </template>
                  <template v-else-if="data.field === 'coload_agent'">
                    <el-select
                      v-model="scope.row[data.field]"
                      filterable
                      :filter-method="(query) => filterColoader(query, data.options)"
                      :disabled="!scope.row['cl_out']"
                    >
                      <el-option
                        v-for="item in coloaderOption"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </template>
                  <template v-else-if="data.type === 'select'">
                    <el-select
                      v-model="scope.row[data.field]"
                      @focus="onCustomerCodeFocus"
                      remote
                      filterable
                      :remote-method="filterCustomerCode"
                    >
                      <el-option
                        v-for="item in data.options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </template>
                  <template v-else-if="data.field === 'cl_out'">
                    <el-checkbox
                      v-model="scope.row[data.field]"
                      @change="() => scope.row['coload_agent'] = ''"
                    ></el-checkbox>
                  </template>
                  <template v-else>
                    <el-checkbox v-model="scope.row[data.field]"></el-checkbox>
                  </template>
                </template>
                <template v-else>
                  <template v-if="data.type === 'checkbox'">
                    <i
                      class="el-icon-minus"
                      v-if="scope.row[data.field] === 0"
                    ></i>
                    <i class="el-icon-check" v-else></i>
                  </template>
                  <div v-else class="margin">{{ scope.row[data.field] }}</div>
                </template>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <div class="no-data-info" v-else>
          請搜尋相關內容，以帶出船期資料
        </div>
      </div>
    </section>
    <HeavyMaterialsFilterIdDialog
      :value="showDialog"
      :dataList="shipListData"
      :total="total"
      @close="closeDialog"
      @fetchListById="handleHeavyMaterialsFilterIdDialogConfirm"
      @handlePage="handleCurrentChange"
    />
    <over-capacity-notice-box
      :openDialogOver="openDialogOver"
      @handleClose="openDialogOver = false"
      @confirm="handleOverCapacityNoticeBox"
    ></over-capacity-notice-box>
  </div>
</template>

<script>
import SearchBar from './section/search/SearchBar.vue'
import {
  getSelectList,
  getShipList,
  putHeavyMaterialsSave,
  getHeavyMaterialsList
} from '@/api/cfsApi'
import HeavyMaterialsFilterIdDialog from './section/dialog/heavyMaterialsFilterIdDialog'
import OverCapacityNoticeBox from './components/modal/heavyMaterials/OverCapacityNoticeBox.vue'

export default {
  components: {
    SearchBar,
    HeavyMaterialsFilterIdDialog,
    OverCapacityNoticeBox
  },
  data() {
    return {
      showDialog: false,
      searchCondition: {
        reg_ship: '',
        vsl_name: '',
        voyage: '',
        port_load: '',
        carrier: '',
        limit: 20,
        offset: 0
      },
      total: 0,
      column: [
        {
          field: 'so_no',
          label: 'S/O',
          type: 'input'
        },
        {
          field: 'package_no',
          label: '放行件數',
          type: 'input'
        },
        {
          field: 'package_unit',
          label: '件數單位',
          type: 'select',
          options: []
        },
        {
          field: 'cargo_gross_wt',
          label: 'KGS',
          type: 'input'
        },
        {
          field: 'cargo_measure',
          label: 'CBM',
          type: 'input'
        },
        {
          field: 'receipt_place',
          label: '收貨地',
          type: 'onlyView'
        },
        {
          field: 'port_load',
          label: '裝貨港',
          type: 'onlyView'
        },
        {
          field: 'port_discharge',
          label: '卸貨港',
          type: 'onlyView'
        },
        {
          field: 'customer_code_name',
          label: '客戶',
          type: 'onlyView'
        },
        {
          field: 'sales_code_name',
          label: 'SALES',
          type: 'onlyView'
        },
        {
          field: 'cl_out',
          label: 'Cl out',
          type: 'checkbox'
        },
        {
          field: 'coload_agent',
          label: 'Coloader',
          type: 'select',
          options: []
        },
        {
          field: 'note',
          label: '配櫃備註',
          type: 'input'
        },
        {
          field: 'action',
          label: '',
          width: '30px',
          type: 'input'
        }
      ],
      tableData: [],
      shipListData: {
        title: '船期主檔',
        list: []
      },
      beDelete: [],
      customersLC: [],
      inputIsValid: true,
      openDialogOver: false,
      enableSaveButton: true,
      coloaderOption: [],
      onOverCapacityCallBack: undefined
    }
  },
  computed: {
    params() {
      return this.$route.params
    },
    paramsId() {
      return this.shipListData.id || this.$route.params.id || null
    },
    packageTypeSelect() {
      return this.$store.getters.getPackageTypeSelect || []
    },
    isCfsSearchBarConditionEmpty() {
      return this.$store.getters.isCfsSearchBarConditionEmpty
    }
  },
  methods: {
    closeDialog() {
      this.showDialog = false
    },
    handleCurrentChange(val) {
      this.searchCondition.offset = (val - 1) * 20
      this.fetchList()
    },
    handleDelete(val, index) {
      // 先做刪掉整條 新增的沒有id 先改用index
      if (val.id) this.beDelete.push(val.id)

      this.tableData = this.tableData.filter((item, idx) => {
        return idx !== index
      })
    },
    handleClear() {
      delete this.shipListData.id
      this.tableData = []
    },
    handleSearch() {
      this.handleClear()
      this.fetchList()
    },
    async fetchList() {
      const res = await getShipList({ ...this.searchCondition, dist: 1 })
      if (res.code === '200') {
        this.shipListData.list = res.data.ListData
        this.total = res.data.PageData.count
        if (res.data.ListData.length === 1) {
          this.$set(this.shipListData, 'id', res.data.ListData[0].id)
          return this.fetchListByCompanyName()
        }
        this.showDialog = true
      }
    },
    async fetchListByCompanyName() {
      const found =
        this.shipListData.list.length > 1
          ? this.shipListData.list.find(e => e.id === this.shipListData.id)
          : this.shipListData.list[0]

      if (found) {
        Object.keys(this.searchCondition).forEach(item => {
          return (this.searchCondition[item] = found[item])
        })
      }

      const res = await getHeavyMaterialsList({
        ...this.searchCondition
      })
      if (res.code === '200') {
        this.tableData = res.data.ListData
        this.tableData.map(item => {
          item.coload_agent = item.coload_agent === 'NIL' ? '' : item.coload_agent
          item.cl_in = 0
          item.cl_out = 0
        })
        this.tableData.forEach(item => {
          if (parseFloat(item.package_no) === 0) item.package_no = ''
          if (parseFloat(item.cargo_gross_wt) === 0) item.cargo_gross_wt = ''
          if (parseFloat(item.cargo_measure) === 0) item.cargo_measure = ''
        })
      }

      if (this.tableData.length === 0) return
      // for (let i = 0; i < 20; i++) {
      //   this.tableDataa = [
      //     ...this.tableData,
      //     {
      //       so_no: '',
      //       package_no: '',
      //       measure_unit: '',
      //       cargo_gross_wt: '',
      //       cargo_measure: '',
      //       customer_code: '',
      //       sales_code: '',
      //       coload_agent: '',
      //       note: '',
      //       ...this.searchCondition
      //     }
      //   ]
      // }
      this.fetchCustomersLC()
    },
    handleCheck() {
      if (this.tableData.length <= 0) return
      for (let i = 0; i < this.tableData.length; i++) {
        const _data = this.tableData[i]
        if (_data.package_no === '') _data.package_no = '0'
        if (_data.package_unit === '') _data.package_unit = 'PLT'
        if (_data.cargo_gross_wt === '') _data.cargo_gross_wt = '0'
        if (_data.cargo_measure === '') _data.cargo_measure = '0'
      }

      const data = this.tableData.filter(item => {
        return item.so_no !== '' && item.package_no !== ''
      })

      data.forEach(item => {
        item.cl_out = item.cl_out === true ? '1' : '0'
      })

      if (data.length === 0) {
        return this.$message({
          message: '資料不完整，無法儲存',
          type: 'error'
        })
      }
      // const rule = data.some(item => {
      //   item.cl_in = item.cl_in === true ? '1' : '0'
      //   item.cl_out = item.cl_out === true ? '1' : '0'
      //   return (
      //     (item.cl_in !== '0' || item.cl_out !== '0') &&
      //     (item.coload_agent === '' || item.coload_agent === null)
      //   )
      // })
      // if (rule) {
      //   return this.$message({
      //     message: 'Coloader 必填',
      //     type: 'error'
      //   })
      // }

      let rules
      rules = this.handleRule(data, true, 'package_no', '放行件數須為整數')
      if (!rules) return
      rules = this.handleRule(
        data,
        false,
        'cargo_gross_wt',
        'KGS輸入最多至小數點後三位',
        3
      )
      if (!rules) return
      rules = this.handleRule(
        data,
        false,
        'cargo_measure',
        'CBM輸入最多至小數點後四位',
        4
      )
      if (!rules) return

      return this.fetchSaveHeavyMaterials(data)
    },
    handleRule(data, isInt, name, msg, decimal) {
      const rule = data.some(item => {
        const _temp = item[name].split('.')[1] ?? ''
        const length = _temp.toString().length
        if (isInt) {
          return length > 0
        }
        return _temp.length > decimal
      })
      if (rule) {
        this.$message({
          message: msg,
          type: 'error'
        })
        return false
      }
      return true
    },
    async fetchSaveHeavyMaterials(data, acceptOver = 0) {
      const res = await putHeavyMaterialsSave({
        data,
        del_list: this.beDelete,
        current_page: 'cbm',
        accept_over: acceptOver !== 1 ? 0 : 1
      })

      if (res.code === '200') {
        this.$message({
          message: '修改成功',
          type: 'success'
        })

        return this.handleGoVanPage()
      }

      if (res.detail.includes('已超過貨櫃最大')) {
        this.onOverCapacityCallBack = () => {
          this.fetchSaveHeavyMaterials(data, 1)
        }
        this.openDialogOver = true
        return
      }

      this.$message({
        message: res.detail,
        type: 'error'
      })
    },
    handleOverCapacityNoticeBox() {
      if (this.onOverCapacityCallBack !== undefined) {
        this.onOverCapacityCallBack()
      }
    },
    handleGoVanPage() {
      this.$confirm('是否直接切換至 配櫃 功能？', '提示！', {
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        callback: action => {
          if (action !== 'confirm') return this.fetchList()
          this.$router.push({
            name: 'cabinet',
            params: {
              id: this.paramsId,
              ...this.searchCondition
            }
          })
        }
      })
    },
    async fetchSelectList(val, name) {
      const res = await getSelectList({ basic_type: val })
      if (res.code === '200') {
        this.column.forEach(item => {
          if (item.field === name) {
            item.options = res.data.ListData.map(item => {
              return {
                label: item.Local_Name,
                value: item.Basic_Code
              }
            })
          }
        })
      }
    },
    async fetchCustomersLC() {
      const res = await getSelectList({ basic_type: 'LC' })
      if (res.code === '200') {
        this.customersLC = res.data.ListData

        await this.filterCustomerCode()
      }
    },
    async filterCustomerCode(query) {
      this.column.forEach(item => {
        if (item.field === 'customer_code') {
          if (this.customersLC !== null) {
            let filter1 = () => {
              return true
            }
            if (query != null) {
              filter1 = e =>
                e.Basic_Code.includes(query) ||
                e.English_Name.includes(query) ||
                e.Local_Name.includes(query)
            }
            const pagedList = this.customersLC.filter(filter1).splice(0, 100)
            item.options = pagedList.map(item => {
              return {
                label: item.Local_Name,
                value: item.Basic_Code
              }
            })
            item.options = item.options
              .concat(
                this.tableData.map(e => {
                  return {
                    label: e.customer_code_name,
                    value: e.customer_code
                  }
                })
              )
              .filter(
                (v, i, a) => a.findIndex(v2 => v2.value === v.value) === i
              )
          }
        }
      })
    },
    onCustomerCodeFocus() {
      this.filterCustomerCode()
    },
    updateSearchCondition() {
      this.searchCondition.voyage = ''
      this.searchCondition.carrier = ''
    },
    async handleHeavyMaterialsFilterIdDialogConfirm() {
      await Promise.all([
        this.fetchListByCompanyName(),
        this.$refs.searchBar.fetchVoyageAndCarrierOptionsByVslName()
      ])
    },
    getPackageUnitIndexOnKeydown(index) {
      const ref = this.$refs.packageUnitSelect[index]
      const findIndex = this.packageTypeSelect.findIndex(({ value }) => value === ref.query)
      return findIndex
    },
    handlePackageUnitSelectKeydown(scope) {
      const index = this.getPackageUnitIndexOnKeydown(scope.$index)
      if (index !== -1) {
        scope.row.package_unit = this.packageTypeSelect[index].value
      }
    },
    async handleSaveButtonClick() {
      if (!this.enableSaveButton) return
      this.enableSaveButton = false
      await this.handleCheck()
      this.enableSaveButton = true
    },
    filterColoader(query, options) {
      if (query === '' || query === null) {
        this.coloaderOption = []
        return
      }
      this.coloaderOption = options
        .filter(x => x.label.includes(query))
        .map(x => ({
          label: x.label.substring(0, 11),
          value: x.value
        }))
    },
    clearInputIfZeroOnFocus(row, field) {
      const isZero = row[field].split('.').every(x => parseInt(x) === 0)
      if (isZero) {
        row[field] = ''
      }
    }
  },
  mounted() {
    if (this.paramsId) {
      this.searchCondition.reg_ship = this.params.reg_ship
      this.searchCondition.vsl_name = this.params.vsl_name
      this.searchCondition.voyage = this.params.voyage
      this.searchCondition.port_load = this.params.port_load
      this.searchCondition.carrier = this.params.carrier
      this.fetchListByCompanyName()
    } else {
      const cfsSearchBarCondition = this.$store.getters.cfsSearchBarCondition
      this.searchCondition.reg_ship = cfsSearchBarCondition.reg_ship
      this.searchCondition.vsl_name = cfsSearchBarCondition.vsl_name
      this.searchCondition.voyage = cfsSearchBarCondition.voyage
      this.searchCondition.receipt_place = cfsSearchBarCondition.receipt_place
      this.searchCondition.carrier = cfsSearchBarCondition.carrier
      this.searchCondition.port_load = cfsSearchBarCondition.port_load
      if (!this.isCfsSearchBarConditionEmpty) this.fetchListByCompanyName()
    }
    this.column[2].options = this.packageTypeSelect
    // this.fetchSelectList('SA', 'sales_code')
    this.fetchSelectList('FW', 'coload_agent')
    this.fetchCustomersLC()
  },
  watch: {
    searchCondition: {
      handler(val) {
        this.$store.commit('setCfsSearchBarCondition', val)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.save-btn {
  position: relative;

  button {
    position: absolute;
    top: -46px;
    right: 24px;
    background: #4ba676;
    color: #fff;
    padding: 8px 24px;
  }

  button[disabled]{
    background-color: rgb(172, 169, 169);

    &:hover {
      cursor:not-allowed;
    }
  }
}
.search-counter-wrapper {
  width: 50%;
}
.bg-box {
  background: #f7f7f9;
  padding: 24px;
  box-sizing: border-box;
  max-width: 100vw;
  .table {
    margin-top: 16px;
    padding: 16px;
    background: #fff;
    border-radius: 8px;
    min-height: 60vh;
    display: flex;
    justify-content: center;
    max-width: calc(100vw - 48px);
    ::v-deep .el-textarea__inner {
      border: 0;
      background: transparent;
      font-family: 'PingFang TC';
    }
  }

  .no-data-info {
    color: #87898c;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    font-size: 0.875rem;
  }
}
.margin {
  margin-left: 15px;
}
</style>
