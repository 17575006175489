export default {
  methods: {
    limitSingleLetter(val) {
      return val.toString()[0]?.replace(/[^a-zA-Z]/g, '') || ''
    },
    limitDecimalPlaces(val, place) {
      const regex = new RegExp(`^\\d*(\\.\\d{0,${place}})?`)

      return regex.exec(val.toString())[0] || ''
    },
    limitInteger(val) {
      val = val.toString().replace('.', '')
      return parseInt(val) || ''
    }
  }
}
