
// import { deliveryMasterList, deliveryMasterDelete, deliveryDetailList, deliveryMasterCreate } from '@/api/delivery'
// eslint-disable-next-line
import { deliveryMasterList, deliveryMasterDelete, deliveryMasterCreate, deliveryMasterSingle, deliveryPurchaseNo } from '@/api/delivery'
// eslint-disable-next-line
import { purchaseSingle, purchaseList, purchaseCreate, purchaseDelete, purchaseUpdate } from '@/api/purchase'
// import { decisionUploadlog } from '@/api/decision'
// import { ipfsAppGetpdf } from '@/api/ipfsApp'
// import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
const delivery = {
  state: {
    purchaseSearchInputList: [{
      label: 'Job No',
      input: '',
      type: 'input',
      key: 'pre_book_no'
    }, {
      label: '船名Vessel',
      input: '',
      type: 'input',
      key: 'vsl_name'
    }, {
      label: '航次Voyage',
      input: '',
      type: 'input',
      key: 'voyage'
    }, {
      label: 'SO No',
      input: '',
      type: 'input',
      key: 'so_no'
    }, {
      label: 'HB/L No',
      input: '',
      type: 'input',
      key: 'o_bl_no'
    }, {
      label: '客戶名稱',
      input: '',
      type: 'input',
      key: 'customer_code'
    }, {
      label: '裝貨港POL',
      input: 'POL',
      key: 'port_load',
      msg: ''
    }, {
      label: '日期類型',
      input: 'closing_date',
      type: 'filter',
      key: 'date_filter',
      options: [{ name: '結關日', code: 'closing_date' }, { name: '裝船日', code: 'onboard_date' }]
    }, {
      label: '結關日開始日期',
      input: '',
      type: 'date',
      key: 'closing_date_start',
      hidden: false
    }, {
      label: '結關日結束日期',
      input: '',
      type: 'date',
      key: 'closing_date_end',
      hidden: false
    }, {
      label: '裝船日開始日期',
      input: '',
      type: 'date',
      key: 'onboard_date_start',
      hidden: true
    }, {
      label: '裝船日結束日期',
      input: '',
      type: 'date',
      key: 'onboard_date_end',
      hidden: true
    }, {
      label: '備註',
      input: '',
      type: 'input',
      key: 'reference_no'
    }, {
      label: '收貨港PLR',
      input: 'PLR',
      key: 'receipt_place',
      msg: ''
    }, {
      label: '卸貨港POD',
      input: 'POD',
      key: 'port_discharge',
      msg: ''
    }],
    purchaseAddInputlist: [{
      label: 'Job No',
      input: 'JobNo31',
      key: 'pre_book_no',
      msg: ''
    }, {
      label: '船名Vessel',
      input: 'vslName',
      key: 'vsl_name',
      msg: ''
    }, {
      label: '航次Voyage',
      input: 'Voyage',
      key: 'voyage',
      msg: ''
    }, {
      label: 'SO No',
      input: 'soNo',
      key: 'so_no',
      msg: ''
    }, {
      label: 'HB/L No',
      input: 'soNo',
      key: 'o_bl_no',
      msg: ''
    }, {
      label: '客戶名稱',
      input: 'customerCode',
      key: 'customer_code',
      msg: ''
    }, {
      label: '結關日期',
      input: 'closingDate',
      key: 'closing_date',
      msg: ''
    }, {
      label: '裝船日期',
      input: 'onboardDate',
      key: 'onboard_date',
      msg: ''
    }, {
      label: '裝貨港POL',
      input: 'PLR',
      key: 'port_load',
      msg: ''
    }, {
      label: '卸貨港POD',
      input: 'POD',
      key: 'port_discharge',
      msg: ''
    }, {
      label: 'POL',
      input: 'POL',
      key: 'port_load',
      msg: ''
    }, {
      label: 'PLD',
      input: 'PLD',
      key: 'delivery_place',
      msg: ''
    }, {
      label: 'VoyID',
      input: 'VoyID',
      key: 'ship_num',
      msg: ''
    }, {
      label: 'salesCode',
      input: 'salesCode',
      key: 'sales_code',
      msg: ''
    }, {
      label: 'entryUserCode',
      input: 'test',
      key: 'entry_user_code',
      msg: ''
    }, {
      label: 'cfsDeliveryPlace',
      input: 'cfsDeliveryPlace',
      key: 'cfs_delivery_place',
      msg: ''
    }, {
      label: 'brokerCode',
      input: 'brokerCode',
      key: 'broker_code',
      msg: ''
    }, {
      label: 'truckCompany',
      input: 'truckCompany',
      key: 'truck_company',
      msg: ''
    }, {
      label: 'carrier',
      input: 'carrier',
      key: 'carrier',
      msg: ''
    }, {
      label: 'selfPodAgent',
      input: 'selfPodAgent',
      key: 'self_pod_agent',
      msg: ''
    }, {
      label: 'ediType',
      input: 'aa',
      key: 'edi_type',
      msg: ''
    }, {
      label: 'transferBy',
      input: 'transferBy',
      key: 'transfer_by',
      msg: ''
    }, {
      label: 'transfer_dt',
      input: '',
      key: 'transferDt',
      msg: ''
    }],
    purchaseTableLabel: [{
      label: 'Job No',
      minWidth: 130,
      fixed: 'left',
      link: true,
      prop: 'pre_book_no',
      node: 'edit',
      sortable: 'custom',
      url: 'shipment'
    }, {
      label: '船名Vessel',
      minWidth: 200,
      sortable: 'custom',
      prop: 'vsl_name'
    }, {
      label: '航次Voyage',
      minWidth: 130,
      sortable: 'custom',
      prop: 'voyage'
    }, {
      label: 'SO No',
      sortable: 'custom',
      prop: 'so_no',
      minWidth: 90
    }, {
      label: 'HB/L No',
      sortable: 'custom',
      minWidth: 120,
      prop: 'o_bl_no'
    }, {
      label: '客戶中文名稱',
      minWidth: 200,
      sortable: 'custom',
      prop: 'customer_code'
    }, {
      label: '備註',
      sortable: 'custom',
      editable: true,
      expand: false,
      minWidth: 140,
      prop: 'reference_no'
    }, {
      label: '結關日期',
      minWidth: 120,
      sortable: 'custom',
      prop: 'closing_date'
    }, {
      label: '裝船日期',
      minWidth: 120,
      sortable: 'custom',
      prop: 'onboard_date'
    }, {
      label: '卸貨港POD',
      minWidth: 150,
      sortable: 'custom',
      prop: 'port_discharge'
    }, {
      label: '裝貨港POL',
      minWidth: 150,
      prop: 'port_load',
      sortable: 'custom'
    }],
    // deliveryDetailTableLabel: [{
    //   type: 'selection',
    //   minWidth: 50,
    //   fixed: 'left'
    // }, {
    //   label: 'pre_book_no',
    //   prop: 'pre_book_no',
    //   minWidth: 50,
    //   fixed: 'left'
    // }, {
    //   label: 'sequence_no',
    //   prop: 'sequence_no',
    //   minWidth: 50
    // }, {
    //   label: 'charge_code',
    //   prop: 'charge_code',
    //   minWidth: 50
    // }, {
    //   label: 'charge_name',
    //   prop: 'charge_name',
    //   minWidth: 50
    // }, {
    //   label: 'org_currency',
    //   prop: 'org_currency',
    //   minWidth: 50
    // }, {
    //   label: 'chg_rate',
    //   prop: 'chg_rate',
    //   minWidth: 50
    // }, {
    //   label: 'chg_qty',
    //   prop: 'chg_qty',
    //   minWidth: 50
    // }, {
    //   label: 'chg_unit',
    //   prop: 'chg_unit',
    //   minWidth: 50
    // }, {
    //   label: 'ttl_org_amount',
    //   prop: 'ttl_org_amount',
    //   minWidth: 50
    // }, {
    //   label: 'tax_rate',
    //   prop: 'tax_rate',
    //   minWidth: 50
    // }, {
    //   label: 'ttl_debit_amount',
    //   prop: 'ttl_debit_amount',
    //   minWidth: 50
    // }, {
    //   label: 'tax_type',
    //   prop: 'tax_type',
    //   minWidth: 50
    // }, {
    //   label: 'edi_type',
    //   prop: 'edi_type',
    //   minWidth: 50
    // }, {
    //   label: 'transfer_dt',
    //   prop: 'transfer_dt',
    //   minWidth: 50
    // }, {
    //   label: 'Edit',
    //   prop: 'Edit',
    //   icon: true,
    //   minWidth: 20,
    //   node: 'Edit',
    //   key: 'pre_book_no'
    // }, {
    //   label: 'View',
    //   prop: 'View',
    //   icon: true,
    //   minWidth: 20,
    //   node: 'View',
    //   key: 'pre_book_no'
    // }],
    // deliveryDetailTableLabel: [{
    //   type: 'selection',
    //   minWidth: 50,
    //   fixed: 'left'
    // }, {
    //   label: 'version',
    //   prop: 'version',
    //   minWidth: 50
    // }, {
    //   label: 'update_time',
    //   prop: 'update_time',
    //   minWidth: 50
    // }, {
    //   label: 'uploader',
    //   prop: 'uploader',
    //   minWidth: 50
    // }, {
    //   label: 'status',
    //   prop: 'status',
    //   minWidth: 50,
    //   fixed: 'left'
    // }, {
    //   label: 'Edit',
    //   prop: 'Edit',
    //   icon: true,
    //   minWidth: 20,
    //   node: 'Edit',
    //   key: 'id'
    // }, {
    //   label: 'View',
    //   prop: 'View',
    //   icon: true,
    //   minWidth: 20,
    //   node: 'View',
    //   key: 'id'
    // }],
    // orderDataInputList: [{
    //   type: 'selection',
    //   minWidth: 50,
    //   fixed: 'left'
    // }, {
    //   label: 'version',
    //   input: 'carrier',
    //   key: 'carrier',
    //   msg: ''
    // }, {
    //   label: 'carrier',
    //   input: 'carrier',
    //   key: 'carrier',
    //   msg: ''
    // }, {
    //   label: 'carrier',
    //   input: 'carrier',
    //   key: 'carrier',
    //   msg: ''
    // }, {
    //   label: 'carrier',
    //   input: 'carrier',
    //   key: 'carrier',
    //   msg: ''
    // }, {
    //   label: 'carrier',
    //   input: 'carrier',
    //   key: 'carrier',
    //   msg: ''
    // }, {
    //   label: 'carrier',
    //   input: 'carrier',
    //   key: 'carrier',
    //   msg: ''
    // }],
    // deliveryDetailTableData: [],
    // deliveryDetailData: {
    //   purchaseSingle: {},
    //   detailList: {}
    // },
    purchaseOrderid: '',
    purchaseOrderBy: ''
  },

  getters: {
    purchaseSearchInputList: (state) => state.purchaseSearchInputList,
    purchaseAddInputlist: (state) => state.purchaseAddInputlist,
    // deliveryDetailData: (state) => state.deliveryDetailData,
    // deliveryDetailTableLabel: (state) => state.deliveryDetailTableLabel,
    // deliveryDetailTableData: (state) => state.deliveryDetailTableData,
    purchaseTableLabel: (state) => state.purchaseTableLabel,
    purchaseOrderid: (state) => state.purchaseOrderid,
    purchaseOrderBy: (state) => state.purchaseOrderBy
  },

  mutations: {
    purchaseSearchInputList: (state, payload) => { state.purchaseSearchInputList = payload },
    purchaseAddInputlist: (state, payload) => { state.purchaseAddInputlist = payload },
    // deliveryDetail: (state, payload) => { state.deliveryDetail = payload },
    // deliveryDetailTableLabel: (state, payload) => { state.deliveryDetailTableLabel = payload },
    // deliveryDetailTableData: (state, payload) => { state.deliveryDetailTableData = payload },
    purchaseTableLabel: (state, payload) => { state.purchaseTableLabel = payload },
    purchaseOrderid: (state, payload) => { state.purchaseOrderid = payload },
    purchaseOrderBy: (state, payload) => { state.purchaseOrderBy = payload }
  },
  actions: {
    setPurchaseInputList: async (state) => {
      state.commit('searchInputList', state.getters.purchaseSearchInputList)
      state.commit('addInputlist', state.getters.purchaseAddInputlist)
      state.commit('tableLabel', state.getters.purchaseTableLabel)
      // console.log('setpurchaseInputList')
    },
    purchaseSearch: async (state, payload) => {
      var body = {}
      if (payload === null && JSON.stringify(state.getters.shipmentQuery) === '{}') {
        body.offset = 0
        body.limit = 10
        state.commit('purchaseOrderBy', '')
      } else {
        if (payload !== null && typeof payload === 'object') {
          if (payload.order_by) {
            state.commit('purchaseOrderBy', payload.order_by)
          } else {
            state.commit('purchaseOrderBy', '')
          }
          body = payload
        // } else {
        //   body = state.getters.shipmentQuery
        }
      }
      if (body === undefined) {
        body = {}
      }
      Object.values(state.getters.searchInputList).forEach(item => {
        // console.log('item', item)
        if (['input', 'date'].indexOf(item.type) > -1 && item.input !== '') {
          console.log('item', item.key, item.input)
          body[item.key] = item.input
        }
      })
      body.is_history = state.getters.flagQueryHistory ? 1 : 0
      var result = await purchaseList(body)
      switch (result.status) {
        case 200:
          state.commit('pageData', result.data.data.PageData)
          state.commit('tableData', result.data.data.ListData)
          break
        default:
          state.commit('pageData', [])
          state.commit('tableData', [])
      }
      console.log('handleSearch result', result)
    },
    purchaseHandleCurrentChange: async (state, payload) => {
      const currentPage = payload
      var result = {}
      const body = {
        offset: (parseInt(currentPage) - 1) * parseInt(state.getters.pageData.limit),
        limit: 10
      }
      if (state.getters.purchaseOrderBy !== '') {
        body.order_by = state.getters.purchaseOrderBy
      }
      if (JSON.stringify(state.getters.shipmentQuery) !== '{}') {
        body.track_point = state.getters.shipmentQuery.track_point
      }
      Object.values(state.getters.searchInputList).forEach(item => {
        if (['input', 'date'].indexOf(item.type) > -1 && item.input !== '') {
          body[item.key] = item.input
        }
      })
      body.is_history = state.getters.flagQueryHistory ? 1 : 0
      result = await purchaseList(body)
      result.data.data.PageData.totalPage = state.getters.pageData.totalPage
      result.data.data.PageData.currentPage = currentPage
      state.commit('pageData', result.data.data.PageData)
      state.commit('tableData', result.data.data.ListData)
      // console.log('handleCurrentChange body', body)
      // console.log('handleCurrentChange result', result)
    },
    delTableSelectDataByPurchase: async (state, payload) => {
      try {
        var rsp
        // console.log('delTableSelectData', state.getters.tableSelectData)
        await Object.values(state.getters.tableSelectData).forEach(async item => {
          // console.log('item', item.pre_book_no)
          const body = { pre_book_no: item.pre_book_no }
          // console.log('body', body)
          rsp = await purchaseDelete(body)
          console.log('result', rsp)
        })
      } catch (e) {
        console.log(e)
      }
      // console.log('rsp.status', rsp.status)
      // if (rsp.status === 200) {
      Object.values(state.getters.searchInputList).forEach(item => {
        if (item.type === 'input') {
          state.getters.pageData[item.key] = item.input
        }
      })
      const result = await purchaseList(state.getters.pageData)
      result.data.PageData.totalPage = state.getters.pageData.totalPage
      result.data.PageData.currentPage = state.getters.pageData.currentPage
      state.commit('pageData', result.data.PageData)
      state.commit('tableData', result.data.ListData)

      // }
      // var tableDataByDel = state.getters.tableData.filter(function (item, index, array) {
      //   var check = true
      //   Object.values(state.getters.tableSelectData).forEach(item2 => {
      //     if (item.pre_book_no === item2.pre_book_no) {
      //       check = false
      //     }
      //   })
      //   return check
      // })
      // state.commit('tableData', tableDataByDel)
      // console.log('tableDataByDel', tableDataByDel)
    },
    addDataByPurchase: async (state, payload) => {
      const body = {}
      Object.values(state.getters.addInputlist).forEach(item => {
        body[item.key] = item.input
      })
      // console.log(body)
      const rsp = await purchaseCreate(body)
      // console.log('rsp', rsp)
      switch (rsp.status) {
        case 201:
          this.delTableSelectData()
          this.$message({
            type: 'info',
            message: '新增成功'
          })
          break
        default:
          Object.keys(rsp.data).forEach(key => {
            Object.values(state.getters.addInputlist).forEach(item => {
              if (item.key === key) {
                item.msg = rsp.data[key][0]
              }
            })
          })
      }
    },
    addDealDate: async (state, payload) => {
      console.log(payload)
    }
  }
}

export default delivery
