<template>
  <div class="box-detail" v-if="selected">
    <add-box-detail
      :openDialog="openDialog"
      @handleClose="handleClose"
      @save="fetchAddNew"
      :receiptPlace="receiptPlace"
      :allData="allData"
      :row="row"
    ></add-box-detail>
    <edit-box-detail
      :openDialogEdit="openDialogEdit"
      @handleCloseEdit="handleCloseEdit"
      @vanEdit="fetchVanEdit"
      @vanDelete="fetchVanDelete"
      :data="editData"
      :value="openDialogEdit"
      :receiptPlace="receiptPlace"
    ></edit-box-detail>
    <div class="detail-group">
      <div class="detail-header">
        <h3>{{ name }}</h3>
        <button @click="addFn">加櫃</button>
      </div>
      <div class="detail-list">
        <div v-for="item in items" :key="item.id" class="detail-item">
          <div class="detail-item-header">
            <h3>{{ item.number }} {{ item.name }}</h3>
            <button @click="editFn(item)">
              <img src="@/assets/bb/edit.svg" />
            </button>
          </div>
          <div class="detail-item-content">
            <div class="detail-item-progress">
              <div class="progress-number">
                <h4 :style="computeTitle(item.weight, row.maxWeight)">
                  {{ item.weight.toFixed(3) }} kg
                </h4>
                <h5 v-if="name !== '其他類型'">
                  {{ Math.round((item.weight / row.maxWeight) * 100) }}%
                </h5>
              </div>
              <div class="progress-bar" v-if="name !== '其他類型'">
                <div class="border"></div>
                <div
                  class="width"
                  :style="computeWidth(item.weight, row.maxWeight)"
                ></div>
              </div>
            </div>
            <div class="detail-item-progress">
              <div class="progress-number">
                <h4 :style="computeTitle(item.measure, row.maxMeasure)">
                  {{ item.measure.toFixed(4) }} cbm
                </h4>
                <h5 v-if="name !== '其他類型'">
                  {{ Math.round((item.measure / row.maxMeasure) * 100) }}%
                </h5>
              </div>
              <div class="progress-bar" v-if="name !== '其他類型'">
                <div class="border"></div>
                <div
                  class="width"
                  :style="computeWidth(item.measure, row.maxMeasure)"
                ></div>
              </div>
            </div>
          </div>
          <div class="detail-item-footer">
            <p>放行件數：{{ item.packageCount }}</p>
            <div v-if="item.topping" class="badge">
              {{ handleArea(item) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddBoxDetail from '../../components/modal/box-detail/AddBoxDetail.vue'
import EditBoxDetail from '../../components/modal/box-detail/EditBoxDetail.vue'
// import { cfsVanAction } from '@/api/cfsVanApi'
import { putCfsVanEditAndAdd } from '@/api/cfsApi'

export default {
  components: {
    AddBoxDetail,
    EditBoxDetail
  },
  props: [
    'name',
    'items',
    'selected',
    'row',
    'allData',
    'receiptPlace',
    'searchCondition'
  ],
  data() {
    return {
      openDialog: false,
      openDialogEdit: false,
      allAddData: {
        action: 'A',
        pre_book_no: null,
        vsl_name: null,
        voyage: null,
        so_no: null,
        o_bl_no: null,
        receipt_place: null,
        port_discharge: null,
        port_load: null,
        delivery_place: null,
        ship_num: null,
        edi_type: 'A',
        van_type: null,
        van_no: null,
        reg_ship: null,
        transfer_by: null,
        transfer_dt: null,
        carrier: this.searchCondition.carrier
      },
      editData: null
    }
  },
  methods: {
    handleClose(e) {
      this.openDialog = false
    },
    handleCloseEdit() {
      this.openDialogEdit = false
    },
    handleArea(row) {
      const obj = {
        TWKEL: '基',
        TWTPE: '北',
        TWTYN: '桃',
        TWTXG: '中',
        TWKHH: '高'
      }
      var areaArr = []
      var areaStr = ''
      Object.keys(obj).forEach((key) => {
        if (row['Area_' + key.substr(2)] === 1) {
          areaArr.push(obj[key])
        }
      })
      areaStr = areaArr.join('-')
      return areaStr
    },
    addFn() {
      this.openDialog = true
    },
    editFn(val) {
      this.editData = val
      this.openDialogEdit = true
    },
    computeWidth(dividend, all) {
      const percent = Math.round((dividend / all) * 100)

      if (percent < 33) {
        return {
          width: `${percent}%`,
          backgroundColor: '#84C242',
          borderTop: '1px solid #84C242',
          borderBottom: '1px solid #84C242'
        }
      } else if (percent < 66 && percent >= 33) {
        return {
          width: `${percent}%`,
          backgroundColor: '#E9DA48',
          borderTop: '1px solid #E9DA48',
          borderBottom: '1px solid #E9DA48'
        }
      } else {
        return {
          width: `${percent}%`,
          backgroundColor: '#D04433',
          borderTop: '1px solid #D04433',
          borderBottom: '1px solid #D04433'
        }
      }
    },
    computeTitle(dividend, all) {
      const percent = Math.round((dividend / all) * 100)
      if (percent < 33) {
        return {
          color: '#6BA636'
        }
      } else if (percent < 66 && percent >= 33) {
        return {
          color: '#C8B93C'
        }
      } else {
        return {
          color: '#D04433'
        }
      }
    },
    async fetchAddNew(e) {
      this.handleData('A')
      var addBody = {
        ...this.allAddData,
        ...e
      }
      addBody.area_kel = addBody.Area_KEL ? 1 : 0
      addBody.area_tpe = addBody.Area_TPE ? 1 : 0
      addBody.area_tyn = addBody.Area_TYN ? 1 : 0
      addBody.area_txg = addBody.Area_TXG ? 1 : 0
      addBody.area_khh = addBody.Area_KHH ? 1 : 0
      if (addBody.area_khh + addBody.area_kel + addBody.area_txg + addBody.area_tpe + addBody.area_tyn >= 2) {
        addBody.topping = 1
      } else {
        addBody.topping = 0
      }
      console.log(addBody)
      const res = await putCfsVanEditAndAdd(addBody)

      if (res.code === '200') {
        this.$message({
          type: 'info',
          message: '新增成功'
        })
        this.$emit('update')
        this.handleClose()
        return
      }
      this.$message({
        type: 'info',
        message: '新增失敗'
      })
    },
    async fetchVanEdit(e) {
      this.handleData('U')
      var editBody = {
        ...this.allAddData,
        ...e
      }
      editBody.area_kel = editBody.Area_KEL ? 1 : 0
      editBody.area_tpe = editBody.Area_TPE ? 1 : 0
      editBody.area_tyn = editBody.Area_TYN ? 1 : 0
      editBody.area_txg = editBody.Area_TXG ? 1 : 0
      editBody.area_khh = editBody.Area_KHH ? 1 : 0
      if (editBody.area_khh + editBody.area_kel + editBody.area_txg + editBody.area_tpe + editBody.area_tyn >= 2) {
        editBody.topping = 1
      } else {
        editBody.topping = 0
      }
      const res = await putCfsVanEditAndAdd(editBody)

      if (res.code === '200') {
        this.$message({
          type: 'info',
          message: '修改成功'
        })
        this.$emit('update')
        this.handleCloseEdit()
        return
      }
      this.$message({
        type: 'info',
        message: '修改失敗'
      })
    },
    async fetchVanDelete(e) {
      const res = await putCfsVanEditAndAdd({ id: e, action: 'D' })

      if (res.code === '200') {
        this.$message({
          type: 'info',
          message: '刪除成功'
        })
        this.$emit('update')
        this.handleCloseEdit()
        return
      }
      this.$message({
        type: 'info',
        message: '刪除失敗'
      })
    },
    handleData(isEdit) {
      Object.keys(this.allAddData).forEach(item => {
        if (item === 'action' || item === 'edi_type') {
          this.allAddData[item] = isEdit
          this.allAddData.edi_type = isEdit
          return
        }
        if (item === 'van_type') {
          this.allAddData[item] = this.row.van_type
          return
        }
        if (item === 'transfer_dt') {
          const date = new Date()
          const year = date.getFullYear() // 年
          const month = date.getMonth() + 1 // 月
          const dates = date.getDate() // 日
          const time = date
            .toTimeString()
            .substr(0, 8)
            .replace(/:/gi, '')
          this.allAddData[item] = `${String(year).substr(
            2,
            2
          )}${month}${dates}${time}`
          return
        }
        this.allAddData[item] = this.allData[0][item]
      })
    }
  },
  mounted: function() {
    console.log('props', this.cfsVanList, this.cfsList)
  }
}
</script>

<style lang="scss" scoped>
.box-detail {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  margin: 12px 24px;
  margin-right: 39px;
}

.detail-group {
  display: flex;
  flex-direction: column;
  padding-top: 11px;

  & .detail-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    padding-bottom: 11px;
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.04);

    & h3 {
      color: #25282b;
    }

    & button {
      background-color: #4ba676;
      border: none;
      border-radius: 2px;
      color: #ffffff;
      padding: 5px 20px;
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
      }

      &.cancel {
        background-color: #f7f7f9;
        color: #3b4044;
        border: 1px solid #e0e1e1;
      }
    }
  }

  & .detail-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding-top: 16px;

    & .detail-item {
      display: grid;
      grid-template-rows: max-content 1fr 2rem;
      grid-row-gap: 10px;
      width: 230px;
      border-right: 1px solid rgba(0, 0, 0, 0.08);
      padding: 0 16px;
      margin-bottom: 24px;

      & .detail-item-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        & h3 {
          font-size: 14px;
          font-weight: 500;
        }

        & button {
          border: none;
          background-color: transparent;

          & :hover {
            cursor: pointer;
          }

          & img {
            height: 17px;
          }
        }
      }

      & .detail-item-content {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-row-gap: 12px;

        & .detail-item-progress {
          display: grid;
          grid-template-rows: 1fr 1fr;
          grid-row-gap: 8px;

          & .progress-number {
            display: flex;
            justify-content: space-between;

            & h4 {
              font-size: 16px;
              font-weight: 500;
              color: #6ba636;
            }

            & h5 {
              font-size: 14px;
              font-weight: 400;
              color: #87898c;
            }
          }

          & .progress-bar {
            position: relative;
            overflow: hidden;

            & .border {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 3px;
              border: 1px solid #e0e1e1;
              background-color: #e0e1e1;
            }

            & .width {
              position: absolute;
              z-index: 1;
              top: 0;
              left: 0;
              height: 3px;
              background-color: #84c242;
              border-top: 1px solid #84c242;
              border-bottom: 1px solid #84c242;
            }
          }
        }
      }

      & .detail-item-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & p {
          font-size: 13px;
          font-weight: 400;
          color: #87898c;
        }

        & .badge {
          background-color: #f7f7f9;
          color: #696c70;
          padding: 4px 8px;
          border-radius: 2px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
