<template>
  <div class="notice-box">
    <p>搜尋</p>
    <el-card class="box-card" shadow="never">
      <div class="search-bar">
        <div class="input">
          <div v-for="data in searchBar" :key="data.label">
            <p>{{ data.label }}</p>
            <el-input v-model="data.val"></el-input>
          </div>
        </div>
        <div class="btn-box">
          <div @click="fetchList">
            <base-button class="button" type="button">
              <template #icon>
                <img src="@/assets/bb/search.svg" />
              </template>
              <template #default>
                <p>搜尋</p>
              </template>
            </base-button>
          </div>
          <div @click="handleClear">
            <base-button class="button redColor" type="button">
              <template #icon>
                <i class="el-icon-close"></i>
              </template>
              <template #default>
                <p>清除</p>
              </template>
            </base-button>
          </div>
        </div>
      </div>
    </el-card>

    <el-card class="table-card" shadow="never">
      <div class="title-box">
        <p>裝船通知清單</p>
        <el-button class="btn" @click="handleAddNew">
          裝船通知表單
          <i class="el-icon-plus el-icon--right" />
        </el-button>
      </div>
      <el-table :data="tableData" border style="width: 100%;color: #333" @sort-change="handleSortChange">
        <el-table-column
          v-for="(data, index) in column"
          :key="index"
          :prop="data.field"
          :label="data.label"
          :min-width="data.width || 'auto'"
          :sortable="data.field !== 'action' && data.field !== 'edit'"
        >
          <template slot-scope="scope">
            <template v-if="data.field === 'edit'">
              <el-button
                @click="handleEdit(scope.row)"
                type="text"
                icon="el-icon-edit"
              ></el-button>
            </template>
            <template v-else>
              {{ scope.row[data.field] }}
            </template>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        layout="prev, pager, next"
        :page-size="20"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { shipmentTempList } from '@/api/shipmentTemp'

export default {
  data() {
    return {
      searchBar: {
        pre_book_no: {
          label: 'Job No',
          val: null
        },
        x: {
          label: '海掛',
          val: null
        },
        vsl_name: {
          label: '船名',
          val: null
        },
        voyage: {
          label: '航次',
          val: null
        },
        so_no: {
          label: 'SO No',
          val: null
        },
        carrier: {
          label: '船公司',
          val: null
        },
        closing_date: {
          label: '結關日',
          val: null
        },
        onboard_date: {
          label: '裝船日',
          val: null
        },
        receipt_place: {
          label: '裝貨港POL',
          val: null
        },
        port_discharge: {
          label: '卸貨港POD',
          val: null
        },
        cfs_delivery_place: {
          label: 'CFS交貨處',
          val: null
        },
        cfs_number_desc: {
          label: 'CFS貨量',
          val: null
        }
      },
      column: [
        {
          field: 'pre_book_no',
          label: 'Job No'
        },
        {
          field: '',
          label: '海掛'
        },
        {
          field: 'vsl_name',
          label: '船名'
        },
        {
          field: 'voyage',
          label: '航次'
        },
        {
          field: 'so_no',
          label: 'SO No'
        },
        {
          field: 'carrier',
          label: '船公司'
        },
        {
          field: 'closing_date',
          label: '結關日'
        },
        {
          field: 'onboard_date',
          label: '裝船日'
        },
        {
          field: 'receipt_place',
          label: '裝貨港POL'
        },
        {
          field: 'port_discharge',
          label: '卸貨港POD'
        },
        {
          field: 'cfs_delivery_place',
          label: 'CFS交貨處'
        },
        {
          field: 'cfs_number_desc',
          label: 'CFS貨量'
        }
      ],
      tableData: [],
      page: {
        limit: 20,
        offset: 0,
        sort: ''
      },
      currentPage: 0,
      total: 0
    }
  },
  methods: {
    handleSortChange({ prop, order }) {
      if (order === 'ascending') {
        this.page.sort = prop
      } else if (order === 'descending') {
        this.page.sort = '-' + prop
      } else {
        this.page.sort = ''
      }
      this.fetchList(false)
    },
    handleCurrentChange(val) {
      this.page.offset = (val - 1) * 20
      this.fetchList(false)
    },
    handleClear() {
      Object.keys(this.searchBar).forEach(key => {
        this.searchBar[key].val = null
      })
      this.page.offset = 0
      this.tableData = []
      this.total = 0
    },
    async fetchList(repage = true) {
      if (repage) {
        this.page.offset = 0
      }
      Object.keys(this.searchBar).forEach(item => {
        this.page[item] = this.searchBar[item].val
      })
      const { data: res } = await shipmentTempList(this.page)
      if (res.code === '200') {
        this.tableData = res.data.ListData
        this.total = res.data.PageData.count
      }
    },

    handleEdit(val) {
      console.log('click', val)
      this.$router.push({ name: 'shippingNoticeEdit', params: { id: val.id } })
    },
    handleAddNew() {
      console.log('go shippingNoticeAddNew')
      this.$router.push({ name: 'shippingNoticeAddNew' })
    }
  },
  mounted() {
    // this.fetchList()
  }
}
</script>

<style lang="scss" scoped>
.notice-box {
  padding: 24px;
  box-sizing: border-box;
  background: #f7f7f9;

  > p {
    margin-bottom: 16px;
  }
  .box-card {
    border-radius: 8px;
    border: 0;
  }
}

.search-bar {
  display: grid;
  grid-template-columns: auto 6.5%;
  grid-column-gap: 16px;

  .input {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 2em;

    > div > p {
      margin-bottom: 10px;
      font-size: 14px;
    }
  }
}

.btn-box {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: end;

  .button {
    margin: 2px;

    i {
      width: 16px;
      height: 16px;
      font-size: 16px;
      margin-right: 6px;
    }
  }
}

.table-card {
  margin-top: 24px;
  border-radius: 8px;
  border: 0;

  .title-box {
    margin: 8px 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .btn {
    border: 1px solid #e0e1e1;
    background: #f7f7f9;
    color: #3b4044;
    padding: 8px 10px;

    &:hover {
      opacity: 0.8;
    }
  }
}

::v-deep .el-table th.is-leaf {
  color: #333;
}
</style>
