
// import { deliveryMasterList, deliveryMasterDelete, deliveryDetailList, deliveryMasterCreate } from '@/api/delivery'
// eslint-disable-next-line
import {
  deliveryMasterList,
  deliveryMasterDelete,
  deliveryMasterCreate,
  deliveryMasterSingle,
  // deliveryPurchaseNo,
  deliveryDetailCreate,
  deliveryDetailList,
  deliveryMasterUpdate,
  deliveryDetailUpdate,
  deliveryDetailDelete,
  deliveryConfirmedSingle,
  deliveryUpdateReferenceNo
  // deliveryConfirmedList
} from '@/api/delivery'
import axios from 'axios'
import { uploadChainReq } from '@/api/chainOperator'
// eslint-disable-next-line
import { purchaseSingle, purchaseList } from '@/api/purchase'
// import { decisionUploadlog } from '@/api/decision'
// import { ipfsAppGetpdf } from '@/api/ipfsApp'
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
const delivery = {
  state: {
    deliveryMasterSearchInputList: [{
      label: 'Job No',
      input: '',
      type: 'input',
      key: 'pre_book_no'
    }, {
      label: '船名Vessel',
      input: '',
      type: 'input',
      key: 'vsl_name'
    }, {
      label: '航次Voyage',
      input: '',
      type: 'input',
      key: 'voyage'
    }, {
      label: 'SO No',
      input: '',
      type: 'input',
      key: 'so_no'
    }, {
      label: 'HB/L No',
      input: '',
      type: 'input',
      key: 'o_bl_no'
    }, {
      label: '客戶名稱',
      input: '',
      type: 'input',
      key: 'customer_code'
    }, {
      label: '裝貨港POL',
      input: 'POL',
      key: 'port_load',
      msg: ''
    }, {
      label: 'Refrence No',
      input: '',
      type: 'input',
      key: 'reference_no'
    }, {
      label: '收貨港PLR',
      input: 'PLR',
      key: 'receipt_place',
      msg: ''
    }, {
      label: '卸貨港POD',
      input: 'POD',
      key: 'port_discharge',
      msg: ''
    }],
    deliveryMasterAddInputlist: [{
      label: 'Job No',
      input: 'JobNo31',
      key: 'pre_book_no',
      msg: ''
    }, {
      label: '船名Vessel',
      input: 'vslName',
      key: 'vsl_name',
      msg: ''
    }, {
      label: '航次Voyage',
      input: 'Voyage',
      key: 'voyage',
      msg: ''
    }, {
      label: 'SO No',
      input: 'soNo',
      key: 'so_no',
      msg: ''
    }, {
      label: 'HB/L No',
      input: 'soNo',
      key: 'o_bl_no',
      msg: ''
    }, {
      label: '客戶名稱',
      input: 'customerCode',
      key: 'customer_code',
      msg: ''
    }, {
      label: '結關日期',
      input: 'closingDate',
      key: 'closing_date',
      msg: ''
    }, {
      label: '裝船日期',
      input: 'onboardDate',
      key: 'onboard_date',
      msg: ''
    }, {
      label: '裝貨港POL',
      input: 'POL',
      key: 'port_load',
      msg: ''
    }, {
      label: '卸貨港POD',
      input: 'POD',
      key: 'port_discharge',
      msg: ''
    }, {
      label: 'POL',
      input: 'POL',
      key: 'port_load',
      msg: ''
    }, {
      label: 'PLD',
      input: 'PLD',
      key: 'delivery_place',
      msg: ''
    }, {
      label: 'VoyID',
      input: 'VoyID',
      key: 'ship_num',
      msg: ''
    }, {
      label: 'salesCode',
      input: 'salesCode',
      key: 'sales_code',
      msg: ''
    }, {
      label: 'entryUserCode',
      input: 'test',
      key: 'entry_user_code',
      msg: ''
    }, {
      label: 'cfsDeliveryPlace',
      input: 'cfsDeliveryPlace',
      key: 'cfs_delivery_place',
      msg: ''
    }, {
      label: 'brokerCode',
      input: 'brokerCode',
      key: 'broker_code',
      msg: ''
    }, {
      label: 'truckCompany',
      input: 'truckCompany',
      key: 'truck_company',
      msg: ''
    }, {
      label: 'carrier',
      input: 'carrier',
      key: 'carrier',
      msg: ''
    }, {
      label: 'selfPodAgent',
      input: 'selfPodAgent',
      key: 'self_pod_agent',
      msg: ''
    }, {
      label: 'ediType',
      input: 'aa',
      key: 'edi_type',
      msg: ''
    }, {
      label: 'transferBy',
      input: 'transferBy',
      key: 'transfer_by',
      msg: ''
    }, {
      label: 'transfer_dt',
      input: '',
      key: 'transferDt',
      msg: ''
    }],
    deliveryMasterTableLabel: [{
      label: '單據檔',
      minWidth: 130,
      fixed: 'left',
      link: true,
      prop: 'file_name',
      node: 'edit',
      url: 'quotation',
      key: 'pre_book_no'
    }, {
      label: 'Job No',
      minWidth: 130,
      fixed: 'left',
      link: true,
      prop: 'pre_book_no',
      node: 'edit',
      url: 'shipment'
    }, {
      label: '船名Vessel',
      minWidth: 200,
      prop: 'vsl_name'
    }, {
      label: '航次Voyage',
      minWidth: 130,
      prop: 'voyage'
    }, {
      label: 'SO No',
      prop: 'so_no'
    }, {
      label: 'HB/L No',
      minWidth: 180,
      prop: 'o_bl_no'
    }, {
      label: '客戶中文名稱',
      minWidth: 200,
      prop: 'customer_code'
    }, {
      label: '結關日期',
      minWidth: 120,
      prop: 'closing_date'
    }, {
      label: '裝船日期',
      minWidth: 120,
      prop: 'onboard_date'
    }, {
      label: '卸貨港POD',
      minWidth: 150,
      prop: 'port_discharge'
    }, {
      label: '裝貨港POL',
      minWidth: 150,
      prop: 'port_load'
    }],
    // deliveryDetailTableLabel: [{
    //   type: 'selection',
    //   minWidth: 50,
    //   fixed: 'left'
    // }, {
    //   label: 'pre_book_no',
    //   prop: 'pre_book_no',
    //   minWidth: 50,
    //   fixed: 'left'
    // }, {
    //   label: 'sequence_no',
    //   prop: 'sequence_no',
    //   minWidth: 50
    // }, {
    //   label: 'charge_code',
    //   prop: 'charge_code',
    //   minWidth: 50
    // }, {
    //   label: 'charge_name',
    //   prop: 'charge_name',
    //   minWidth: 50
    // }, {
    //   label: 'org_currency',
    //   prop: 'org_currency',
    //   minWidth: 50
    // }, {
    //   label: 'chg_rate',
    //   prop: 'chg_rate',
    //   minWidth: 50
    // }, {
    //   label: 'chg_qty',
    //   prop: 'chg_qty',
    //   minWidth: 50
    // }, {
    //   label: 'chg_unit',
    //   prop: 'chg_unit',
    //   minWidth: 50
    // }, {
    //   label: 'ttl_org_amount',
    //   prop: 'ttl_org_amount',
    //   minWidth: 50
    // }, {
    //   label: 'tax_rate',
    //   prop: 'tax_rate',
    //   minWidth: 50
    // }, {
    //   label: 'ttl_debit_amount',
    //   prop: 'ttl_debit_amount',
    //   minWidth: 50
    // }, {
    //   label: 'tax_type',
    //   prop: 'tax_type',
    //   minWidth: 50
    // }, {
    //   label: 'edi_type',
    //   prop: 'edi_type',
    //   minWidth: 50
    // }, {
    //   label: 'transfer_dt',
    //   prop: 'transfer_dt',
    //   minWidth: 50
    // }, {
    //   label: 'Edit',
    //   prop: 'Edit',
    //   icon: true,
    //   minWidth: 20,
    //   node: 'Edit',
    //   key: 'pre_book_no'
    // }, {
    //   label: 'View',
    //   prop: 'View',
    //   icon: true,
    //   minWidth: 20,
    //   node: 'View',
    //   key: 'pre_book_no'
    // }],
    deliveryDetailTableLabel: [{
      label: 'version',
      prop: 'version',
      minWidth: 50
    }, {
      label: 'update_time',
      prop: 'transfer_dt',
      minWidth: 50
    }, {
      label: 'uploader',
      prop: 'transfer_by',
      minWidth: 50
    }, {
      label: 'status',
      prop: 'status',
      minWidth: 50,
      fixed: 'left'
    }, {
      label: 'Edit',
      prop: 'Edit',
      icon: true,
      minWidth: 20,
      node: 'Edit',
      key: 'id'
    }, {
      label: 'View',
      prop: 'View',
      icon: true,
      minWidth: 20,
      node: 'View',
      key: 'id'
    }],
    // orderDataInputList: [{
    //   type: 'selection',
    //   minWidth: 50,
    //   fixed: 'left'
    // }, {
    //   label: 'version',
    //   input: 'carrier',
    //   key: 'carrier',
    //   msg: ''
    // }, {
    //   label: 'carrier',
    //   input: 'carrier',
    //   key: 'carrier',
    //   msg: ''
    // }, {
    //   label: 'carrier',
    //   input: 'carrier',
    //   key: 'carrier',
    //   msg: ''
    // }, {
    //   label: 'carrier',
    //   input: 'carrier',
    //   key: 'carrier',
    //   msg: ''
    // }, {
    //   label: 'carrier',
    //   input: 'carrier',
    //   key: 'carrier',
    //   msg: ''
    // }, {
    //   label: 'carrier',
    //   input: 'carrier',
    //   key: 'carrier',
    //   msg: ''
    // }],
    quotationConfirmedTableLabel: [{
      label: 'version',
      prop: 'version',
      minWidth: 50
    }, {
      label: 'update_time',
      prop: 'transfer_dt',
      minWidth: 50
    }, {
      label: 'uploader',
      prop: 'transfer_by',
      minWidth: 50
    }, {
      label: 'status',
      prop: 'status',
      minWidth: 50,
      fixed: 'left'
    }, {
      label: 'Edit',
      prop: 'Edit',
      icon: true,
      minWidth: 20,
      node: 'Edit',
      key: 'id'
    }, {
      label: 'View',
      prop: 'View',
      icon: true,
      minWidth: 20,
      node: 'View',
      key: 'id'
    }],
    deliveryDetailTableData: [],
    deliveryDetailData: {
      purchaseSingle: {},
      detailList: {}
    },
    deliveryOrderid: '',
    taxInfo: {
      total_zero_tax: '',
      taxable_amount: '',
      tax: '',
      total_taxable: '',
      total_amount_receivable: ''
    },
    deliveryDetailItems: [{
      sequence_no: '',
      charge_code: '',
      charge_name: '',
      org_currency: '',
      chg_rate: '',
      chg_qty: '',
      chg_unit: '',
      ttl_org_amount: '',
      tax_rate: '',
      ttl_debit_amount: '',
      tax_type: ''
    }],
    deliveryMasterItem: {
      pre_book_no: '',
      vsl_name: '',
      voyage: '',
      so_no: '',
      o_bl_no: '',
      customer_code: '',
      closing_date: '',
      onboard_date: '',
      receipt_place: '',
      port_discharge: '',
      port_load: '',
      delivery_place: '',
      ship_num: '',
      sales_code: '',
      entry_user_code: '',
      cfs_delivery_place: '',
      broker_code: '',
      truck_company: '',
      carrier: '',
      self_pod_agent: ''
    },
    deliveryRsp: {},
    reference_no: ''
  },
  getters: {
    deliveryMasterSearchInputList: (state) => state.deliveryMasterSearchInputList,
    deliveryMasterAddInputlist: (state) => state.deliveryMasterAddInputlist,
    deliveryDetailData: (state) => state.deliveryDetailData,
    deliveryDetailTableLabel: (state) => state.deliveryDetailTableLabel,
    deliveryDetailTableData: (state) => state.deliveryDetailTableData,
    deliveryMasterTableLabel: (state) => state.deliveryMasterTableLabel,
    deliveryOrderid: (state) => state.deliveryOrderid,
    taxInfo: (state) => state.taxInfo,
    deliveryDetailItems: (state) => state.deliveryDetailItems,
    deliveryMasterItem: (state) => state.deliveryMasterItem,
    deliveryRsp: (state) => state.deliveryRsp,
    quotationConfirmedTableLabel: (state) => state.quotationConfirmedTableLabel,
    reference_no: (state) => state.reference_no
  },

  mutations: {
    deliveryMasterSearchInputList: (state, payload) => { state.deliveryMasterSearchInputList = payload },
    deliveryMasterAddInputlist: (state, payload) => { state.deliveryMasterAddInputlist = payload },
    deliveryDetail: (state, payload) => { state.deliveryDetail = payload },
    deliveryDetailData: (state, payload) => { state.deliveryDetailData = payload },
    deliveryDetailTableLabel: (state, payload) => { state.deliveryDetailTableLabel = payload },
    deliveryDetailTableData: (state, payload) => { state.deliveryDetailTableData = payload },
    deliveryMasterTableLabel: (state, payload) => { state.deliveryMasterTableLabel = payload },
    deliveryOrderid: (state, payload) => { state.deliveryOrderid = payload },
    taxInfo: (state, payload) => { state.taxInfo = payload },
    deliveryDetailItems: (state, payload) => { state.deliveryDetailItems = payload },
    deliveryMasterItem: (state, payload) => { state.deliveryMasterItem = payload },
    deliveryRsp: (state, payload) => { state.deliveryRsp = payload },
    reference_no: (state, payload) => { state.reference_no = payload },
    quotationConfirmedTableLabel: (state, payload) => { state.quotationConfirmedTableLabel = payload }
  },
  actions: {
    setDeliveryMasterInputList: async (state) => {
      state.commit('searchInputList', state.getters.deliveryMasterSearchInputList)
      state.commit('addInputlist', state.getters.deliveryMasterAddInputlist)
      state.commit('tableLabel', state.getters.deliveryMasterTableLabel)
      // console.log('setDeliveryMasterInputList')
    },
    deliveryMasterSearch: async (state, payload) => {
      const body = {
        offset: 0,
        limit: 10
      }
      Object.values(state.getters.searchInputList).forEach(item => {
        if (item.type === 'input') {
          body[item.key] = item.input
        }
      })
      console.log('deliveryMasterSearch body', body)
      var result = await deliveryMasterList(body)
      switch (result.status) {
        case 200:
          state.commit('pageData', result.data.data.PageData)
          state.commit('tableData', result.data.data.ListData)
          break
        default:
          state.commit('pageData', [])
          state.commit('tableData', [])
      }
      console.log('deliveryMasterSearch result', result)
    },
    saveRefrenceNo: async (state, payload) => {
      var res = await deliveryUpdateReferenceNo(payload)
      return res
    },
    deliveryDetailSearch: async (state, payload) => {
      const body = { pre_book_no: payload.orderId }
      if (payload.version !== undefined) {
        body.version = payload.version
      }
      console.log('deliveryMasterSingle body', body)
      var result = await deliveryMasterSingle(body)
      Object.keys(state.getters.taxInfo).forEach(key => {
        state.getters.taxInfo[key] = result.data.ListData[0][key] !== undefined ? result.data.ListData[0][key] : ''
      })
      state.commit('taxInfo', state.getters.taxInfo)
      if (!payload.withoutInfoData) {
        state.dispatch('singleFileInfoData', { rows: result, pre_book_no: body.pre_book_no, pdf_serial: 0 })
      }
      const decisionUploadlogListRsp = result
      const purchaseSingleRsp = await purchaseSingle({ pre_book_no: payload.orderId })
      console.log('deliveryDetailSearch result', purchaseSingleRsp)
      if (purchaseSingleRsp.status === 200) {
        state.getters.deliveryDetailData.purchaseSingle = purchaseSingleRsp.data.data[0]
        // state.getters.reference_no = purchaseSingleRsp.data.data[0].reference_no
        state.commit('reference_no', purchaseSingleRsp.data.data[0].reference_no)
        // console.log('decisionUploadlogListRsp', state.getters.deliveryDetailData.decisionUploadlogListRsp)
        if (decisionUploadlogListRsp.code === '200') {
          // console.log('decisionUploadlogListRsp', decisionUploadlogListRsp.data.data)
          state.getters.deliveryDetailData.detailList = decisionUploadlogListRsp.data.ListData
          await Object.values(state.getters.deliveryDetailData.detailList).forEach(item => {
            item.Edit = faEdit
            item.View = faEye
            item.pre_book_no = payload.orderId
          })
        }
        const orderDataInputList = []
        Object.keys(state.getters.deliveryDetailData.purchaseSingle).forEach(async (key, idx) => {
          if (idx < 13) {
            if (key !== 'track_point') {
              if (key === 'status' || key === 'Status') {
                // 應該說 除了 Job_Flag =DSCO，其他都顯示進行中，對吧
                var dsocArr = ['取消出貨', '退關', '關檔', '開檔']
                var statusCheckKey = ['D', 'S', 'O', 'C'].indexOf(result.data.ListData[0].job_flag)
                if (statusCheckKey > -1) {
                  orderDataInputList.push({ label: key, input: dsocArr[statusCheckKey] })
                } else {
                  orderDataInputList.push({ label: key, input: '進行中' })
                }
              } else {
                orderDataInputList.push({ label: key, input: state.getters.deliveryDetailData.purchaseSingle[key] })
                // state.getters.reference_no
              }
            }
          }
        })
        Object.values(Object.keys(state.getters.deliveryDetailData.purchaseSingle).filter(key => key.endsWith('point') && key !== 'track_point')).forEach(async (key, idx) => {
          Object.values(state.getters.steps).forEach(item => {
            if (item.key === key) {
              switch (state.getters.deliveryDetailData.purchaseSingle[key]) {
                case 0:
                  item.status = 'wait'
                  item.description = '未處理'
                  break
                case 1:
                  item.status = 'process'
                  item.description = '處理中'
                  break
                case 2:
                  item.status = 'success'
                  item.description = '已完成'
                  break
              }
            }
          })
        })
        // const keyVersions = Object.keys(state.getters.deliveryDetailData.purchaseSingle).filter(key => key.endsWith('_latest_version'))
        // console.log(keyVersions)
        Object.values(Object.keys(state.getters.deliveryDetailData.purchaseSingle).filter(key => key.endsWith('_latest_version'))).forEach(async (key, idx) => {
          state.getters.steps.forEach(item => {
            item.key2.forEach(it => {
              if (it === key) {
                switch (key) {
                  case 'qu_latest_version':
                    if (state.getters.deliveryDetailData.purchaseSingle[key] === 0 ||
                       state.getters.deliveryDetailData.purchaseSingle[key] === null ||
                       state.getters.deliveryDetailData.purchaseSingle[key] === 1) {
                      item.version = []
                    } else {
                      item.version[0] = 'QU: ' + (state.getters.deliveryDetailData.purchaseSingle[key] - 1)
                    }
                    break
                  case 'sa_latest_version':
                    if (state.getters.deliveryDetailData.purchaseSingle[key] === 0 ||
                       state.getters.deliveryDetailData.purchaseSingle[key] === null ||
                       state.getters.deliveryDetailData.purchaseSingle[key] === 1) {
                      item.version = []
                    } else {
                      item.version[0] = 'SA: ' + (state.getters.deliveryDetailData.purchaseSingle[key] - 1)
                      console.log(item.version)
                    }
                    break
                  case 'md_latest_version':
                    if (state.getters.deliveryDetailData.purchaseSingle[key] === 0 ||
                       state.getters.deliveryDetailData.purchaseSingle[key] === null ||
                       state.getters.deliveryDetailData.purchaseSingle[key] === 1) {
                      item.version = []
                    } else {
                      item.version[0] = 'MD: ' + (state.getters.deliveryDetailData.purchaseSingle[key] - 1)
                    }
                    break
                  case 'iv_latest_version':
                    if (state.getters.deliveryDetailData.purchaseSingle[key] === 0 ||
                       state.getters.deliveryDetailData.purchaseSingle[key] === null ||
                       state.getters.deliveryDetailData.purchaseSingle[key] === 1) {
                      item.version[0] = ''
                    } else {
                      const iv = 'IV: ' + (state.getters.deliveryDetailData.purchaseSingle[key] - 1)
                      item.version[0] = iv
                    }
                    break
                  case 'pl_latest_version':
                    if (state.getters.deliveryDetailData.purchaseSingle[key] === 0 ||
                      state.getters.deliveryDetailData.purchaseSingle[key] === null ||
                      state.getters.deliveryDetailData.purchaseSingle[key] === 1) {
                      item.version[1] = ''
                    } else {
                      const pl = 'PL: ' + (state.getters.deliveryDetailData.purchaseSingle[key] - 1)
                      item.version[1] = pl
                    }
                    break
                  case 'so_latest_version':
                    if (state.getters.deliveryDetailData.purchaseSingle[key] === 0 ||
                      state.getters.deliveryDetailData.purchaseSingle[key] === null ||
                      state.getters.deliveryDetailData.purchaseSingle[key] === 1) {
                      item.version[2] = ''
                    } else {
                      const so = 'SO: ' + (state.getters.deliveryDetailData.purchaseSingle[key] - 1)
                      item.version[2] = so
                    }
                    break
                  case 'hc_latest_version':
                    if (state.getters.deliveryDetailData.purchaseSingle[key] === 0 ||
                      state.getters.deliveryDetailData.purchaseSingle[key] === null ||
                      state.getters.deliveryDetailData.purchaseSingle[key] === 1) {
                      item.version = []
                    } else {
                      item.version[0] = 'HC: ' + (state.getters.deliveryDetailData.purchaseSingle[key] - 1)
                    }
                    break
                  case 'qc_latest_version':
                    if (state.getters.deliveryDetailData.purchaseSingle[key] === 0 ||
                       state.getters.deliveryDetailData.purchaseSingle[key] === null ||
                       state.getters.deliveryDetailData.purchaseSingle[key] === 1) {
                      item.version = []
                    } else {
                      item.version[0] = 'QC: ' + (state.getters.deliveryDetailData.purchaseSingle[key] - 1)
                    }
                    break
                  case 'hb_latest_version':
                    if (state.getters.deliveryDetailData.purchaseSingle[key] === 0 ||
                       state.getters.deliveryDetailData.purchaseSingle[key] === null ||
                       state.getters.deliveryDetailData.purchaseSingle[key] === 1) {
                      item.version[0] = ''
                    } else {
                      const hb = 'HB: ' + (state.getters.deliveryDetailData.purchaseSingle[key] - 1)
                      item.version[0] = hb
                    }
                    break
                  case 'dn_latest_version':
                    if (state.getters.deliveryDetailData.purchaseSingle[key] === 0 ||
                      state.getters.deliveryDetailData.purchaseSingle[key] === null ||
                      state.getters.deliveryDetailData.purchaseSingle[key] === 1) {
                      item.version[1] = ''
                    } else {
                      const dn = 'DN: ' + (state.getters.deliveryDetailData.purchaseSingle[key] - 1)
                      item.version[1] = dn
                    }
                    break
                }
              }
            })
          })
        })
        if (!payload.withoutInfoData) {
          // await state.commit('pageData', decisionUploadlogListRsp.data.data.PageData)
          await state.commit('pageData', [])
          await state.commit('tableData', state.getters.deliveryDetailData.detailList)
          await state.commit('tableLabel', state.getters.deliveryDetailTableLabel)
        }
        await state.commit('orderId', payload.orderId)
        await state.commit('orderDataInfo', orderDataInputList)
      } else {
        await state.commit('pageData', [])
        await state.commit('tableData', [])
        await state.commit('tableLabel', [])
        await state.commit('orderId', '')
        await state.commit('orderDataInfo', [])
      }
    },
    singleFileInfoData: async (state, payload) => {
      var infoData = {}
      var validRecord = false
      var multiVersion = false
      // console.log(payload.rows)
      if (payload.rows !== null) {
        // console.log(payload.rows)
        if (typeof payload.rows.data === 'undefined') {
          state.dispatch('EmptyInfoData')
          return
        }
        if (payload.multiVersio) {
          multiVersion = true
        }
        if (payload.rows.data.ListData.length < 1) {
          state.dispatch('EmptyInfoData')
          return
        }
        if (multiVersion) {
          infoData = payload.rows.data.ListData[0].DebitList[0]
        } else {
          payload.rows.data.ListData.map(function (v, k) {
            if (v.status_code > 1 && validRecord === false) {
              validRecord = true
              infoData = v
            }
          })
        }
      } else {
        const body = { pre_book_no: payload.pre_book_no }
        var result = await deliveryMasterSingle(body)
        infoData = result.data.ListData[payload.pdf_serial]
      }
      infoData.currentIdentity = ''
      const basicCode = String(window.localStorage.getItem('basic_code')).trim()
      if (infoData.sender) {
        if (basicCode === infoData.sender.basic_code) {
          infoData.currentIdentity = 'sender'
        }
        if (basicCode === infoData.receiver_1.basic_code) {
          infoData.currentIdentity = 'receiver_1'
        }
        if (basicCode === infoData.receiver_2.basic_code) {
          infoData.currentIdentity = 'receiver_2'
        }
      }
      state.commit('infoData', infoData)
      console.log('infoData', infoData)
    },
    deliveryMasterHandleCurrentChange: async (state, payload) => {
      const currentPage = payload
      var result = {}
      const body = {
        offset: (parseInt(currentPage) - 1) * parseInt(state.getters.pageData.limit),
        limit: 10
      }
      Object.values(state.getters.searchInputList).forEach(item => {
        if (item.type === 'input') {
          body[item.key] = item.input
        }
      })
      result = await deliveryMasterList(body)
      result.data.data.PageData.totalPage = state.getters.pageData.totalPage
      result.data.data.PageData.currentPage = currentPage
      state.commit('pageData', result.data.data.PageData)
      state.commit('tableData', result.data.data.ListData)
      // console.log('handleCurrentChange body', body)
      // console.log('handleCurrentChange result', result)
    },
    // delTableSelectDataByDeliveryMaster: async (state, payload) => { // 之前做出或報價單用先留著，如果之後不用再刪除
    //   try {
    //     var rsp
    //     // console.log('delTableSelectData', state.getters.tableSelectData)
    //     await Object.values(state.getters.tableSelectData).forEach(async item => {
    //       // console.log('item', item.pre_book_no)
    //       const body = { pre_book_no: item.pre_book_no }
    //       // console.log('body', body)
    //       rsp = await deliveryMasterDelete(body)
    //       console.log('result', rsp)
    //     })
    //   } catch (e) {
    //     console.log(e)
    //   }
    //   // console.log('rsp.status', rsp.status)
    //   // if (rsp.status === 200) {
    //   Object.values(state.getters.searchInputList).forEach(item => {
    //     if (item.type === 'input') {
    //       state.getters.pageData[item.key] = item.input
    //     }
    //   })
    //   const result = await deliveryMasterList(state.getters.pageData)
    //   result.data.PageData.totalPage = state.getters.pageData.totalPage
    //   result.data.PageData.currentPage = state.getters.pageData.currentPage
    //   state.commit('pageData', result.data.PageData)
    //   state.commit('tableData', result.data.ListData)
    // },
    delTableSelectDataByDelivery: async (state, payload) => {
      console.log('delTableSelectDataByDelivery', payload)
      try {
        var rsp
        console.log('delTableSelectData', state.getters.tableSelectData)
        await Object.values(state.getters.tableSelectData).forEach(async item => {
          console.log('item', item.pre_book_no)
          const body = { pre_book_no: item.pre_book_no, version: item.version }
          console.log('body', body)
          rsp = await deliveryMasterDelete(body)
          rsp = await deliveryDetailList({ pre_book_no: item.pre_book_no })
          body.sequence_no = []
          Object.values(rsp.data.ListData).forEach(row => {
            body.sequence_no.push(row.sequence_no)
          })
          rsp = await deliveryDetailDelete(body)
        })
      } catch (e) {
        console.log(e)
      }
      console.log('result', rsp)
      state.commit('commRsp', rsp)
      state.dispatch('deliveryDetailSearch', payload)
    },
    addDataByDelivery: async (state, payload) => {
      var deliveryMasterCreateRsp = await deliveryMasterCreate(state.getters.deliveryMasterItem)
      console.log('deliveryMasterCreateRsp', deliveryMasterCreateRsp)
      switch (deliveryMasterCreateRsp.status) {
        case 200:
          console.log('detail body', JSON.stringify({ pre_book_no: state.getters.deliveryMasterItem.pre_book_no, version: deliveryMasterCreateRsp.data.data.version, detail: state.getters.deliveryDetailItems }))
          deliveryMasterCreateRsp = await deliveryDetailCreate({ pre_book_no: state.getters.deliveryMasterItem.pre_book_no, version: deliveryMasterCreateRsp.data.data.version, detail: state.getters.deliveryDetailItems })
          console.log('deliveryMasterCreateRsp', deliveryMasterCreateRsp)
          break
        default:
          break
      }
      state.commit('commRsp', deliveryMasterCreateRsp)
      state.dispatch('deliveryDetailSearch', payload)
    },
    editDataByDelivery: async (state, payload) => {
      console.log('editDataByDelivery', JSON.stringify(state.getters.deliveryMasterItem), JSON.stringify(state.getters.deliveryDetailItems))
      var result = await deliveryMasterUpdate(state.getters.deliveryMasterItem)
      console.log('editDataByDelivery', result)
      console.log('detail body', JSON.stringify({ pre_book_no: state.getters.deliveryMasterItem.pre_book_no, version: state.getters.deliveryMasterItem.version, detail: state.getters.deliveryDetailItems }))
      result = await deliveryDetailUpdate({ pre_book_no: state.getters.deliveryMasterItem.pre_book_no, sequence_no: state.getters.deliveryDetailItems })
      state.commit('commRsp', result)
    },
    beforEditByDelivery: async (state, payload) => {
      const body = { pre_book_no: payload.orderId }
      if (payload.version !== undefined) {
        body.version = payload.version
      }
      console.log('beforEditByDelivery body', body)
      var result = await deliveryMasterSingle(body)
      console.log('editByDelivery deliveryMasterSingle', result)
      state.dispatch('singleFileInfoData', { rows: result, pre_book_no: body.pre_book_no, pdf_serial: 0 })
      state.commit('deliveryMasterItem', result.data.ListData[0])
      Object.keys(state.getters.taxInfo).forEach(key => {
        state.getters.taxInfo[key] = result.data.ListData[0][key] !== undefined ? result.data.ListData[0][key] : ''
      })
      state.commit('taxInfo', state.getters.taxInfo)
      result = await deliveryDetailList(body)
      console.log('editByDelivery deliveryDetailList', result)
      state.commit('deliveryDetailItems', result.data.ListData)
    },
    addDataByDeliveryMaster: async (state, payload) => {
      const body = {}
      Object.values(state.getters.addInputlist).forEach(item => {
        body[item.key] = item.input
      })
      // console.log(body)
      const rsp = await deliveryMasterCreate(body)
      // console.log('rsp', rsp)
      switch (rsp.status) {
        case 201:
          this.delTableSelectData()
          this.$message({
            type: 'info',
            message: '新增成功'
          })
          break
        default:
          Object.keys(rsp.data).forEach(key => {
            Object.values(state.getters.addInputlist).forEach(item => {
              if (item.key === key) {
                item.msg = rsp.data[key][0]
              }
            })
          })
      }
    },
    quotationConfirmedSearch: async (state, payload) => {
      await state.commit('tableLabel', [])
      console.log('quotationConfirmedSearch payload', payload)
      const body = { offset: 0, limit: 10 }
      if (payload !== null) {
        body.pre_book_no = payload.orderId
        if (payload.version !== undefined) {
          body.version = payload.version
        }
      }
      console.log('quotationConfirmedSearch body', body)
      var result = await deliveryConfirmedSingle(body)
      state.dispatch('singleFileInfoData', { rows: result.data, pre_book_no: '', pdf_serial: 0 })
      console.log('quotationConfirmedSearch result', result)
      const orderDataInputList = []
      Object.values(state.getters.formlistTableLabel).forEach(async (item, idx) => {
        if (idx > 1) {
          orderDataInputList.push({ label: item.label, input: state.getters.editRowData[item.prop], key: item.prop })
        }
      })
      switch (result.status) {
        case 200:
          // var rsp = await deliveryConfirmedList({ pre_book_no: body.pre_book_no })
          var maxVersion = result.data.data.ListData[0].version // Math.max(...result.data.data.ListData.map(item => item.version))
          console.log('maxVersion', maxVersion)
          await Object.values(result.data.data.ListData).forEach(item => {
            if (Number(item.version) === maxVersion) {
              item.max = true
            } else {
              item.max = false
            }
            item.Edit = faEdit
            item.View = faEye
            item.messageid = state.getters.orderId
            item.button = true
          })
          // rsp = await deliveryMasterSingle(body)
          // console.log('rsp', rsp)
          Object.keys(state.getters.taxInfo).forEach(key => {
            state.getters.taxInfo[key] = result.data.data.ListData[0][key] !== undefined ? result.data.data.ListData[0][key] : ''
          })
          await state.commit('taxInfo', state.getters.taxInfo)
          await state.commit('pageData', result.data.data.PageData)
          await state.commit('tableData', result.data.data.ListData)
          await state.commit('tableLabel', state.getters.quotationConfirmedTableLabel)
          // await state.commit('orderDataInfo', orderDataInputList)
          break
        default:
          await state.commit('pageData', [])
          await state.commit('tableData', [])
          await state.commit('tableLabel', [])
      }
      console.log('tableData', state.getters.tableData)
      console.log('tableLabel', state.getters.tableLabel)
    },
    updateDealDate: async (state, payload) => {
      var stepKey = 0
      console.log(payload)
      const result = await purchaseSingle(payload)
      console.log(result.data)
      switch (result.status) {
        case 200:
          state.getters.deliveryDetailData.purchaseSingle = result.data.data[0]
          await state.commit('deliveryDetailData', state.getters.deliveryDetailData)
          switch (payload.key) {
            case ('container_inyard_point'):
              stepKey = 2
              break
            case ('export_released_point'):
              stepKey = 5
              break
            case ('peparture_point'):
              stepKey = 9
              break
            case ('pouch_point'):
              stepKey = 10
              break
            case ('arrival_point'):
              stepKey = 11
              break
            case ('release_point'):
              stepKey = 12
              break
          }
          state.getters.steps[stepKey].status = 'success'
          await state.commit('steps', state.getters.steps)
          break
      }
    },
    uploadChain: async (state) => {
      console.log('uploadchain')
      var body = {
        file_name: state.getters.infoData.file_name
      }
      body[state.getters.infoData.currentIdentity] = 1
      var result = await uploadChainReq(body)
      console.log(result)
      state.dispatch('setSenderReceiverStatus', result)
      console.log('uploadchain result')
    },
    markChainAvoid: async (state) => {
      console.log('markChainAvoid')
      var body = {
        file_name: state.getters.infoData.file_name
      }
      body[state.getters.infoData.currentIdentity] = 2
      var result = await uploadChainReq(body)
      console.log(result)
      state.dispatch('setSenderReceiverStatus', result)
      console.log('markChainAvoid result')
    },
    rejectChain: async (state) => {
      console.log('rejectChain')
      var body = {
        file_name: state.getters.infoData.file_name
      }
      body[state.getters.infoData.currentIdentity] = 3
      var result = await uploadChainReq(body)
      console.log(result)
      state.dispatch('setSenderReceiverStatus', result)
      console.log('rejectChain result')
    },
    setSenderReceiverStatus: function (state, payload) {
      switch (state.getters.infoData.currentIdentity) {
        case ('sender'):
          state.getters.infoData.sender_status = payload.data.sender_status
          state.getters.infoData.sender_on = payload.data.sender_on
          break
        case ('receiver_1'):
          state.getters.infoData.receiver_1_status = payload.data.receiver_1_status
          state.getters.infoData.receiver_1_on = payload.data.receiver_1_on
          break
        case ('receiver_2'):
          state.getters.infoData.receiver_2_status = payload.data.receiver_2_status
          state.getters.infoData.receiver_2_on = payload.data.receiver_2_on
          break
        case ('receiver_3'):
          state.getters.infoData.receiver_3_status = payload.data.receiver_3_status
          state.getters.infoData.receiver_3_on = payload.data.receiver_3_on
          break
        case ('receiver_4'):
          state.getters.infoData.receiver_4_status = payload.data.receiver_4_status
          state.getters.infoData.receiver_4_on = payload.data.receiver_4_on
          break
      }
    },
    updateReceiverStatus: async (state, payload) => {
      var body = {
        pre_book_no: '',
        basic_type: '',
        permission: 0
      }
      var row = state.getters.infoData
      var receiverRow = row['receiver_' + payload.receiverIdx]
      // console.log('row', row)
      // console.log('receiverRow', receiverRow)
      var revertPermission = receiverRow.permission === 1 ? 0 : 1
      var result = null
      switch (payload.routeName) {
        case ('completeBooking'):
        case ('shipment'):
        case ('checkReleased'):
        case ('quotationConfirmed'):
        case ('soReleased'):
        case ('ivReleased'):
        case ('plReleased'):
        case ('documentReleased'):
          body.pre_book_no = row.pre_book_no
          body.basic_type = receiverRow.basic_type
          body.permission = revertPermission
          break
        case ('mblsi'):
          body.vsl_name = row.vsl_name
          body.voyage = row.voyage
          body.carrier_agent = row.carrier_agent
          body.so_no = row.so_no
          body.basic_type = receiverRow.basic_type
          body.permission = revertPermission
          break
        case ('spouchDetailDC'):
          body = {
            vsl_name: row.vsl_name,
            voyage: row.voyage,
            carrier_agent: row.carrier_agent,
            dc_no: row.dc_no,
            doc_type: row.doc_type,
            basic_type: receiverRow.basic_type,
            permission: revertPermission
          }
          break
      }
      console.log('updateReceiverStatus body', body)
      switch (payload.routeName) {
        case ('completeBooking'):
        case ('shipment'):
          result = await axios.post('api/shipment/single', body).then((response) => response)
          break
        case ('checkReleased'):
          result = await axios.post('api/hbl_checklist/single', body).then((response) => response)
          break
        case ('quotationConfirmed'):
          result = await axios.post('api/delivery_confirmed/single', body).then((response) => response)
          break
        case ('soReleased'):
        case ('ivReleased'):
        case ('plReleased'):
          result = await axios.post('api/hbl/single', body).then((response) => response)
          break
        case ('documentReleased'):
          if (state.getters.soTab === 0) {
            result = await axios.post('api/hbl/single', body).then((response) => response)
          } else {
            result = await axios.post('api/bill/single', body).then((response) => response)
          }
          break
        case ('mblsi'):
          result = await axios.post('api/mblsi/single', body).then((response) => response)
          break
        case ('spouchDetailDC'):
          result = await axios.post('api/pouch/single', body).then((response) => response)
          break
      }
      if (body.pre_book_no !== '' || body.vsl_name !== '') {
        row['receiver_' + payload.receiverIdx].permission = revertPermission
        state.commit('infoData', row)
      }
      return result
    }
  }
}

export default delivery
