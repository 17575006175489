import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mixincss from './css/index.js'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/zh-TW'
import './css/style/theme/index.css'
import VueI18n from 'vue-i18n'
import messages from '@/common/i18n/i18n.js'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import axios from './api/axios'

// bb components
import BaseButton from './views/cfs/components/ui/BaseButton.vue'

Vue.mixin({
  data: function () {
    return {
      get globalAppHost() {
        return 'http://ionetest.viuto-aiot.com/'
      },
      get globalPDFHost() {
        // console.log(location.host)
        if (location.host === 'bctest.kf-kfic.com') {
          return location.protocol + '//bctest.kf-kfic.com'
        } else if (location.host === 'bc.kf-kfic.com') {
          return location.protocol + '//bc.kf-kfic.com'
        } else if (location.host === 'kfrd.ioneit.com') {
          return location.protocol + '//kfrd.ioneit.com'
        } else if (location.host === 'ionetest.viuto-aiot.com') {
          return location.protocol + '//ionetest.viuto-aiot.com'
        } else if (location.host.match('localhost')) {
          return 'location.protocol + //kfrd.ioneit.com'
        } else {
          return location.protocol + '//' + location.host
        }
      }
    }
  }
})
console.log('location.host', location)
Vue.use(VueI18n)
Vue.component('l-map', LMap)
Vue.component('l-tile-layer', LTileLayer)
Vue.component('l-marker', LMarker)
Vue.component('base-button', BaseButton)

Vue.config.productionTip = false
window.axios = axios()

Vue.use(ElementUI, { locale })

const i18n = new VueI18n({
  locale: 'tw',
  messages
})

new Vue({
  router,
  store,
  mixincss,
  i18n,
  render: h => h(App)
}).$mount('#app')
